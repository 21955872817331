'use strict';


angular.module('app')
    .controller('HomeController',
    ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", "hotkeys", "RestService",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, hotkeys, RestService) {
    
            $rootScope.title = 'Главная';
            $scope.number = 8;
            $scope.getNumber = function (num) {
                return new Array(Math.ceil(num));
            };
            $scope.md = function () {
                return Math.ceil(vm.data.locationList.length / $scope.number);
            };
            var vm = this; //Было закоментировано
            vm.comId = localStorage.getItem('ComId');

/*
            vm.rest = RestService;
            vm.rest.set({name:'AdvItems', url:'/api/advitems'});

            vm.adv = {};

            vm.getAdvItems = getAdvItems;

            vm.getAdvItems();



            function getAdvItems() {
                $http.get('/api/adv/actual').success(function (data) {
                    vm.adv = data;
                });
            }*/

            $rootScope.allowUIActions = true;
            $rootScope.allowUICatalogs = true;
            $rootScope.allowUITopItems = true;
			$rootScope.catForCom15 = false;
			$rootScope.catForCom16 = false;
            $rootScope.catForCom17 = false;
            $rootScope.catForCom19 = false;
            $rootScope.catForCom22 = false;
			$rootScope.catForCom19Url = "";
            $rootScope.showBanner = false;
            $rootScope.showBannerForma = false;


            $rootScope.allowBlockCom = {
                 15: false, 
                 16: false, 
                 17: false, 
                 19: false,
                 22: false
            };
            $rootScope.news = null;
            //$rootScope.showBannerForClient = { 'ADBUDDY': false, 'ADFAMILY': false, 'ADFRIEND': false };

            getAllowUI();
            getTopNews();

            $rootScope.test = "";
            //ShowBannerForClient();

            openModal();

            function openModal() {
                if (window.rem) {
                    return;
                } else {
                    window.rem = true;
                }
                var today = new Date();
                var milliseconds = today.getMilliseconds();
                $http.get("/home/getReminder?milliseconds=" + milliseconds)
                    .success(function(data, status) {
                        if (data != "") {
                            var modalInstance = $modal.open({
                                templateUrl: "/app/home/reminder-modal.html",
                                controller: "ReminderModalController",
                                controllerAs: "vm",
                                backdrop: 'static',
                                windowClass: "no-animation-modal",
                                resolve: {
                                    reminder: function() {
                                        return data;
                                    }
                                }
                            });
                        modalInstance.result.then(
                            function(result) {
                                window.rem = false;
                            },
                            function (result) {
                                if (result == "escape key press") {
                                    window.rem = false;
                                    openModal();
                                } else {
                                    window.rem = false;
                                }
                            }
                        );
                    }

                        }); 
                    
            }

            setShowBanner();

            function setShowBanner() {
                $rootScope.showBanner = false;
                var lsComId = localStorage.getItem('ComId');
                var today = new Date();
                if ((new Date(2019, 10, 24) < today && today < new Date(2019, 11, 1)) && lsComId=='15') {
                    $rootScope.showBanner = true;
                } 
                $rootScope.showBannerForma = false;
                if (today < new Date(2019, 11, 1) && lsComId == '19') {
                    $rootScope.showBannerForma = true;
                } 
            }

            function httpGet(url)
            {
                    var xHttp;
                    var res = "";
                    if (window.XMLHttpRequest) {
                        xHttp = new XMLHttpRequest();
                    } else {
                        if (window.ActiveXObject) {
                            xHttp = new ActiveXObject("Microsoft.XMLHTTP");
                        } else {
                            xHttp = new ActiveXObject("Msxml2.XMLHTTP");
                        }
                    }

                    xHttp.open("GET", url, false);
                    //xHttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
                    xHttp.send();
                    if (xHttp.status === 200) {
                        res = xHttp.responseText;
                    } 
                    return res;
                
            }

            $scope.getShowBannerForClient = function(code) {
                var lsComId = localStorage.getItem('ComId');
                var today = new Date();
                var startdate = new Date();
                // Месяц указывать на 1 меньше.
                startdate.setFullYear(2017, 11, 29);
                //startdate.setHours(16, 0, 0, 0);

                if (today >= startdate && lsComId == '15') {
                    //if (localStorage.getItem('loyaltyCode')) {
                    //    localStorage.setItem('loyaltyCode', '');
                    //}
                    var loyaltyCode = localStorage.getItem('loyaltyCode');

                        if (loyaltyCode == null || loyaltyCode == '' ) {
                            //$http.get('/api/catalog/GetLoyaltyCode').then(function(responce) {
                            //    loyaltyCode = responce.data.replace(new RegExp('"', 'g'), '');
                            //    if (loyaltyCode === '') loyaltyCode = 'NONE';
                            //    localStorage.setItem('loyaltyCode', loyaltyCode);
                            //});
                            loyaltyCode = httpGet('/api/catalog/GetLoyaltyCode');
                            loyaltyCode = loyaltyCode.replace(new RegExp('"', 'g'), '');
                            if (loyaltyCode === '') loyaltyCode = 'NONE';
                            localStorage.setItem('loyaltyCode', loyaltyCode);
                        }
                   

                        if (loyaltyCode === code) {
                            return true;
                        }
                    
                }
                return false;
            }
            function getTopNews()  {
                $http.get('/api/marketing/GetTopNews').then(function (responce) {
                    $rootScope.news = responce.data.items;
                    //console.log(responce);
                });
            }

            function getAllowUI() {
                var lsComId = localStorage.getItem('ComId');
                var com_id = '';                
                if (lsComId != null && lsComId !== '' && lsComId !== '0') {
                    com_id = lsComId;
                } else {
                    $http.get('/api/catalog/getcomid').then(function(responce) {
                        com_id = responce.data.replace(new RegExp('"', 'g'), '');
                        localStorage.setItem('ComId', com_id);
                    });
                }

                $rootScope.ComId = com_id;
                $rootScope.allowBlockCom[com_id] = true;
                
                //catForCom15 setUp
                $rootScope['catForCom' + com_id] = true;

                if (com_id === "15") {
                    $rootScope.allowUICatalogs = true;
                    //$rootScope.catForCom15 = true;
                    $rootScope.showBannerForma = false;
                }
                if (com_id === "17") {
                    $rootScope.allowUIActions = true;
                    $rootScope.allowUICatalogs = true;
                    $rootScope.allowUITopItems = true;
                    //$rootScope.catForCom17 = true;
                    //$rootScope.catForCom15 = false;
                    //$rootScope.catForCom19 = false;
                    $rootScope.showBanner = false;

                };

                if (com_id === "19") {
                    $rootScope.allowUIActions = true;
                    $rootScope.allowUICatalogs = true;
                    $rootScope.allowUITopItems = false;
                    //$rootScope.catForCom15 = false;
                    //$rootScope.catForCom17 = false;
                    //$rootScope.catForCom19 = true;
                    $rootScope.showBanner = false;

                    if (document.location.hostname === "b2b2.eso.local" || document.location.hostname === "localhost") {
                        $rootScope.catForCom19Url = "http://www.fps-catalog.com.ua/clid9e985a9b6df1c82e24c45534d1d2fc22";
                    }
                    else {
                        $rootScope.catForCom19Url = "http://www.fps-catalog.com.ua/clid3e0ad02344c9f20b843779ac5749725e";
                    }
                };
                return false;
            }



            $http.get('/api/reports/debts')
                .success(function (data) {
                    $rootScope.debts = data.items ? ($rootScope.catForCom17 && data.items.length > 0 && new Date(data.items[0].DueDate.split('.').reverse().join('.')) < new Date()) : false;
                });
        }])
    .filter('dir_file', function () {
    return function (input) {        
        return input.substring(0, 2) + "/" + input;
    }
});

