'use strict';


angular.module('reports')
    .controller('InvoicesLinesController',
    ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", "hotkeys",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, hotkeys) {

            $rootScope.title = 'Строки учтённых документов';

            $scope.openedStart = false;
            $scope.openedEnd = false;

            $scope.openStart = function($event) {
                $event.preventDefault();
                $event.stopPropagation();
                $scope.openedStart = true;
            };

            $scope.openEnd = function($event) {
                $event.preventDefault();
                $event.stopPropagation();
                $scope.openedEnd = true;
            };

            //$scope.format = 'yyyy.MM.dd';


            var vm = this;

            vm.alerts = {};

            vm.searchAll = '';
            vm.search = {};

            vm.data = {
                search: {},
                searchAll: '',
                items: [],
                filtered: [],
                dateFrom:'',
                dateTo:'',
                agreement:'Без учета договора',
                agreements:[],
                from:'',
                to:''
            };

            var date = new Date(), y = date.getFullYear(), m = date.getMonth();
            var firstDay = new Date(y, m, 1);
            var lastDay = new Date(y, m + 1, 0);
            vm.data.dateFrom = firstDay;
            vm.data.dateTo = lastDay;

            vm.getData = getData;
            vm.exportToCsv = exportToCsv;
            //vm.getAgreements = getAgreements;
            //vm.openModal = openModal;

            vm.getData();

            $scope.filterTextTimeout;
            $scope.filterTextTimeout2;
            $scope.$watch('vm.searchAll', function (val) {
                if ($scope.filterTextTimeout) $timeout.cancel($scope.filterTextTimeout);
                $scope.filterTextTimeout = $timeout(function() {
                    vm.data.searchAll = val;
                }, 500);
            });

            $scope.$watchCollection('vm.search', function (val) {
                if ($scope.filterTextTimeout2) $timeout.cancel($scope.filterTextTimeout2);
                $scope.filterTextTimeout2 = $timeout(function() {
                    vm.data.search = vm.search;
                }, 500);
            });

            function exportToCsv() {
                window.open('/api/reports/invoicelinestocsv?from='+moment(vm.data.dateFrom).format('YYYY-MM-DD')+'&to='+moment(vm.data.dateTo).format('YYYY-MM-DD'),'_blank');
                /*var anchor = angular.element('<a/>');
                anchor.attr({
                    href: '/api/reports/invoicelinestocsv?from='+moment(vm.data.dateFrom).format('YYYY-MM-DD')+'&to='+moment(vm.data.dateTo).format('YYYY-MM-DD'),
                    target: '_blank'
                })[0].click();*/
            }
           
            /*function getAgreements() {
                    $http.get('/api/reports/getagreements')
                        .success(function (data) {
                            vm.data.agreements = data.items;
                            vm.data.dateFrom = data.first;
                            vm.data.dateTo = data.last;
                            vm.getData();
                        });
            }*/

            /*function openModal(row) {
                var modalInstance = $modal.open({
                    templateUrl: "/app/reports/invoices/invoice-lines-modal.html",
                    controller: "InvoiceLinesController",
                    controllerAs: "vm",
                    size: 'lg',
                    windowClass: "no-animation-modal",
                    resolve: {
                        parent: function () {
                            return row.DocNo;
                        }
                    }
                });
                modalInstance.result.then(
                    function (newRow) {

                    },
                    function () {

                    }
                );
            }*/

            function getData() {

                /*if(vm.data.agreement == 'Без учета договора'){
                    var agreement = '';
                } else {
                    var agreement = vm.data.agreement;
                }*/

                var params = {from:vm.data.dateFrom,to:vm.data.dateTo};

                    $http.get('/api/reports/invoicelines', {params:params})
                        .success(function (data) {
                            vm.data.items = data.items;
                            vm.data.filtered = data.items;
                            vm.data.search = '';
                            vm.data.searchAll = '';
                            vm.searchAll = '';
                            /*
                            vm.data.from = data.from;
                            vm.data.to = data.to;*/
                        });

            }






        }]);
