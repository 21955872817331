'use strict';

angular.module('app')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("vin", {
                url: "/vin/:vin/type/:typeId/ssd/:ssd/catalog/:catalog",
                //url: "/vin/:vin/type/:typeId",
                views:{
                    "@" : {
                        templateUrl: "/app/vin/vin.html",
                        controller: "VinController",
                        controllerAs:"vin"
                    },
                    'groups@vin':{
                        templateUrl: "/app/vin/vin-groups-items.html",
                        controller: "VinGroupsItemsController",
                        controllerAs:"vm"
                    },
                    'type@vin':{
                        templateUrl: "/app/vin/vin-type.html",
                        controller: "VinTypeController",
                        controllerAs:"vm"
                    },

                    'items@vin':{
                        templateUrl: "/app/vin/vin-type-detail.html",
                        controller: "VinTypeController",
                        controllerAs:"vm"
                    }

                }
            })
           /* .state("oem", {
                url: "/oem/type/:typeId/ssd/:ssd/catalog/:catalog",
                views:{
                    "@" : {
                        templateUrl: "/app/vin/vin.html",
                        controller: "VinController",
                        controllerAs:"vin"
                    },
                    'groups@oem':{
                        templateUrl: "/app/vin/vin-groups-items.html",
                        controller: "VinGroupsItemsController",
                        controllerAs:"vm"
                    },
                    'type@oem':{
                        templateUrl: "/app/vin/vin-type.html",
                        controller: "VinTypeController",
                        controllerAs:"vm"
                    },

                    'items@oem':{
                        templateUrl: "/app/vin/vin-type-detail.html",
                        controller: "VinTypeController",
                        controllerAs:"vm"
                    }

                }
            })*/


            .state("vin.grp", {
                url: "/grp/:grpId",
                views: {
                    'items@vin': {
                        templateUrl: "/app/vin/vin-oem-items.html",
                        controller: "VinOemItemsController",
                        controllerAs: "vm"
                    }
                }
            })


            /*.state("oem.grp", {
                url: "/grp/:grpId",
                views: {
                    'items@oem': {
                        templateUrl: "/app/vin/vin-oem-items.html",
                        controller: "VinOemItemsController",
                        controllerAs: "vm"
                    }
                }
            })*/
            
            .state("vin.units", {
                //url: "/cat/:categoryId",
                params: {
                    categoryId:'',
                    ssdId:''
                },
                views: {
                    'items@vin': {
                        templateUrl: "/app/vin/vin-unit-img-list.html",
                        controller: "VinUnitListController",
                        controllerAs: "vm"
                    },
                    'replace@vin': {
                        templateUrl: "/app/vin/vin-unit-list.html",
                        controller: "VinUnitListController",
                        controllerAs: "vm"
                    }
                }
            })

           /* .state("oem.units", {
                //url: "/cat/:categoryId",
                params: {
                    categoryId:'',
                    ssd:''
                },
                views: {
                    'items@oem': {
                        templateUrl: "/app/vin/vin-unit-img-list.html",
                        controller: "VinUnitListController",
                        controllerAs: "vm"
                    },
                    'replace@oem': {
                        templateUrl: "/app/vin/vin-unit-list.html",
                        controller: "VinUnitListController",
                        controllerAs: "vm"
                    }
                }
            })*/
            
            
            .state("vin.grp.oem", {
                url: "/oem/:art",
                params: {
                    name:'',
                    note:''
                },
                views:{
                    'items@vin': {
                        templateUrl: "/app/art/art-main-items.html",
                        controller: "VinMainItemsController",
                        controllerAs: "vm"
                    }
                }
            })
            /*.state("oem.grp.oem", {
                url: "/oem/:art",
                params: {
                    name:'',
                    note:''
                },
                views:{
                    'items@oem': {
                        templateUrl: "/app/art/art-main-items.html",
                        controller: "VinMainItemsController",
                        controllerAs: "vm"
                    }
                }
            })*/
            
            .state("vin.grp.oem.replace", {
                //url: "/item/:artId",
                params: {
                    artId:''
                },
                views:{
                    'replace@vin': {
                        templateUrl: "/app/art/art-replace-items.html",
                        controller: "ArtReplaceItemsController",
                        controllerAs: "vm"
                    }
                }
            })
            /*.state("oem.grp.oem.replace", {
                //url: "/item/:artId",
                params: {
                    artId:''
                },
                views:{
                    'replace@oem': {
                        templateUrl: "/app/art/art-replace-items.html",
                        controller: "ArtReplaceItemsController",
                        controllerAs: "vm"
                    }
                }
            })*/
        
        ;
    }]);