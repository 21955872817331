'use strict';


angular.module('settings')
    .controller('UserPriceController',
    ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", "hotkeys","RestService",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, hotkeys, RestService) {

            $rootScope.title = 'Настройка наценки от Вашей цены закупки';

            var vm = this;

            vm.alerts = {};

            vm.data = {
                search: {},
                items: [],
                filtered: [],
            };
            vm.rest = RestService;
            vm.rest.set({name: 'UserPrice', url: '/api/userprice'});

            vm.getData = getData;
            vm.openModal = openModal;
            vm.deleteRows = deleteRows;

            vm.getData();

            $scope.$watchCollection("vm.data.search", function (val) {
                if(val.Brand == ''){
                    delete val.Brand;
                }
                if(val.Grp == ''){
                    delete val.Grp;
                }
                if(val.SubGrp == ''){
                    delete val.SubGrp;
                }
            });

            function openModal(row) {
                var modalInstance = $modal.open({
                    templateUrl: "/app/settings/user-price/user-price-modal.html",
                    controller: "UserPriceModalController",
                    controllerAs: "vm",
                    //size: 'lg',
                    windowClass: "no-animation-modal",
                    resolve: {
                        parent: function () {
                            return row;
                        }
                    }
                });
                modalInstance.result.then(
                    function () {
                        vm.getData();
                    },
                    function () {
                        vm.getData();
                    }
                );
            }

            function getData() {
                    $http.get('/api/userprice' )
                        .success(function (data) {
                            vm.data.items = data.items;
                            vm.data.filtered = data.items;
                        });
            }



            function deleteRows(rows) {

                var i = 0;

                angular.forEach(rows, function (row) {
                    vm.rest.del(row.id, function (data) {
                        i++;
                        var index = _.findIndex(vm.data.filtered, row);
                        vm.data.filtered.slice(index, 1);
                    });
                });

                $timeout(function () {
                    vm.getData();
                    vm.alerts = {success: ['Удалено строк: ' + (i)]};
                }, 1000);
            }

        }]);
