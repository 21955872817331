'use strict';

angular.module('app')
    .controller('VinTypeController',
    ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", '$state', '$stateParams', "hotkeys",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, $state, $stateParams, hotkeys) {

            var vm = this;
            vm.$storage = $localStorage;
            vm.$stateParams = $stateParams;
            vm.$rootStateParams = $rootScope.$stateParams;

            vm.char = {
                nameFull:'Полное название',
                optionsField:'Опции',
                engineField:'Тип двигателя',
                transmissionField:'Трансмиссия',
                dateField:'Дата выпуска',
                gradeField:'Класс',
                nameField:'Название',
                framecolorField:'Цвет кузова',
                modificationField:'Модификация',
                descriptionField:'Описание',
                enginenoField:'Номер двигателя',
                engine1Field:'Двигатель',
                engine2Field:'Двигатель',
                framesField:'Кузов',
                frameField:'Кузов',
                trimcolorField:'Код цвета',
                doorsField:'Дверей',
                modelField:'Модель',
                modelyearfromField:'Модель с',
                modelyeartoField:'Снята с производства',
                datetoField:'Снята с производства',
                manufacturedField:'Год производства',
                datefromField:'Выпускалась с',
                xx:''
            };
/*
            vm.alerts = {};

            vm.data = {
                items: [],
                filtered: [],
                active: -1//,
                //grpPath: vm.$stateParams.grpId.split('-')
            };

            vm.getData = getData;

            vm.getData();

            function getData() {
                var params = {vin: $stateParams.vin, type: $stateParams.typeId};
                $http.post("/api/catalogoem/typeunits", params)
                    .success(function (data, status) {
                        //vm.$storage.vinType = data.type;
                        //vm.$storage.vinCatalog = data.catalog;
                        if(data.units) {
                            vm.data.items  = data.units.rowField;
                            vm.data.filtered = data.units.rowField;
                        }
                        //vm.setGrpPath();
                        //params.name = vm.getTypeName(data);
                        //vm.saveToHistory(params);
                    });
            }*/


            /*

            vm.go = go;

            vm.getData();

            function getData() {
                $http.post("/api/catalogoem/items?id=" + vm.data.grpPath[0], vm.$storage.vinType)
                    .success(function (data, status) {
                        if(data.items && data.items.categoryField){
                            vm.data.items = data.items.categoryField;
                            vm.data.filtered = data.items.categoryField;
                        } else {
                            vm.alerts = {warning:['ОЕ-артикулы не найдены']}
                        }
                    });
            }

            function go(row) {
                if (row.oemField != null) {
                    $state.go('vin.grp.oem', {art: row.oemField});
                }
            }*/
        }]);

