
angular.module('nb.items', [])
	.controller('NbItemsController', ['$scope', '$filter', 'hotkeys',
		function ($scope, $filter, hotkeys) {
			var ctrl = this,
				model = ctrl.model = $scope.model;

			model.isAllSelect = false;

			// Фильтр по поиску
			$scope.$watchCollection("model.search[model.col]", function (query) {
				model.resetItems(query);
			});

			// Сброс списка
			model.resetItems = function (query) {
				model.active[model.col] = -1;
				model.selected[model.col] = [];
				if(query && query.items) model.filtered[model.col] = $filter("filter")(model.items[model.col], query.items);
			};

			// Поиск индекса активной строки
			model.findActiveRow = function (row) {
				var index = _.findIndex(model.filtered[model.col], row);
				if (index >= 0) {
					return index;
				} else {
					return -2;
				}
			};

			// Проверка активна ли строка
			model.isActiveRow = function (row) {
				return model.findActiveRow(row) == model.active[model.col];
			};

			// Поиск индекса выделенной строки
			model.findSelectedRow = function (row, col) {
				if(col == undefined){
					col = model.col;
				}
				var index = _.findIndex(model.selected[col], row);
				if (index >= 0) {
					return index;
				} else {
					return -5;
				}
			};

			// Проверка выделена ли строка
			model.isSelectedRow = function (row, col) {
				if(col == undefined){
					col = model.col;
				}
				//var index = _.findIndex(model.selected[col], row);
				if (model.findSelectedRow(row, col) >= 0) {
					return true;
				} else {
					return false;
				}
			};

			// Выделение строки
			model.selectRow = function (row) {
				if (!row) {
					row = model.filtered[model.col][model.active];
				}
				var index = model.findSelectedRow(row);
				if (index >= 0) {
					model.selected[model.col].splice(index, 1);
					return false;
				} else {
					if (row) model.selected[model.col].push(row);
					return true;
				}
			};

			// Активирование строки
			model.activeRow = function (index, col) {
				if(col == undefined){
					col = model.col;
				}

				model.active[col] = index;
			};



			// Выделение всех строк
			model.selectAllRows = function () {
				model.isAllSelect = !model.isAllSelect;
				angular.forEach(model.filtered[model.col], function (row, key) {
					var index = model.findSelectedRow(row);
					if (index >= 0) {
						if (!model.isAllSelect) model.selected[model.col].splice(index, 1);
					} else {
						if (model.isAllSelect) model.selected[model.col].push(row);
					}
				});
			};

			model.bindAll = function () {
			hotkeys.bindTo($scope)
				.add({
					combo: "up",
					description: "Движение вверх по списку",
					allowIn: ["INPUT", "SELECT", "TEXTAREA"],
					callback: function (event, hotkey) {
						event.preventDefault();
						if (model.active[model.col] >= 0) {
							model.active[model.col] -= 1;
						}
						if (model.active[model.col] < 0) {
							model.active[model.col] = model.filtered[model.col].length - 1;
						}
					}
				})

				.add({
					combo: "down",
					description: "Движение вниз по списку",
					allowIn: ["INPUT", "SELECT", "TEXTAREA"],
					callback: function (event, hotkey) {
						event.preventDefault();
						if (model.active[model.col] <= (model.filtered[model.col].length - 1)) {
							model.active[model.col] += 1;
						}
						if (model.active[model.col] > model.filtered[model.col].length - 1) {
							model.active[model.col] = 0;
						}
					}
				})
				.add({
					combo: "right",
					allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
					description: 'Переключение в правую колонку',
					callback: function (event, hotkey) {
						event.preventDefault();
						if (model.col < model.cols-1) {
							model.col += 1;
						}

						/*switch ($scope.selected.col) {
							case "sidegroups":
								$scope.selected.col = "items";
								$("#srch_items").focus();
								break;
							case "items":
								$scope.selected.col = "replaces";
								$scope.selected.replaces = 0;
								$("#srch_replaces").focus();
								break;
							case "replaces":
								//$scope.selected.col = "sidegroups";
								//$("#srch_sidegroups").focus();
								//$scope.selected.replaces = -1;
								break;
						}*/
					}
				})
				.add({
					combo: "left",
					allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
					description: 'Переключение в левую колонку',
					callback: function (event, hotkey) {
						event.preventDefault();
						if (model.col > 0) {
							model.col -= 1;
						}


						/*switch ($scope.selected.col) {
							case "sidegroups":
								//$scope.selected.col = "replaces";
								//$("#srch_replaces").focus();
								//$scope.selected.replaces = 0;
								break;
							case "items":
								$scope.selected.col = "sidegroups";
								$("#srch_sidegroups").focus();
								break;
							case "replaces":
								$scope.selected.col = "items";
								$("#srch_items").focus();
								$scope.selected.replaces = -1;
								break;
						}*/
					}
				})

				.add({
					combo: "space",
					description: "Выбор строки",
					//allowIn: ["INPUT", "SELECT", "TEXTAREA"],
					callback: function (event, hotkey) {
						event.preventDefault();
						model.selectRow();

						if (model.active[model.col] <= (model.filtered[model.col].length - 1)) {
							model.active[model.col] += 1;
						}
						if (model.active[model.col] > model.filtered[model.col].length - 1) {
							model.active[model.col] = 0;
						}

						//model.active += 1;
					}
				})
				.add({
					combo: "enter", description: "Открытие",// allowIn: ["INPUT", "SELECT", "TEXTAREA"],
					callback: function (event, hotkey) {
						event.preventDefault();
						model.actionRow();
					}
				});


		};

		model.bindAll();

		var destroyed;
		$scope.$on('$destroy', function() {
			destroyed = true;
			if(model.parent) model.parent.bindAll();
		});
	}])
;