'use strict';


angular.module('reports')
    .controller('BalanceController',
    ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", "hotkeys",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, hotkeys) {

            $rootScope.title = 'Клиент-Сверка';

            $scope.openedStart = false;
            $scope.openedEnd = false;

            $scope.openStart = function($event) {
                $event.preventDefault();
                $event.stopPropagation();
                $scope.openedStart = true;
            };

            $scope.openEnd = function($event) {
                $event.preventDefault();
                $event.stopPropagation();
                $scope.openedEnd = true;
            };

            $scope.format = 'yyyy.MM.dd';


            var vm = this;

            vm.alerts = {};

            vm.data = {
                search: '',
                items: [],
                filtered: [],
                dateFrom:'',
                dateTo:'',
                agreement:'Без учета договора',
                agreements:[],
                from:'',
                to: '',
                message: '',
                bankTransactionAmount: 0.00,
                reminderId: 0
            };

            vm.getData = getData;
            vm.getAgreements = getAgreements;

            vm.getAgreements();

            vm.agree = changeReminderStatus;
            function changeReminderStatus() {
                $http.post("/home/changeReminderStatus?message=" + vm.data.message + "&status=" + 1 + "&reminderId=" + vm.data.reminderId + "&balance=" + vm.data.Balance + "&balanceDate=" + vm.data.BalanceDate).success(function (data) {
                    window.location = window.location;
                });
            }

            function getAgreements() {
                    $http.get('/api/reports/getagreements')
                        .success(function (data) {
                            vm.data.agreements = data.items;
                            vm.data.dateFrom = data.first;
                            vm.data.dateTo = data.last;
                            vm.data.message = data.message;
                            vm.data.reminderId = data.reminderId;
                            vm.data.Balance = data.Balance;
                            vm.data.BalanceDate = data.BalanceDate;

                            vm.getData();
                        });

            }

            function getData() {

                if(vm.data.agreement == 'Без учета договора'){
                    var agreement = '';
                } else {
                    var agreement = vm.data.agreement;
                }

                var params = {from:vm.data.dateFrom,to:vm.data.dateTo,agreement:agreement};

                    $http.get('/api/reports/balance', {params:params})
                        .success(function (data) {
                            vm.data.items = data.items;
                            vm.data.filtered = data.items;
                            vm.data.from = data.from;
                            vm.data.to = data.to;
                            vm.data.bankTransactionAmount = data.bankTransactionAmount;
                        });

            }


        }]);
