'use strict';


angular.module('reports')
    .controller('DebtsController',
    ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", "hotkeys",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, hotkeys) {

            $rootScope.title = 'Задолженность';



            /*$scope.openedStart = false;
            $scope.openedEnd = false;

            $scope.openStart = function($event) {
                $event.preventDefault();
                $event.stopPropagation();
                $scope.openedStart = true;
            };

            $scope.openEnd = function($event) {
                $event.preventDefault();
                $event.stopPropagation();
                $scope.openedEnd = true;
            };

            $scope.format = 'yyyy.MM.dd';*/




            var vm = this;

            vm.alerts = {};

            vm.data = {
                search: '',
                items: [],
                filtered: [],
                manager: {},
                dateFrom:'',
                dateTo:'',
                agreement:'Без учета договора',
                agreements:[],
                from:'',
                to:''
            };

            vm.getData = getData;
            vm.getData();
            //vm.getAgreements = getAgreements;

            /*vm.getAgreements();

            function getAgreements() {
                    $http.get('/api/reports/getagreements')
                        .success(function (data) {
                            vm.data.agreements = data.items;
                            vm.data.dateFrom = data.first;
                            vm.data.dateTo = data.last;
                        });

            }*/

            function getData() {

                /*if(vm.data.agreement == 'Без учета договора'){
                    var agreement = '';
                } else {
                    var agreement = vm.data.agreement;
                }*/

                //var params = {from:vm.data.dateFrom,to:vm.data.dateTo,agreement:agreement};

                    $http.get('/api/reports/debts')
                        .success(function (data) {
                            vm.data.items = data.items;
                            vm.data.manager = data.manager;
                            vm.data.filtered = data.items;
                            vm.data.bankTransactionAmount = data.bankTransactionAmount;
                        });

            }


        }]);
