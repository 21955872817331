"use strict";

angular.module("app")
    .controller("CartController", ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", "hotkeys", "$upload",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, hotkeys, $upload) {

            $rootScope.title = 'Корзина';

            var vm = this;
            vm.$storage = $localStorage;
            vm.alerts = {};
            vm.data = {
                container: '.mainview',
                items: [],
                filtered: [],
                selected: [],
                search: '',
                //type: [],
                stock: [],
                active: -1,
                openOrders: [],
                cartItems: [],
                stockReal: [],
                total: {money: 0, qty: 0},
                totalSelected: {money: 0, qty: 0},
                totalFiltered: {money: 0, qty: 0},
                filters: [],
                currentLocation: false,
                //currentTag: false,
                LocationList: [],
                lastRemovedItems:{},
                lastOrderItems:{},
                lastSelectedItems:{}
            };

            //Создане заказ
            vm.createOrder = createOrder;
            vm.createOrderAsync = createOrderAsync;
            //Добавление в Заказ
            vm.addToOrder = addToOrder;

            //Получение и установка позиций и наличия
            vm.getData = getData;
            vm.getItems = getItems;
            vm.setItems = setItems;
            vm.resetItems = resetItems;
            vm.setStock = setStock;

            //Сортировка

            // Расчет сумм, кол-ва строк и количества
            vm.calcTotal = calcTotal;
            vm.calcFiltered = calcFiltered;
            vm.calcSelected = calcSelected;
            vm.calcAll = calcAll;

            vm.getOpenOrders = getOpenOrders; //получение открытых заказов для добавления позиций
            vm.openToOrder = openToOrder; //открыть диалог для создания Заказа
            vm.openToOrderAsync = openToOrderAsync; //открыть диалог для создания Заказа
            vm.bindKeys = bindKeys; //биндинг горячих клавиш

            vm.openReplace = openReplace;

            vm.bindKeys();
            vm.getData();

            $scope.$watchCollection("vm.data.items", function () {
                //vm.calcTotal();
                vm.calcAll();
            });

            $scope.$watchCollection("vm.data.selected", function () {
                //vm.calcSelected();
                vm.calcAll();
            });

            $scope.$watchCollection("vm.data.filtered", function () {
                //vm.calcFiltered();
                vm.calcAll();
            });

            function openReplace(row) {
                var modalInstance = $modal.open({
                    templateUrl: "/app/art/art-replace-modal.html",
                    controller: "ArtReplaceModalController",
                    controllerAs: "ctrl",
                    //size: 'lg',
                    windowClass: "no-animation-modal",
                    resolve: {
                        parent: function () {
                            vm.row = row;
                            return vm;
                        }/*,
                         row: function () {
                         return row;
                         }*/
                    }
                });
                modalInstance.result.then(
                    function (newRow) {

                        // console.log(row);

                        var index = _.findIndex(vm.$storage.cart.items, {Item: row.Item});
                        var isInCart = _.findIndex(vm.$storage.cart.items, {Item: newRow.Item});

                        //console.log(index);

                        if (index >= 0 && isInCart < 0) {
                            vm.$storage.cart.items[index].Item = newRow.Item;
                            vm.getData();
                        } else if (isInCart >= 0) {
                            vm.alerts = {danger: ['Товар уже есть в корзине! Строка: ' + (isInCart + 1)]};
                        }

                        vm.bindTableItem();
                    },
                    function () {
                        vm.bindTableItem();
                    }
                );
            }


            function addToOrder(location, order) {
                vm.createOrder(location, order);
            }

            function createOrder(location, order) {
                vm.alerts = {};

                if (vm.data.filtered.length == 0) {
                    vm.alerts = {danger: ['Корзина пустая!']};
                    return false;
                }



                var post = {};
                post['location'] = location;

                if (order) {
                    post['activeOrder'] = order;
                }

                if (vm.data.selected.length) {
                    post['items'] = _.map(
                        vm.data.selected,
                        function (row) {
                            return {Item: row.Item, Qty: row.qnty};
                        }
                    );

                } else {
                    post['items'] = _.map(
                        vm.data.filtered,
                        function (row) {
                            return {Item: row.Item, Qty: row.qnty};
                        }
                    );
                }

                if (post['items'].length > 100) {
                    if(post['activeOrder']) {
                        vm.alerts = {danger: ['Товар не добавлен! Максимальное количество для добавления в заказ: 100 строк']};
                    } else {
                        vm.alerts = {danger: ['Заказ не создан! Максимальное количество для создания заказа: 100 строк']};
                    }
                    return false;
                }

                $http.post("/api/cart/createorder", post)
                    .success(function (data) {
                        if (!data.nostock) {

                            vm.data.lastSelectedItems = [];
                            vm.data.lastOrderItems = {};
                            vm.data.lastRemovedItems = [];

                            vm.data.lastRemovedItems = _.remove(vm.$storage.cart.items, function(row) {
                                return _.has(data.ok, row.Item);
                            });

                            vm.data.lastOrderItems = data.ok;

                            vm.data.lastSelectedItems = vm.data.selected;

                            //console.log(vm.removed);

                           /* angular.forEach(data.order, function (val, key) {
                                if (key != '$id') {
                                    //var index = _.findIndex(vm.$storage.cart.items, {Item: val.No});
                                    //if(index != -1) vm.data.items.splice(index, 1);
                                    //var index = _.findIndex(vm.data.filtered, {Item: val.No});
                                    //vm.data.filtered.splice(index, 1);
                                }
                            });*/
                            vm.data.active = -1;
                            vm.getData();
                            vm.updateFilters()
                        } else {
                            if (vm.data.selected.length) {
                                angular.forEach(data.items[location], function (val, key) {
                                    if (key != '$id') {
                                        var index = _.findIndex(vm.data.selected, {Item: key});
                                        vm.data.selected.splice(index, 1);
                                    }
                                });
                            }
                            //vm.data.selected = [];
                            //vm.data.isAllSelect = false;
                            vm.data.active = -1;
                            vm.data.stockReal = data.items;
                        }
                        vm.alerts = data.alerts;
                    });
            }

            function createOrderAsync(location, order) {
                vm.alerts = {};

                if (vm.data.filtered.length == 0) {
                    vm.alerts = {danger: ['Корзина пустая!']};
                    return false;
                }

                var post = {};
                post['location'] = location;

                if (order) {
                    post['activeOrder'] = order;
                }

                if (vm.data.selected.length) {
                    post['items'] = _.map(
                        vm.data.selected,
                        function (row) {
                            return {Item: row.Item, Qty: row.qnty};
                        }
                    );
                } else {
                    post['items'] = _.map(
                        vm.data.filtered,
                        function (row) {
                            return {Item: row.Item, Qty: row.qnty};
                        }
                    );
                }

                $http.post("/api/cart/createorderasync", post,{ignoreLoadingBar: true})
                    .success(function (data) {




                        /*if (!data.nostock) {
                            angular.forEach(data.ok, function (val, key) {
                                if (key != '$id') {
                                    var index = _.findIndex(vm.data.items, {Item: key});
                                    vm.data.items.splice(index, 1);
                                    var index = _.findIndex(vm.data.filtered, {Item: key});
                                    vm.data.filtered.splice(index, 1);
                                }
                            });
                            vm.data.active = -1;
                            //vm.getData();
                            //vm.updateFilters()
                        } else {
                            if (vm.data.selected.length) {
                                angular.forEach(data.items[location], function (val, key) {
                                    if (key != '$id') {
                                        var index = _.findIndex(vm.data.selected, {Item: key});
                                        vm.data.selected.splice(index, 1);
                                    }
                                });
                            }
                            //vm.data.selected = [];
                            //vm.data.isAllSelect = false;
                            vm.data.active = -1;
                            vm.data.stockReal = data.items;
                        }
                        vm.alerts = data.alerts;*/
                    });
            }


            function getData() {
                vm.resetItems();
                vm.getItems();
            }

            function getItems() {
                if (vm.$storage.cart.items.length > 0) {
                    $http.post('/api/cart/items', vm.$storage.cart.items)
                        .success(function (data) {
                            vm.setItems(data.items);
                            vm.setStock(data.stock);
                            vm.data.LocationList = data.loc;
                            vm.calcAll();
                            vm.getOpenOrders();
                        });
                }
            }

            function resetItems() {
                //vm.data.filters = [];
                vm.data.items = vm.$storage.cart.items;
                vm.data.filtered = vm.$storage.cart.items;
                vm.data.isAllSelect = false;
                vm.data.selected = [];
            }

            function setItems(data) {
                var items = {};
                angular.forEach(data, function (val, key) {
                    if (!items[val.Item]) {
                        items[val.Item] = val;
                    }
                });
                vm.data.cartItems = items;
            }

            function setStock(data) {
                var stock = {};
                angular.forEach(data, function (val, key) {
                    if (!stock[val.ItemNo]) {
                        stock[val.ItemNo] = {};
                    }
                    stock[val.ItemNo][val.LocationCode] = val.Qty;
                });
                vm.data.stock = stock;
            }

            function calcTotal() {
                $timeout(function () {
                    var lsComId = localStorage.getItem('ComId');
                    var total = {money: 0, qty: 0};
                    angular.forEach(vm.data.items, function (row, key) {
                        row.qnty = Number(row.qnty);
                        total.qty = total.qty + row.qnty;
                        if (vm.data.cartItems[row.Item] != undefined) total.money = total.money + row.qnty * Number(vm.data.cartItems[row.Item]['Price']);
                        if (lsComId != '16' && Number(row.qnty) > 50) {  
                            vm.data.items[key].qnty = 50;
                            vm.calcAll();
                        }
                    });
                    vm.data.total = total;
                });
            }

            function calcFiltered() {
                if (vm.data.filtered.length != vm.data.items.length) {
                    $timeout(function () {
                        var total = {money: 0, qty: 0};
                        angular.forEach(vm.data.filtered, function (row) {
                            row.qnty = Number(row.qnty);
                            total.qty = total.qty + row.qnty;
                            total.money = total.money + row.qnty * Number(vm.data.cartItems[row.Item]['Price']);
                        });
                        vm.data.totalFiltered = total;
                    });
                }
            }

            function calcSelected() {
                if (vm.data.selected.length > 0) {
                    $timeout(function () {
                        var total = {money: 0, qty: 0};
                        angular.forEach(vm.data.selected, function (row) {
                            row.qnty = Number(row.qnty);
                            total.qty = total.qty + row.qnty;
                            total.money = total.money + row.qnty * Number(vm.data.cartItems[row.Item]['Price']);
                        });
                        vm.data.totalSelected = total;
                    });
                }
            }

            function calcAll() {
                vm.calcTotal();
                vm.calcFiltered();
                vm.calcSelected();
            }

            function getOpenOrders() {
                $http.post("/api/orders/list",{},{ignoreLoadingBar: true})
                    .success(function (data) {
                        vm.data.openOrders = data.rows;
                    });
            }

            function openToOrder() {
                var modalInstance = $modal.open({
                    templateUrl: "/app/cart/to-order-modal.html",
                    controller: "ToOrderModalController",
                    controllerAs: "vm",
                    // size: 'sm',
                    windowClass: "no-animation-modal",
                    resolve: {
                        parent: function () {
                            return vm;
                        }
                    }
                });
                modalInstance.result.then(
                    function (location) {
                        //vm.currentLocation = location;
                        vm.bindTableItem();
                        vm.createOrder(location);
                    },
                    function () {
                        vm.bindTableItem();
                    }
                );
            }

            function openToOrderAsync() {
                var modalInstance = $modal.open({
                    templateUrl: "/app/cart/to-order-modal.html",
                    controller: "ToOrderModalController",
                    controllerAs: "vm",
                    // size: 'sm',
                    windowClass: "no-animation-modal",
                    resolve: {
                        parent: function () {
                            return vm;
                        }
                    }
                });
                modalInstance.result.then(
                    function (location) {
                        //vm.currentLocation = location;
                        vm.bindTableItem();
                        vm.createOrderAsync(location);
                    },
                    function () {
                        vm.bindTableItem();
                    }
                );
            }

            function bindKeys() {
                hotkeys
                    .add({
                        combo: "alt+o",
                        description: "Создать заказ",
                        allowIn: ["INPUT", "SELECT", "TEXTAREA"],
                        callback: function (event, hotkey) {
                            event.preventDefault();
                            vm.openToOrder();
                        }
                    });
            }

        }]);
