'use strict';


angular.module('app')
    .controller('VinGroupsItemsController',
    ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", '$state', '$stateParams', "hotkeys", "userCarService",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, $state, $stateParams, hotkeys, userCarService) {

            var vm = this;

            //$scope.isNavView = $rootScope.isNavView;

            vm.$storage = $localStorage;
            vm.$stateParams = $stateParams;
            //vm.$state = $state;
            vm.$rootStateParams = $rootScope.$stateParams;
            vm.comId = localStorage.getItem('ComId');

            vm.data = {
                numView: 0,
                view: 'navview',
                items: [],
                filtered: [],
                groups: {},
                oegroups: {},
                grp: [],
                subgrp: [],
                groupActive: false,
                collapseIn: false,
                collapseIn2: false,
                collapseIn3: false,
                grpPath: [],
                //grpName: '',
                activeGroup: false
            };
            vm.go = go;
            vm.isActive = isActive;
            vm.isIn = isIn;
            vm.isInFirst = isInFirst;
            vm.setGrpPath = setGrpPath;
            vm.activeSubGroup = activeSubGroup;
            vm.activeGroup = activeGroup;
            vm.getData = getData;
            vm.saveGroup = saveGroup;
            vm.addGroups = addGroups;
            vm.saveToHistory = saveToHistory;
            vm.getTypeName = getTypeName;
            vm.goOeUnit = goOeUnit;

            function setGrpPath() {
                if (vm.$rootStateParams.grpId) {
                    //vm.data.collapseIn = false;
                    vm.data.grpPath = vm.$rootStateParams.grpId.split('-');
                    vm.data.activeGroup = vm.data.grpPath[0];
                }
            }

            $scope.$watch("vm.$rootStateParams.grpId", function (art) {
                vm.setGrpPath();
            });

            vm.getData();

            function go(rows) {
                $rootScope.vv.isNavView = false;
                var grps = [];
                angular.forEach(rows, function (row) {
                    grps.push(row.quickgroupidField);
                });
                if (rows[0].rowField == null) {


                    //vm.data.activeGroup = rows[0].quickgroupidField;

                    //$rootScope.title = rows[0].nameField +' '+ $rootScope.tag + ' VIN:' + vm.$rootStateParams.vin;

                    vm.data.grpPath = [];

                    //console.log(rows[0].nameField);
                    $state.go('vin.grp', {grpId: grps.join('-')}).then(function () {
                        $timeout(function () {
                            vm.saveToHistory({vin: vm.$rootStateParams.vin, type: vm.$rootStateParams.typeId, grpName: rows[0].nameField, path: grps.join('-')});
                        },1000);
                    });

                    //


                }
            }

            function goOeUnit(row) {
                $rootScope.vv.isNavView = false;
                $state.go('vin.units', {categoryId: row.categoryidField, ssdId: row.ssdField});
            }

            function isActive(row) {
                if (row.rowField == null) {
                    return vm.data.activeGroup == row.quickgroupidField;
                } else {
                    return false;
                }
            }

            function isIn(row) {
                var index = _.indexOf(vm.data.grpPath, row.quickgroupidField.toString());

                if (vm.data.grpPath.length) {
                    if (vm.data.grpPath[0] == row.quickgroupidField) {
                        $rootScope.title = row.nameField + ' ' + $rootScope.tag + ' VIN: ' + vm.$rootStateParams.vin;
                        //vm.saveToHistory({vin: vm.$rootStateParams.vin, type: vm.$rootStateParams.type, grpName: row.nameField});
                    }
                }

                if (index >= 0) {
                    return true;
                } else {
                    return false;
                }
            }

            function isInFirst(row) {

                var result = false;

                if (vm.data.collapseIn == row.quickgroupidField) {
                    result = true;
                }

                var index = _.indexOf(vm.data.grpPath, row.quickgroupidField.toString());
                if (index >= 0) {
                    result = true;
                }
                return result;
            }

            function activeGroup(row) {
                if (vm.data.collapseIn == row.quickgroupidField) {
                    vm.data.collapseIn = false;
                } else {
                    vm.data.collapseIn = row.quickgroupidField;
                }
            }

            function activeSubGroup() {
                if ($rootScope.$stateParams.grpId) {
                    var index = _.findIndex(vm.data.subgrp, {name: $rootScope.$stateParams.grpId});
                    if (index) vm.data.groupActive = vm.data.subgrp[index].grp;
                }
            }

            function getData() {
                var params = {vin: $stateParams.vin, type: $stateParams.typeId};
                
                if($stateParams.ssd){
                    params.ssd = $stateParams.ssd;
                    params.catalog = $stateParams.catalog;
                }
                
                $http.get("/api/vin/typegroups", {params:params,cache:false})
                    .success(function (data, status) {
                        //vm.$storage.vinType = data.type;
                        vm.$storage.vinCatalog = data.catalog;


                        if (data.tree) vm.data.groups = data.tree.rowField[0].rowField;
                        if (data.oetree) vm.data.oegroups = data.oetree.rowField;
                        vm.setGrpPath();

                        params.mark = data.catalog.rowField.nameField;
                        if(data.type && data.type.nameField != undefined) {
                            params.model = data.type.nameField;
                        }

                        params.name = vm.getTypeName(data);

                        $rootScope.tag = params.name;

                        if($stateParams.ssd){
                           // $rootScope.tag = data.type[0].name;
                        } else {
                            vm.$storage.vinType = data.type;
                            vm.saveToHistory(params);
                        }

                        $rootScope.title = $rootScope.tag + ' VIN: ' + vm.$rootStateParams.vin;
                    });
            }

            function saveToHistory(params) {

                var row = {
                    CatId: 2,
                    CarName: params.name,
                    Mark: params.mark,
                    Model: params.model,
                    TypeId: vm.$rootStateParams.typeId,
                    Vin: params.vin
                };

                if (vm.$rootStateParams.grpId) {
                    row.Path = vm.$rootStateParams.grpId;
                }

                if (params.path) {
                    row.Path = params.path;
                }

                if (params.grpName) {
                    row.SubGrp = params.grpName;
                }

                userCarService.add(row);


                /* var index = _.findIndex(vm.$storage.vinHistory, {vin: row.vin});
                 if (index >= 0) {
                 if (vm.$rootStateParams.grpId) {
                 vm.$storage.vinHistory[index].grp = vm.$rootStateParams.grpId;
                 vm.$storage.vinHistory[index].grpName = row.grpName;
                 }
                 } else {

                 //var row = {vin: params.vin, type: params.type};

                 if (vm.$rootStateParams.grpId) {
                 row.grp = vm.$rootStateParams.grpId;
                 }

                 vm.$storage.vinHistory.unshift(row);

                 if (vm.$storage.vinHistory.length > 20) {
                 vm.$storage.vinHistory.pop();
                 }
                 }*/
            }

            function getTypeName(data) {
                var nameFull = [];

                if (data.catalog.rowField.nameField != undefined) nameFull.push(data.catalog.rowField.nameField);
                if(data.type){
                if (data.type.nameField != undefined) nameFull.push(data.type.nameField);
                if (data.type.gradeField != undefined)  nameFull.push(data.type.gradeField);
                if (data.type.gradeField != undefined)  nameFull.push(data.type.gradeField);
                if (data.type.engineField != undefined) nameFull.push(data.type.engineField);
                }
                /*data.catalog.rowField.nameField
                 +" "+data.type.nameField
                 +" "+data.type.gradeField
                 +" "+data.type.gradeField
                 +" "+data.type.engineField
                 ;*/

                nameFull = nameFull.join(" ");

                if (vm.$storage.vinType) {
                    vm.$storage.vinType.nameFull = nameFull;


                    //$rootScope.car = nameFull;
                    //$rootScope.title = $filter('capitalize')(vm.$stateParams.grpId) + ' ' + vm.$storage.vinType.nameFull;

                    //$rootScope.header = nameFull + 'VIN: '+vm.$rootStateParams.vin + ' ';
                }

                return nameFull;
            }


            function addGroups(car) {

                //car.groups = _.flattenDeep(car.tree.rowField[0].rowField);
                var grp = [];
                var subgrp = [];

                angular.forEach(car.tree.rowField[0].rowField, function (row, key) {
                    grp.push({code: row.quickgroupidField, name: row.nameField});

                    if (row.rowField) {
                        angular.forEach(row.rowField, function (row2, key) {
                            subgrp.push({
                                code: row2.quickgroupidField,
                                name: row2.nameField,
                                name2: row2.nameField,
                                grp: row.quickgroupidField
                            });
                            if (row2.rowField) {
                                angular.forEach(row2.rowField, function (row3, key) {
                                    subgrp.push({
                                        code: row3.quickgroupidField,
                                        name: row3.nameField,
                                        name2: ' -- ' + row3.nameField,
                                        grp: row.quickgroupidField
                                    });
                                    if (row3.rowField) {
                                        angular.forEach(row3.rowField, function (row4, key) {
                                            subgrp.push({
                                                code: row4.quickgroupidField,
                                                name: row4.nameField,
                                                name2: ' ---- ' + row4.nameField,
                                                grp: row.quickgroupidField
                                            });
                                        });
                                    }
                                });
                            }
                        });
                    }
                });

                vm.data.grp = grp;
                vm.data.subgrp = subgrp;
            }

            function saveGroup(group) {
                var index = _.findIndex(vm.$storage.carHistory, {code: $rootScope.$stateParams.typeId});
                if (index >= 0) vm.$storage.carHistory[index].grp = group;
            }
        }]);

