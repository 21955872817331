'use strict';

angular.module('settings')
    .controller('UserPriceModalController', ['$scope', '$http', '$timeout', '$modalInstance', '$localStorage', 'hotkeys', '$log', '$filter', '$modal', 'parent','RestService',
        function ($scope, $http, $timeout, $modalInstance, $localStorage, hotkeys, $log, $filter, $modal, parent, RestService) {

            var vm = this;

            vm.data = {
                search: '',
                header: {},
                items: [],
                filtered: [],
                brands:[],
                groups: [],
                grp: [],
                subgrp: [],
                isMain:false,
                MarkupPercent:'',
                Markup:''
            };

            vm.rest = RestService;
            vm.rest.set({name: 'UserPrice', url: '/api/userprice'});
            vm.model = {Markup:0};


            vm.parent = parent;
            vm.getData = getData;
            vm.getModel = getModel;
            vm.setMarkup = setMarkup;
            vm.setMarkupPercent = setMarkupPercent;
            vm.updateSubGrp = updateSubGrp;
            vm.save = save;
            vm.ok = ok;
            vm.cancel = cancel;


            vm.getModel();

            $scope.$watchCollection("vm.model", function (model, oldModel) {
                vm.updateSubGrp(model);
            });

            /*$scope.$watch("vm.data.Markup", function (val) {
                //vm.data.MarkupPercent = '';
                //vm.model.Markup = parseFloat(val);
            });*/

            /*$scope.$watch("vm.data.MarkupPercent", function (val) {
                //vm.data.Markup = '';
                vm.model.Markup = Math.round(((1+parseFloat(val)/100)) * 10000) / 10000;
            });*/

            function setMarkupPercent() {
                //vm.data.Markup.replace(',','.'); TODO: добавить replace setMarkupPercent

                vm.data.MarkupPercent = Math.round(((parseFloat(vm.data.Markup)-1)*100) * 100) / 100;
                vm.model.Markup = parseFloat(vm.data.Markup);
            }

            function setMarkup() {
                vm.data.Markup = Math.round(((parseFloat(vm.data.MarkupPercent)/100+1)) * 10000) / 10000;
                vm.model.Markup = parseFloat(vm.data.Markup);
            }

            function updateSubGrp(model) {
                //console.log(_.filter(vm.data.allSubGrp, { 'grp': 'Фильтры' }));
                var subgrp = [];
                angular.forEach(_.filter(vm.data.allSubGrp, { 'grp': model.Grp }),function(row){
                    if(row.code != '') {
                        subgrp.push(row.code);
                    }
                });
                vm.data.subgrp = subgrp;
            }

            function getModel() {
                if(parent && parent.id){

                    var params = {id:parent.id};
                    vm.rest.get(function(data){
                        var model = data;
                        // if(model.Start) model.Start = model.Start.replace('T00:00:00','');
                        //if(model.End) model.End = model.End.replace('T00:00:00','');
                        vm.model = model;
                        vm.data.Markup = vm.model.Markup;
                        vm.setMarkupPercent();
                        vm.getData();
                    }, params);
                } else {
                    vm.getData();
                }
            }

            function getData() {
                $http.get('/api/catalog/brands')
                    .success(function (data) {
                        var brands = [];

                        angular.forEach(data,function(row){
                            if(row.Brand != '') {
                                brands.push(row.Brand);
                            }
                        });
                        vm.data.brands = brands;
                        $http.get('/api/catalog/allgroups')
                            .success(function (data) {
                                var grp = [];

                                angular.forEach(data.grp,function(row){
                                    if(row.code != '') {
                                        grp.push(row.code);
                                    }
                                });


                                vm.data.grp = grp;
                                vm.data.groups = data.groups;
                                vm.data.allSubGrp = data.subgrp;
                               $timeout(function(){
                                   vm.updateSubGrp(vm.model);
                               });
                            });
                    });
            }

            function save() {
                if (vm.model && vm.model.id != undefined && vm.model.id) {
                    vm.rest.update(vm.model.id, vm.model, function (data) {
                        if (data.alerts) {
                            vm.alerts = data.alerts;
                        } else {
                            $modalInstance.close(vm.data.form);
                        }
                    });
                } else {
                    vm.rest.add(vm.model, function (data) {
                        if (data.alerts) {
                            vm.alerts = data.alerts;
                        } else {
                            $modalInstance.close(vm.data.form);
                        }
                    });
                }
            }

            function ok(row) {
                $modalInstance.close(row);
            }

            function cancel() {
                $modalInstance.dismiss('cancel');
            }
        }]);
