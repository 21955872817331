'use strict';

angular.module('app')
    .controller('VinUnitListController',
    ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", '$state', '$stateParams', "hotkeys",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, $state, $stateParams, hotkeys) {

            var vm = this;
            vm.$storage = $localStorage;
            vm.$stateParams = $stateParams;
            vm.$rootStateParams = $rootScope.$stateParams;

            vm.alerts = {};

            vm.data = {
                items: [],
                filtered: [],
                active: -1//,
                //grpPath: vm.$stateParams.grpId.split('-')
            };

            vm.getData = getData;
            vm.openUnit = openUnit;

            vm.getData();


            function openUnit(unit) {
                var modalInstance = $modal.open({
                    templateUrl: "/app/vin/vin-unit-modal.html",
                    controller: "VinUnitModalController",
                    size: 'lg',
                    windowClass: "no-animation-modal full",
                    resolve: {
                        parent: function () {
                            return vm;
                        },
                        unit: function () {
                            return unit;
                        }
                    }
                });
                modalInstance.result.then(function (item) {
                    //$state.go('vin.grp.oem', {art: item});
                    $state.go('vin.grp.oem', {art: item});
                }, function () {
                });
            }

            function getData() {
                var params = {
                    type: vm.$storage.vinType,
                    catalog: vm.$storage.vinType.catalogField,
                    vehicleid: vm.$storage.vinType.vehicleId,
                    categoryId:$stateParams.categoryId,
                    ssd:$stateParams.ssdId
                };
                //var params = vm.$storage.vinType;
                $http.post("/api/catalogoem/typeunits", params)
                    .success(function (data, status) {
                        //vm.$storage.vinType = data.type;
                        //vm.$storage.vinCatalog = data.catalog;
                        if(data.units) {
                            vm.data.items  = data.units.rowField;
                            vm.data.filtered = data.units.rowField;
                        }
                        //vm.setGrpPath();
                        //params.name = vm.getTypeName(data);
                        //vm.saveToHistory(params);
                    });
            }


            /*

            vm.go = go;

            vm.getData();

            function getData() {
                $http.post("/api/catalogoem/items?id=" + vm.data.grpPath[0], vm.$storage.vinType)
                    .success(function (data, status) {
                        if(data.items && data.items.categoryField){
                            vm.data.items = data.items.categoryField;
                            vm.data.filtered = data.items.categoryField;
                        } else {
                            vm.alerts = {warning:['ОЕ-артикулы не найдены']}
                        }
                    });
            }

            function go(row) {
                if (row.oemField != null) {
                    $state.go('vin.grp.oem', {art: row.oemField});
                }
            }*/
        }]);

