'use strict';


angular.module('reports')
    .controller('ReturnsController',
        ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", "hotkeys", "cfpLoadingBar",
            function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, hotkeys, cfpLoadingBar) {

                $rootScope.title = 'Возвраты';

                var vm = this;
                var model = $scope.model = { alerts: [] };
                vm.alerts = {};

                vm.data = {
                    items: [],
                    filtered: []
                };

                vm.getData = getData;
                vm.getData();

                function getData() {

                    $http.post('/api/returns/list')
                        .success(function (data) {
                            vm.data.filtered = data.rows;
                        }).error(function (error) { console.error(error); });

                }

                $scope.create = function () {
                    cfpLoadingBar.start();
                    $http.post("/api/Returns/ReturnAdd")
                        .success(function (response) {
                            response = response.replace(/"/g, '');
                            if (response.toLowerCase().startsWith('ok')) {
                                var docNo = response.split(':')[1];
                                window.location.assign("/reports/returns/"+docNo);
                            } else {
                                $scope.addAlerts({ danger: [response] });
                            }
                            cfpLoadingBar.complete();
                        });
                };

                $scope.closeAlert = function (index) {
                    delete model.alerts[index];
                };

                $scope.addAlerts = function (alerts) {
                    model.alerts = alerts;
                    if (model.alerts.success && model.alerts.success.length > 0) {
                        $timeout(function () {
                            delete model.alerts.success;
                        }, 4000);
                    }
                };

            }]);
