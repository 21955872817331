'use strict';

angular.module('app')
    .controller('CarHistoryModalController', ['$scope', '$http', '$timeout', '$modalInstance', '$localStorage', 'hotkeys', '$log', '$filter', '$modal',  '$state', 'parent', 'userCarService',
        function ($scope, $http, $timeout, $modalInstance, $localStorage, hotkeys, $log, $filter, $modal,  $state, parent, userCarService) {

            /*var model = $scope.model = {
             // Модели для списков
             //col: 0,
             //cols: 1,
             items: $scope.$storage.cars,
             filtered: $scope.$storage.cars,
             selected: [],
             active: -1,
             search: {},
             total: [0],
             // Модели для контроллера
             // Parent model
             parent: parent
             };*/
            var vm = this;
            vm.$storage = $localStorage;


            vm.data = {
                container: '.modal',
                items: vm.$storage.cars,
                filtered: vm.$storage.cars,
                selected: [],
                active: -1,
                search: ''
            };

            //console.log($scope);

            vm.deleteRow = deleteRow;
            vm.deleteRows = deleteRows;
            vm.actionRow = actionRow;
            vm.ok = ok;
            vm.cancel = cancel;
            vm.getUserCars = getUserCars;
            vm.goToUserCar = goToUserCar;

            vm.getUserCars();


            function goToUserCar(event, car) {
                event.preventDefault();
                if(car.Vin){
                    if (car.Path) {
                        $state.go('vin.grp', {vin: car.Vin, typeId: car.TypeId, grpId: car.Path, ssd:'', catalog:''});
                    } else {
                        $state.go('vin', {vin: car.Vin, typeId: car.TypeId, ssd:'', catalog:''});
                    }
                } else  {
                    if (car.SubGrp) {
                        $state.go('car.items', {typeId: car.TypeId, grpId: car.SubGrp});
                    } else {
                        $state.go('car', {typeId: car.TypeId});
                    }
                }
                vm.cancel();
            }

            /*function __goToUserCar(event, car) {
                event.preventDefault();
                if (car.SubGrp) {
                    $state.go('car.items', {typeId: car.TypeId, grpId: car.SubGrp});
                } else {
                    $state.go('car', {typeId: car.TypeId});
                }
                //if (index) $rootScope.lastCar = {index: index, type: 'car'};
            }

            function goToUserVinCar(event, car) {
                event.preventDefault();
                if (car.Path) {
                    $state.go('vin.grp', {vin: car.Vin, typeId: car.TypeId, grpId: car.Path});
                } else {
                    $state.go('vin', {vin: car.Vin, typeId: car.TypeId});
                }
            }*/


            function getUserCars() {
                //console.log($scope);
                userCarService.get(function (data) {
                    vm.data.filtered = data.items;
                    vm.data.items = data.items;
                });
            }

            function deleteRows() {
                if (confirm("Удалить выбранные заказы?")) {
                    model.alerts = [];
                    $http.post("/api/orders/delete", model.selected)
                        .success(function (data) {
                            $scope.addAlerts(data.alerts);
                            $scope.getData();
                        });
                    return true;
                } else {
                }
            }

            function deleteRow(row) {
                if (confirm("Удалить заказ № " + row.OrderNo + " ?")) {
                    model.alerts = [];
                    $http.post("/api/orders/delete", [row])
                        .success(function (data) {
                            $scope.addAlerts(data.alerts);
                            $scope.getData();
                        });
                    return true;
                } else {
                }
            }


            /*function deleteRows(event, row) {

             event.preventDefault();

             userCarService.del(row.CarId,function(){
             //vm.getUserCars();
             });


             //vm.$storage.carHistory.splice(index, 1);
             }

             function deleteRow(event, row) {

             event.preventDefault();

             userCarService.del(row.CarId,function(){
             //vm.getUserCars();
             });


             //vm.$storage.carHistory.splice(index, 1);
             }  */

            /*function deleteRow() {
             if (confirm("Удалить выбранные автомобили?")) {
             angular.forEach(vm.data.selected, function (row, key) {
             var index = _.findIndex($scope.$storage.cars, row);
             if (index >= 0) {
             $scope.$storage.cars.splice(index, 1);
             }
             });
             } else {}
             }*/

            function ok(index) {
                $modalInstance.close(index);
            }

            function cancel() {
                vm.data.filtered = [];
                vm.data.items = [];
                $modalInstance.dismiss('cancel');
            }

            function actionRow(row) {
                if (!row) {
                    row = vm.data.filtered[vm.data.active];
                }
                $scope.ok(row);
            }
        }]);
