'use strict';

angular.module('app')
    .controller('CarGroupsModalController', ['$scope', '$http', '$timeout', '$modalInstance', '$localStorage', 'hotkeys', '$log', '$filter', '$modal', 'parent', '$state', '$stateParams',
        function ($scope, $http, $timeout, $modalInstance, $localStorage, hotkeys, $log, $filter, $modal, parent, $state, $stateParams) {

            var vm = this;

            vm.data = {
                search: '',
                header: {},
                items: [],
                filtered: [],
                brands:[],
                groups: [],
                grp: [],
                subgrp: []
            };

            //vm.rest = RestService;
           // vm.rest.set({name: 'UserPrice', url: '/api/userprice'});
           // vm.model = {Markup:0};
            vm.ok = ok;
            vm.cancel = cancel;


            vm.parent = parent;

            function ok(row) {
                $modalInstance.close(row);
            }

            function cancel() {
                $modalInstance.dismiss('cancel');
            }
        }]);
