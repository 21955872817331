'use strict';


angular.module('app')
    .controller('GoodsGroupsItemsController',
    ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", '$state', '$stateParams', "hotkeys","userCarService",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, $state, $stateParams, hotkeys, userCarService) {

            var vm = this;

            vm.$storage = $localStorage;
            vm.$stateParams = $stateParams;
            //vm.$state = $state;
            vm.$rootStateParams = $rootScope.$stateParams;

            vm.data = {
                numView: 0,
                view: 'navview',
                items: [],
                filtered: [],
                subgrp: [],
                groupActive: false
            };
            vm.activeSubGroup = activeSubGroup;
            vm.getData = getData;
            //vm.saveGroup = saveGroup;
            //vm.saveToHistory = saveToHistory;

            vm.getData();


            $scope.$watch("vm.$rootStateParams.grpId", function (query) {
                if(vm.data.subgrp.length){
                    vm.activeSubGroup();
                }
            });

            // console.log($scope);

            function activeSubGroup() {
              //  $timeout(function() {
                    if($rootScope.$stateParams.grpId){
                        var index = _.findIndex(vm.data.subgrp, {name:$rootScope.$stateParams.grpId});
                        if(index>=0) vm.data.groupActive = vm.data.subgrp[index].grp;
                    }
                //});
            }

            function getData(code) {
                if (!code) {
                    code = $stateParams.typeId;
                }
                var params = {code: code};
                $http.get("/api/catalog/goodsgroups", {params: params})
                    .success(function (data, status) {

                        vm.data.items = $filter('orderBy')(data.groups, 'name');
                        vm.data.subgrp = data.subgrp;
                        $timeout(function() {
                            vm.activeSubGroup();
                           // vm.saveToHistory(data.type[0]);
                        });

                    });
            }

           /* function saveToHistory(car) {

                var row = {
                    CatId:1,
                    CarName:car.name_full,
                    Mark:car.mark,
                    Model:car.model,
                    TypeId:car.code
                };

                if($rootScope.$stateParams.grpId){
                    row.SubGrp = $rootScope.$stateParams.grpId;
                }


                userCarService.add(row);
            }*/


            /*

            function saveToHistory(car) {



                var index = _.findIndex(vm.$storage.carHistory, {code:car.code});
                if(index >= 0){
                    vm.$storage.carHistory[index].grp = $rootScope.$stateParams.grpId;
                } else {
                    var row = {code:car.code,name:car.name_full};

                    if($rootScope.$stateParams.grpId){
                        row.grp = $rootScope.$stateParams.grpId
                    }

                    vm.$storage.carHistory.unshift(row);
                    if (vm.$storage.carHistory.length > 20) {
                        vm.$storage.carHistory.pop();
                    }
                }
            }*/


            /*function saveGroup(group) {
                $rootScope.vv.isNavView = false;
                //var index = _.findIndex(vm.data.items, {TypeId:$rootScope.$stateParams.typeId});

                var row = {
                    TypeId:$rootScope.$stateParams.typeId,
                    SubGrp:group
                };

                userCarService.add(row);

                ///if(index >= 0) vm.$storage.carHistory[index].grp = group;
            }*/




        }]);

