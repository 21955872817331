angular.module('ui.list.items', [])

    .controller('ListItemsOldController', ['$scope', '$filter', '$timeout', 'hotkeys',
        function ($scope, $filter, $timeout, hotkeys) {



            var vm = $scope.vm;

           // vm.data = $scope.vm.data;

            //console.log($scope);


            //vm.$storage = $localStorage;
            vm.findActiveRow = findActiveRow;
            vm.isActiveRow = isActiveRow;
            vm.findSelectedRow = findSelectedRow;
            vm.isSelectedRow = isSelectedRow;
            vm.selectRow = selectRow;
            vm.activeRow = activeRow;
            vm.selectAllRows = selectAllRows;
            vm.scrollIntoView = scrollIntoView;
            vm.bindTableItem = bindTableItem;

            vm.data.isAllSelect = false;

            vm.data.shift = [false, false];

            vm.bindTableItem();

            //console.log($scope);

            // Фильтр по поиску
            $scope.$watchCollection("vm.search", function (query) {
                vm.data.active = -1;
                vm.data.selected = [];
                vm.data.filtered = $filter("filter")(vm.data.items, query);
            });

            $scope.$watchCollection("vm.selected", function (query) {
                if (vm.data.selected.length == 0) {
                    vm.data.shift = [false, false];
                }
            });

            // Поиск индекса активной строки
            function findActiveRow(row) {

                if (_.isObject(row)) {
                    var index = _.findIndex(vm.data.filtered, row);
                } else {
                    var index = _.indexOf(vm.data.filtered, row);
                }

                if (index >= 0) {
                    return index;
                } else {
                    return -2;
                }
            }

            // Проверка активна ли строка
            function isActiveRow(row) {

                //console.log(vm.findActiveRow(row) );

                if (vm.findActiveRow(row) == vm.data.active) {
                    return true;
                } else {
                    return false;
                }
            }

            // Поиск индекса выделенной строки
            function findSelectedRow(row) {
                if (_.isObject(row)) {
                    var index = _.findIndex(vm.data.selected, row);
                } else {
                    var index = _.indexOf(vm.data.selected, row);
                }

                if (index >= 0) {
                    return index;
                } else {
                    return -5;
                }
            }

            // Проверка выделена ли строка
            function isSelectedRow(row) {
                if (vm.findSelectedRow(row) >= 0) {
                    return true;
                } else {
                    return false;
                }
            }

            // Выделение строки
            function selectRow(row) {

                //console.log($scope);

                if (!row) {
                    row = vm.data.filtered[vm.data.active];
                }

                var indexRow = _.findIndex(vm.data.filtered, row);

                var index = vm.findSelectedRow(row);
                if (index >= 0) {
                    vm.data.selected.splice(index, 1);
                    return false;
                } else {

                    if (row) vm.data.selected.push(row);

                    if (!shifted && !vm.data.shift[1]) {
                        vm.data.shift[0] = indexRow;
                    }

                    if (shifted && vm.data.shift[0]) {
                        vm.data.shift[1] = indexRow;
                    }

                    if (shifted && vm.data.shift[0] && vm.data.shift[1]) {
                        var start = vm.data.shift[0];
                        var end = vm.data.shift[1];

                        if (start < end) {
                            for (var i = start + 1; i < end; i++) {
                                vm.data.selected.push(vm.data.filtered[i])
                            }
                        } else {
                            for (var i = end + 1; i < start; i++) {
                                vm.data.selected.push(vm.data.filtered[i])
                            }
                        }
                        vm.data.shift = [false, false];
                    }
                    return true;
                }
            }

            // Активирование строки
            function activeRow(index) {
                vm.data.active = index;
            }

            // Выделение всех строк
            function selectAllRows() {
                vm.data.isAllSelect = !vm.data.isAllSelect;
                vm.data.shift = [false, false];

                if (!vm.data.isAllSelect) {
                    vm.data.selected = [];
                    return true;
                }


                angular.forEach(vm.data.filtered, function (row, key) {
                    var index = vm.findSelectedRow(row);
                    if (index >= 0) {
                        if (!vm.data.isAllSelect) vm.data.selected.splice(index, 1);
                    } else {
                        if (vm.data.isAllSelect) vm.data.selected.push(row);
                    }
                });
            }

            function scrollIntoView(index, element, container) {

                if (!container) {
                    container = vm.data.container;
                }

                var compensation;

                if (container == ".modal") {
                    compensation = 114;
                } else {
                    var c = $(container).position();
                    compensation = c.top;
                }


                var containerTop = $(container).scrollTop();
                var containerBottom = containerTop + $(container).height() - compensation;
                var offset = element.position();
                var elemTop = offset.top + compensation;
                var elemBottom = elemTop + $(element).height();

                if (elemTop < containerTop) {
                    if (index == 0) {
                        $(container).scrollTop(0);
                    } else {
                        $(container).scrollTop(elemTop);
                    }
                } else if (elemBottom > containerBottom) {

                    var vv = elemBottom - $(container).height() + compensation;

                    $(container).scrollTop(vv);
                    // console.log('toScroll:' +vv);
                    //$(container).scrollTop(containerBottom);
                }
            }

            function bindTableItem() {

                hotkeys
                    .add({
                        combo: "up",
                        description: "Движение вверх по списку",
                        allowIn: ["INPUT", "SELECT", "TEXTAREA"],
                        callback: function (event, hotkey) {
                            event.preventDefault();
                            if (vm.data.active >= 0) {
                                vm.data.active -= 1;
                            }
                            if (vm.data.active < 0) {
                                vm.data.active = vm.data.filtered.length - 1;
                            }
                            if ($("#grid_row_" + vm.data.active).length) vm.scrollIntoView(vm.data.active, $("#grid_row_" + vm.data.active));
                        }
                    });

                hotkeys
                    .add({
                        combo: "pageup",
                        description: "Движение вверх по списку",
                        allowIn: ["INPUT", "SELECT", "TEXTAREA"],
                        callback: function (event, hotkey) {
                            event.preventDefault();
                            if (vm.data.active >= 0) {
                                vm.data.active -= 10;
                            }
                            if (vm.data.active < 0) {
                                vm.data.active = vm.data.filtered.length - 1;
                            }
                            if ($("#grid_row_" + vm.data.active).length) vm.scrollIntoView(vm.data.active, $("#grid_row_" + vm.data.active));
                        }
                    });

                hotkeys.add({
                    combo: "down",
                    description: "Движение вниз по списку",
                    allowIn: ["INPUT", "SELECT", "TEXTAREA"],
                    callback: function (event, hotkey) {
                        event.preventDefault();
                        if (vm.data.active <= (vm.data.filtered.length - 1)) {
                            vm.data.active += 1;
                        }
                        if (vm.data.active > vm.data.filtered.length - 1) {
                            vm.data.active = 0;
                        }
                        if ($("#grid_row_" + vm.data.active).length) vm.scrollIntoView(vm.data.active, $("#grid_row_" + vm.data.active));
                    }

                });

                hotkeys.add({
                    combo: "pagedown",
                    description: "Движение вниз по списку",
                    allowIn: ["INPUT", "SELECT", "TEXTAREA"],
                    callback: function (event, hotkey) {
                        event.preventDefault();
                        if (vm.data.active <= (vm.data.filtered.length - 1)) {
                            vm.data.active += 10;
                        }
                        if (vm.data.active > vm.data.filtered.length - 1) {
                            vm.data.active = 0;
                        }
                        if ($("#grid_row_" + vm.data.active).length) vm.scrollIntoView(vm.data.active, $("#grid_row_" + vm.data.active), ".mainview");
                    }

                });

                hotkeys.add({
                    combo: "space",
                    description: "Выбор строки",
                    //allowIn: ["INPUT", "SELECT", "TEXTAREA"],
                    callback: function (event, hotkey) {
                        event.preventDefault();
                        if (vm.selectRow) vm.selectRow();

                        if (vm.data.active <= (vm.data.filtered.length - 1)) {
                            vm.data.active += 1;
                        }
                        if (vm.data.active > vm.data.filtered.length - 1) {
                            vm.data.active = 0;
                        }

                        //model.active += 1;
                    }
                });
                hotkeys.add({
                    combo: "enter", description: "Открытие", //allowIn: ["INPUT", "SELECT", "TEXTAREA"],
                    callback: function (event, hotkey) {
                        event.preventDefault();
                        if (vm.actionRow) vm.actionRow();
                    }
                });
            }



            /*var destroyed;
             $scope.$on('$destroy', function() {
             destroyed = true;
             //$scope.$parent.bindTableItem();

             //console.log($scope);

             //if(parent) parent.bindAll();
             });*/
        }])
    .directive('listItems', function () {
        return {
            restrict: 'EA',
            transclude: false,
            replace: false,
            /*scope: {
                model: '='
            },*/
            controller: 'ListItemsOldController',
            controllerAs: 'list',
            bindToController:true,
            link: function (scope, element, attrs) {

            }
        };
    })
;