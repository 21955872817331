'use strict';

angular.module('app')
    .controller('VinOemItemsController',
    ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", '$state', '$stateParams', "hotkeys",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, $state, $stateParams, hotkeys) {

            var vm = this;
            vm.$storage = $localStorage;
            vm.$stateParams = $stateParams;
            vm.$rootStateParams = $rootScope.$stateParams;
            vm.comId = localStorage.getItem('ComId');

            vm.alerts = {};

            vm.data = {
                items: [],
                filtered: [],
                oemitems: {},
                active: -1,
                grpPath: vm.$stateParams.grpId.split('-')
            };
            vm.getData = getData;
            vm.getCrossed = getCrossed;
            vm.go = go;
            vm.openUnit = openUnit;

            vm.getData();

            function openUnit(unit) {
                var modalInstance = $modal.open({
                    templateUrl: "/app/vin/vin-unit-modal.html",
                    controller: "VinUnitModalController",
                    size: 'lg',
                    windowClass: "no-animation-modal full",
                    resolve: {
                        parent: function () {
                            return $scope.model;
                        },
                        unit: function () {
                            return unit;
                        }
                    }
                });
                modalInstance.result.then(function (item) {
                    $state.go('vin.grp.oem', {art: item});
                }, function () {
                });
            }

            function getData() {
                $rootScope.isNavView = false;

                var params = {id:vm.data.grpPath[0], vin:vm.$rootStateParams.vin, type:vm.$rootStateParams.typeId};

                if($stateParams.ssd){
                    params.ssd = $stateParams.ssd;
                    params.catalog = $stateParams.catalog;
                }

                $http.get("/api/vin/oemitems", {params:params, cache:true})
                    .success(function (data) {
                        if(data.items && data.items.categoryField){
                            vm.data.items = data.items.categoryField;
                            vm.data.filtered = data.items.categoryField;
                            // vm.getCrossed(vm.data.items);
                        } else {
                            vm.alerts = {warning:['ОЕ-артикулы не найдены']}
                        }

                    });
            }

            function getCrossed(items) {
                $http.post("/api/vin/getcrossed", items)
                    .success(function (data) {
                        vm.data.oemitems = data.items;
                    });
            }

            function go(row) {
                if (row.oemField != null) {
                    $state.go('vin.grp.oem', {art: row.oemField, note:row.noteField, name:row.nameField});
                }
            }
        }]);

