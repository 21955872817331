'use strict';

angular.module('reports')
    .controller('InvoiceLinesModalController', ['$scope', '$http', '$timeout', '$modalInstance', '$localStorage', 'hotkeys', '$log', '$filter', '$modal', 'parent','paymentMethod',
        function ($scope, $http, $timeout, $modalInstance, $localStorage, hotkeys, $log, $filter, $modal, parent, paymentMethod) {

            var vm = this;

            vm.data = {
                search: '',
                header: {},
                items: [],
                filtered: [],
                allowExport: false
            };

            vm.parent = parent;
            vm.paymentMethod = paymentMethod;
            vm.getData = getData;
            vm.exportToCsv = exportToCsv;
            vm.downloadBill = downloadBill;
            vm.downloadRB = downloadRB;
            vm.ok = ok;
            vm.cancel = cancel;

            vm.getData();

            function exportToCsv(docNo) {
                window.open('/api/reports/invoicelinestocsv?docno='+docNo,'_blank');
            }
            function downloadBill(docNo) {
                window.open("/api/reports/downloadFile?docNo=" + encodeURI(docNo) + "&type=1", "_blank");
            }
            function downloadRB(docNo) {
                window.open("/api/reports/downloadFile?docNo=" + encodeURI(docNo) + "&type=2", "_blank");
            }
            function getData() {
                var params = { docno: parent };

                $http.get('/api/Reports/InvoiceLines', {params:params})
                    .success(function (data) {
                        vm.data.items = data.items;
                        vm.data.filtered = data.items;
                        vm.data.header = data.header[0];
                        vm.data.allowExport = data.allowExport;
                    });
            }

            function ok(row) {
                $modalInstance.close(row);
            }

            function cancel() {
                $modalInstance.dismiss('cancel');
            }
        }]);
