'use strict';

angular.module('app')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("art", {
                url: "/art/:art",
                views:{
                    "@" : {
                        templateUrl: "/app/art/art.html",
                        controller: "ArtController",
                        controllerAs:"art"
                    },
                    'items@art':{
                        templateUrl: "/app/art/art-main-items.html",
                        controller: "ArtMainItemsController",
                        controllerAs:"vm"
                    },
                    'groups@art':{
                        templateUrl: "/app/art/art-groups-items.html",
                        controller: "ArtGroupsItemsController",
                        controllerAs:"vm"
                    }/*,
                    'toolbar@art':{
                        templateUrl: "/app/art/art-toolbar.html"//,
                        //controller: "ArtToolbarController",
                        //controllerAs:"vm"
                    }*/
                }
            })
            .state("art.group", {
                url: "/grp/:grpId",
                views:{
                    'items@art':{
                        templateUrl: "/app/art/art-main-items.html",
                        controller: "ArtMainItemsController",
                        controllerAs:"vm"
                    }
                }
            })
            .state("art.replace", {
                //url: "/item/:artId",
                params: {
                    artId:''
                },
                views:{
                    'replace@art': {
                        templateUrl: "/app/art/art-replace-items.html",
                        controller: "ArtReplaceItemsController",
                        controllerAs: "vm"
                    }
                }
            })
            .state("art.group.replace", {
                //url: "/item/:artId",
                params: {
                    artId:''
                },
                views:{
                    'replace@art': {
                        templateUrl: "/app/art/art-replace-items.html",
                        controller: "ArtReplaceItemsController",
                        controllerAs: "vm"
                    }
                }
            });
    }]);