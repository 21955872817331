"use strict";

angular.module("app")
    .controller("CartsController", ["$scope", "$rootScope","$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", "hotkeys", "$upload",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, hotkeys, $upload) {

            var vm = $scope.vm;

            vm.nameCart = '';
            vm.saveCart = saveCart; //Сохранить корзину
            vm.loadCart = loadCart; //Загрузить корзину
            vm.deleteCart = deleteCart; //Удалить корзину

           /* if(vm.$storage.cart.name != ''){
                vm.nameCart = vm.$storage.cart.name;
            }*/


            function saveCart() {

                var d = new Date();


                var row = angular.copy(vm.$storage.cart);

                //row.name = vm.nameCart;

                //vm.nameCart = '';

                row.createdAt = d.toLocaleTimeString() + ' ' + d.toLocaleDateString();

                vm.$storage.carts.unshift(row);

                if (vm.$storage.carts.length > 15) {
                    vm.$storage.carts.pop();
                }
                vm.$storage.cart.name = '';
                vm.$storage.cart.createdAt = '';
            }

           function loadCart(index) {
                if (vm.$storage.cart.items.length > 0) {
                    if(!vm.clear()){
                        return false;
                    }
                }
                vm.$storage.cart = angular.copy(vm.$storage.carts[index]);
                vm.getData();
            }

            function deleteCart(index) {
                vm.$storage.carts.splice(index, 1);
            }

        }]);

