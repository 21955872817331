'use strict';

//angular.module('reports', []);

angular.module('reports', [])
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("reports", {
                url: "/reports",
                views: {
                    "@": {
                        templateUrl: "/app/reports/reports.html",
                        controller: "ReportsController",
                        controllerAs: "vm"
                    },
                    'menu@reports': {
                        templateUrl: "/app/reports/reports-menu.html"
                    }
                }
            });
    }])
    .controller('ReportsController',
    ["$scope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", '$stateParams', "hotkeys",
        function ($scope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, $stateParams, hotkeys) {

            var vm = this;
            vm.data = {
                showWalletReport: false,
                allowReturns:false
            };


            vm.openNavModal = openNavModal;

            
            function openNavModal() {
                var modalInstance = $modal.open({
                    templateUrl: "/app/reports/reports-menu-modal.html",
                    controller: "ReportsMenuModalController",
                    controllerAs: "modal",
                    //size: 'lg',
                    windowClass: "no-animation-modal",
                    resolve: {
                        parent: function () {
                            return '';
                        }
                    }
                });
                modalInstance.result.then(
                    function () {
                        //vm.getData();
                    },
                    function () {
                        //vm.getData();
                    }
                );
            }

            getShowWallet();

            function getShowWallet() {
                //var now = new Date();
                //var formated = now.format("yyyy.MM.dd");

                //var params = { from: 'now', to: 'now' };
                
                $http.get('/api/reports/ShowWalletReport')
                    .success(function (data) {
                    //    //var items = data.items;
                    //    //vm.data.filtered = data.items;
                    //    //vm.data.from = data.from;
                    //    //vm.data.to = data.to;
                        //alert(data.items[0]['##Bal1']);
                        
                        vm.data.showWalletReport = (parseInt(data.show) > 0);
                        vm.data.allowReturns = (parseInt(data.AllowReturns) > 0);
                        //alert(vm.data.showWalletReport);
                    });
                

                vm.data.showWalletReport = false;
            }


        }])
    .controller('ReportsMenuModalController', ['$scope', '$http', '$timeout', '$modalInstance', '$localStorage', 'hotkeys', '$log', '$filter', '$modal', 'parent', '$state', '$stateParams',
        function ($scope, $http, $timeout, $modalInstance, $localStorage, hotkeys, $log, $filter, $modal, parent, $state, $stateParams) {

            var vm = this;

            vm.data = {
                search: '',
                header: {},
                items: [],
                filtered: [],
                brands: [],
                groups: [],
                grp: [],
                subgrp: []
            };

            //vm.rest = RestService;
            // vm.rest.set({name: 'UserPrice', url: '/api/userprice'});
            // vm.model = {Markup:0};
            vm.ok = ok;
            vm.cancel = cancel;


            vm.parent = parent;

            function ok(row) {
                $modalInstance.close(row);
            }

            function cancel() {
                $modalInstance.dismiss('cancel');
            }
        }]);