'use strict';

angular.module('app')
    .controller('ArtGroupsItemsController',
        ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", '$state', '$stateParams', "hotkeys", "itemService",
            function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, $state, $stateParams, hotkeys, itemService) {

                var vm = this;
                vm.$storage = $localStorage;
                vm.$stateParams = $stateParams;
                vm.$rootStateParams = $rootScope.$stateParams;

                vm.alerts = {};

                vm.data = {
                    numView: 1,
                    view: 'mainview',
                    items: [],
                    filtered: [],
                    type: [],
                    stock: [],
                    active: -1,
                    search: vm.$stateParams.art
                };
                vm.getData = getData;
                vm.setItems = setItems;
                //vm.artService = artService;
                vm.activeItem = activeItem;
                vm.go = go;

                vm.getData();


                $scope.$watch("vm.$rootStateParams.artId", function (art) {
                    if (vm.data.items.length) {
                        vm.activeItem();
                    }
                });

                $scope.$watch("vm.data.active", function (val) {
                    if (val >= 0) {
                        if (vm.data.filtered.length) $state.go('art.replace', {artId: vm.data.filtered[val].Item});
                    }
                });

                function go(row) {
                    $state.go('art.group', {grpId: row.code});
                }

                function activeItem() {
                    if (vm.$rootStateParams.artId) {
                        vm.data.active = _.findIndex(vm.data.filtered, {Item: vm.$rootStateParams.artId});
                    }
                }

                function setItems(data) {
                    vm.data.items = vm.data.filtered = data;
                }

                function getData() {
                    var item = vm.$stateParams.art.replace(/[^a-z0-9*]/gi, '');
                    $timeout(function () {
                            if (item.length) {
                                if (vm.$storage.vinType && vm.$storage.vinType.nameFull) vm.$storage.vinType.nameFull = '';
                                var params = {item: item};
                                $http.get('/api/catalog/searchgrp', {params: params})
                                    .success(function (data, status) {
                                        if (data.items.length) {
                                            vm.data.items = data.items;
                                            vm.data.filtered = data.items;
                                            //  vm.data.locationList = data.loc;
                                            //itemService.getStock(vm);
                                            //vm.data.active = 0;
                                        } else {
                                            // vm.alerts = {warning:['Товар не найден по запросу: '+item]}
                                        }
                                    });
                            }
                        }, 50
                    );
                    /*else {
                     vm.alerts = {warning: ['Товар не найден по пустому запросу']};
                     }  */
                }


            }]);

