'use strict';


angular.module('app')
    .controller('GoodsController',
        ["$scope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", '$stateParams', "hotkeys", "$rootScope",
            function ($scope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, $stateParams, hotkeys, $rootScope) {

                var vm = this;

                vm.$storage = $localStorage;
                vm.car = {};
                vm.comId = 0
                vm.universalGoods = {
                    15: [
                        {
                            "sref": "Кабели для запуска автомобиля",
                            "href": "/goods/grp/Кабели для запуска автомобиля",
                            "name": "Кабели для запуска автомобиля",
                            "imgUri": "/img/goods/Автомобильные-аксессуары.jpg"
                        },
                        {
                            "sref": "Ремонтные принадлежности",
                            "href": "/goods/grp/Ремонтные принадлежности",
                            "name": "Автосервисные принадлежности",
                            "imgUri": "/img/goods/Автосервисные принадлежности.jpg"
                        },
                        {
                            "sref": "Гофры",
                            "href": "/goods/grp/Гофры",
                            "name": "Выхлопная система",
                            "imgUri": "/img/goods/Выхлопная система.jpg"
                        },
                        {
                            "sref": "Гайковерты пневматические",
                            "href": "/goods/grp/Гайковерты пневматические",
                            "name": "Инструмент пневматический",
                            "imgUri": "/img/goods/Инструмент пневматический.jpg"
                        },
                        {
                            "sref": "Наборы инструмента",
                            "href": "/goods/grp/Наборы инструмента",
                            "name": "Инструмент ручной",
                            "imgUri": "/img/goods/Инструмент ручной.jpg"
                        },
                        {
                            "sref": "Масла моторные",
                            "href": "/goods/grp/Масла моторные",
                            "name": "Масла и автохимия",
                            "imgUri": "/img/goods/Масла и автохимия.jpg"
                        },
                        {
                            "sref": "Диагностическое оборудование",
                            "href": "/goods/grp/Диагностическое оборудование",
                            "name": "Оборудование",
                            "imgUri": "/img/goods/Оборудование.jpg"
                        },
                        {
                            "sref": "Перчатки защитные",
                            "href": "/goods/grp/Перчатки защитные",
                            "name": "Средства индивидуальной защиты",
                            "imgUri": "/img/goods/Средства индивидуальной защиты.jpg"
                        },
                        {
                            "sref": "Аккумуляторы",
                            "href": "/goods/grp/Аккумуляторы",
                            "name": "Электрика",
                            "imgUri": "/img/goods/Аккумуляторы.jpg"
                        }
                    ],
                    19: [
                        {
                            "sref": "Средства индивидуальной защиты",
                            "href": "/goods/grp/Средства индивидуальной защиты",
                            "name": "Средства индивидуальной защиты",
                            "imgUri": "/img/goods/19 - Средства индивидуальной защиты.jpg"
                        },
                        {
                            "sref": "Антифризы",
                            "href": "/goods/grp/Антифризы",
                            "name": "Автохимия и Технические жидкости",
                            "imgUri": "/img/goods/19 - Автохимия и Технические жидкости.jpg"
                        },
                        {
                            "sref": "Автоэмали",
                            "href": "/goods/grp/Автоэмали",
                            "name": "Автоэмали и компоненты",
                            "imgUri": "/img/goods/19 - Автоэмали и компоненты.jpg"
                        },
                        {
                            "sref": "Абразивы",
                            "href": "/goods/grp/Абразивы",
                            "name": "Материалы для кузовного ремонта",
                            "imgUri": "/img/goods/19 - Материалы для кузовного ремонта.jpg"
                        },
                        {
                            "sref": "Ручной инструмент",
                            "href": "/goods/grp/Ручной инструмент",
                            "name": "Инструменты и оборудование",
                            "imgUri": "/img/goods/19 - Инструменты и оборудование.jpg"
                        },
                        {
                            "sref": "Клипсы",
                            "href": "/goods/grp/Клипсы",
                            "name": "Автомобильный крепеж",
                            "imgUri": "/img/goods/19 - Автомобильный крепеж.jpg"
                        },
                        {
                            "sref": "Виброшумоизоляция",
                            "href": "/goods/grp/Виброшумоизоляция",
                            "name": "Виброшумоизоляция",
                            "imgUri": "/img/goods/19 - Виброшумоизоляция.jpg"
                        },
                        {
                            "sref": "Инструмент",
                            "href": "/goods/grp/Инструмент",
                            "name": "Замена и ремонт автостекла",
                            "imgUri": "/img/goods/19 - Замена и ремонт автостекла.jpg"
                        },
                        {
                            "sref": "Щетки Стеклоочистителя",
                            "href": "/goods/grp/Щетки Стеклоочистителя",
                            "name": "Стеклоочистители",
                            "imgUri": "/img/goods/19 - Стеклоочистители.jpg"
                        }
                    ]
            }
                vm.data = {
                    activeView: 1,
                    items: {
                        active: -1,
                        total: 0
                    }
                };
                vm.items = {
                    active: -1,
                    total: 0
                };

                vm.bindAll = bindAll;
                vm.addToCart = addToCart;
                vm.isItemInCart = isItemInCart;
                vm.getCartQnty = getCartQnty;
                vm.openGroupsModal = openGroupsModal;

                // bindAll();

                //**
                $rootScope.hideGoodsGrp = false;
                $rootScope.hideFreedomDay = false;
                $rootScope.ShowFormaPic = false;

                hideGrp();
                setShowBanner();

                function hideGrp() {
                    var lsComId = localStorage.getItem('ComId');
                    var com_id = '';

                    if (lsComId != null && lsComId !== '' && lsComId !== '0') {
                        com_id = lsComId;
                    } else {
                        $http.get('/api/catalog/getcomid').then(function (responce) {
                            com_id = responce.data.replace(new RegExp('"', 'g'), '');
                            localStorage.setItem('ComId', com_id);
                        });
                    }

                    vm.comId = com_id;

                    if (com_id == "19") {
                        $rootScope.hideGoodsGrp = true;
                        $rootScope.hideFreedomDay = true;
                        $rootScope.ShowFormaPic = true;


                    };
                    if (com_id == "17") {
                        $rootScope.hideFreedomDay = true;
                    };
                    return false;
                }

                function setShowBanner() {
                    $rootScope.hideFreedomDay = true;
                    var lsComId = localStorage.getItem('ComId');
                    var today = new Date();
                    //var d = new Date();
                    //d.setFullYear(2018, 10, 23);
                    //d.setHours(0, 1, 0, 0);
                    if ((new Date(2020, 10, 25) < today && today < new Date(2020, 10, 29)) && lsComId == '15') {
                        $rootScope.hideFreedomDay = false;
                    }
                }
                //**

                function openGroupsModal() {
                    var modalInstance = $modal.open({
                        templateUrl: "/app/car/car-groups-modal.html",
                        controller: "CarGroupsModalController",
                        controllerAs: "modal",
                        //size: 'lg',
                        windowClass: "no-animation-modal",
                        resolve: {
                            parent: function () {
                                return '';
                            }
                        }
                    });
                    modalInstance.result.then(
                        function () {
                            //vm.getData();
                        },
                        function () {
                            //vm.getData();
                        }
                    );
                }

                function addToCart(col, index) {
                    if (index == undefined) {
                        index = model.active[col];
                    }

                    var row = angular.copy(model.filtered[col][index]);

                    var qnty;
                    if (row.qnty) {
                        qnty = row.qnty;
                    } else {
                        qnty = 1;
                    }

                    if ($scope.$storage.car.types) {
                        if ($scope.$storage.car.types.name_full != undefined) row.tag = $scope.$storage.car.types.name_full;
                    } else if (model.search.articleOrVin) {
                        row.tag = model.search.articleOrVin;
                    }

                    var indexCart = _.findIndex($scope.$storage.cart.items, { Item: row.Item });
                    if (indexCart >= 0) {
                        $scope.$storage.cart.items.splice(indexCart, 1);
                        //$scope.$storage.cart.items[index].qnty += 1;
                    } else {
                        row.qnty = qnty;
                        $scope.$storage.cart.items.push(angular.copy(row));
                    }
                }

                function isItemInCart(row) {
                    var index = _.findIndex($scope.$storage.cart.items, { Item: row.Item });
                    return index >= 0;
                }

                function getCartQnty(row) {
                    var index = _.findIndex($scope.$storage.cart.items, { Item: row.Item });
                    if (index >= 0) {
                        return $scope.$storage.cart.items[index].qnty;
                    } else {
                        return 0;
                    }

                }

                function bindAll() {
                    hotkeys.bindTo($scope)
                        .add({
                            combo: "up",
                            description: "Движение вверх по списку",
                            allowIn: ["INPUT", "SELECT", "TEXTAREA"],
                            callback: function (event, hotkey) {
                                event.preventDefault();
                                if (vm.items.active >= 0) {
                                    vm.items.active -= 1;
                                }
                                if (vm.items.active < 0) {
                                    vm.items.active = 20 - 1;
                                }
                            }
                        })

                        .add({
                            combo: "down",
                            description: "Движение вниз по списку",
                            allowIn: ["INPUT", "SELECT", "TEXTAREA"],
                            callback: function (event, hotkey) {
                                event.preventDefault();

                                if (vm.items.active <= 20 - 1) {
                                    vm.items.active += 1;
                                }
                                if (vm.items.active > 20 - 1) {
                                    vm.items.active = 0;
                                }
                            }
                        })
                        .add({
                            combo: "right",
                            // allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                            description: 'Переключение в правую колонку',
                            callback: function (event, hotkey) {
                                event.preventDefault();
                                if (vm.data.activeView < 2) {
                                    vm.data.activeView += 1;
                                    vm.items.active = 0;
                                }
                            }
                        })
                        .add({
                            combo: "left",
                            // allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                            description: 'Переключение в левую колонку',
                            callback: function (event, hotkey) {
                                event.preventDefault();
                                if (vm.data.activeView > 0) {
                                    vm.data.activeView -= 1;
                                    vm.items.active = 0;
                                }
                            }
                        })
                        ;


                }
            }]);

