'use strict';


angular.module('app')
    .controller('AdvController',
    ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", "hotkeys", "RestService",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, hotkeys, RestService) {

            var vm = this;
            vm.items = {};
            vm.getAdvItems = getAdvItems;

            vm.getAdvItems();

            function getAdvItems() {

                $http.get('/api/marketing/actual').success(function (data) {
                    if(data.items)
                        vm.items = JSON.parse(data.items);
                });
            }


        }]);

