'use strict';

angular.module('app')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("cart", {
                url: "/cart",
                templateUrl: "/app/cart/cart.html",
                controller: "CartController",
                controllerAs:"vm"
            });
    }]);