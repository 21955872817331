'use strict';

angular.module('settings')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("settings.user-price", {
                url: "/user-price",
                views:{
                    'main@settings': {
                        templateUrl: "/app/settings/user-price/user-price.html",
                        controller: "UserPriceController",
                        controllerAs: "vm"
                    }
                }
            });
    }]);