'use strict';

angular.module('app')
	.controller('VinUnitModalController', ['$scope', '$http', '$timeout','$location', '$modalInstance', '$localStorage', 'hotkeys', '$log', '$filter', '$modal', 'parent','unit','$stateParams',
		function ($scope, $http, $timeout,$location, $modalInstance, $localStorage, hotkeys, $log, $filter, $modal, parent, unit, $stateParams) {

			$scope.$storage = $localStorage;

			var model = $scope.model = {
				// Модели для списков
				col: 0,
				cols: 1,
				data: {},
				//items: [$scope.$storage.cars],
				//filtered: [$scope.$storage.cars],
				//selected: [[]],
				active: false,
				search: [{}],
				total: [0],
				// Модели для контроллера
				// Parent model
				parent: parent,
                hover:false,
                selected:[]
			};

            $scope.active = false;

            /*$scope.getData = function () {

            };*/

            $scope.rescaleImage = function(){
                rescaleImage(-100);
            };

           /* model.getData = function() {

                $http.post("/api/catalogoem/unit?id=" + unit.unitidField+"&vin="+$stateParams.vin+"&ssd="+unit.ssdField, $scope.$storage.vinType,{cache:true}) //"Vin="+model.vin JSON.stringify()"vin="+model.vinJSON.stringify({Vin:model.vin})
                    .success(function(data, status) {
                      model.data = data;
                      //model.catalog = data.catalog.rowField;
                    });
                return true;
            };*/


            model.getData = function() {
                var params = {id:unit.unitidField,vin:$stateParams.vin,ssd:unit.ssdField,vehicleid:$scope.$storage.vinType.vehicleId};

                if($stateParams.catalog){
                    //params.ssd = $stateParams.ssd;
                    params.catalog = $stateParams.catalog;
                }

                $http.get("/api/catalogoem/unit", {params:params, cache:true})
                    .success(function (data) {
                        model.data = data;
                    });
            };


            model.getData();



            $scope.model.getWidth = function(row){
                ///console.log(row);

                return (parseInt(row.x2Field)-parseInt(row.x1Field));
            };

            $scope.model.getHeight = function(row){
                return (parseInt(row.y2Field)-parseInt(row.y1Field));
            };

            $scope.model.setCodeField = function(code){
                //console.log(code);
                var index = _.findIndex($scope.model.selected, {code:code});

                if (index >= 0) {
                    $scope.model.selected.splice(index, 1);
                    return true;
                } else {
                    if (code) $scope.model.selected.push({code:code});
                    return true;
                }
            };

            $scope.model.isSelected = function(code){
                var index = _.findIndex($scope.model.selected, {code:code});

                if(index >= 0) {
                    return true;
                } else {
                    return false;
                }
            };
            $scope.model.isActive = function(code){
                if($scope.model.active == code) {
                    return true;
                } else {
                    return false;
                }
            };



            $scope.model.gotoAnchor = function(x) {
                var newHash = 'a' + x;
                if ($location.hash() !== newHash) {
                    // set the $location.hash to `newHash` and
                    // $anchorScroll will automatically scroll to it
                    $location.hash('a' + x);
                } else {
                    // call $anchorScroll() explicitly,
                    // since $location.hash hasn't changed
                    $anchorScroll();
                }
            };


            $scope.wheel = function(event, delta,deltaX, deltaY) {
                // console.log([delta,deltaX,deltaY]);
                rescaleImage2(delta);
                event.stopPropagation();
                event.preventDefault();
            };

            $timeout(function(){

                $('#viewport').dragscrollable({dragSelector: '.dragger, #viewport', acceptPropagatedEvent: false});
            });

            //console.log($scope.model);



			$scope.ok = function (item) {

				$modalInstance.close(item);
			};

			$scope.cancel = function () {
				$modalInstance.dismiss('cancel');
			};

			model.actionRow = function (index) {
				$scope.ok(index);
			};
		}]);
