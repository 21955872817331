'use strict';


angular.module('reports')
    .controller('ReclamationController',
    ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", "hotkeys",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, hotkeys) {

            $rootScope.title = 'Рекламации';

            var vm = this;

            vm.alerts = {};

            vm.data = {
                items: [],
                filtered: []
            };

            vm.getData = getData;
            vm.getData();

            function getData() {

                $http.get('/api/reports/reclamation')
                    .success(function (data) {
                        vm.data.items = data;
                        vm.data.filtered = data;
                    });

            }
        }]);
