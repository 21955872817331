'use strict';


angular.module('app')
    .controller('CarMainItemsController',
    ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", '$state', '$stateParams', "hotkeys","itemService",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, $state, $stateParams, hotkeys, itemService) {

            var vm = this;
            vm.$storage = $localStorage;
            vm.$stateParams = $stateParams;
            vm.$rootStateParams = $rootScope.$stateParams;
            vm.itemService = itemService;
            vm.comId = localStorage.getItem('ComId');
            $scope.number = 8;
            $scope.getNumber = function (num) {
                return new Array(Math.ceil(num));
            };
            $scope.md = function () {
                return Math.ceil(vm.data.locationList.length / $scope.number);
            };
            vm.alerts = {};

            //vm.car =
            vm.data = {
                numView: 1,
                view: 'mainview',
                items: [],
                filtered: [],
                images: {},
                type: [],
                criteria: {},
                brands: [],
                tags: [],
                stock: [],
                active: -1,
                filters: {brand: '', criteria: '', tag:[],inStock:false},
                selected: []
            };
            vm.getData = getData;
            vm.setInStock = setInStock;
            vm.filterByStock = filterByStock;

            vm.setCriterias = setCriterias;
            vm.setItems = setItems;
            vm.activeItem = activeItem;
            vm.setTagFilter = setTagFilter;
            vm.inTagFilter = inTagFilter;

            vm.getData();

            function getData() {
                var params = {code: vm.$stateParams.typeId, group: vm.$stateParams.grpId};
                return $http.get('/api/catalog/items', {params: params})
                    .then(function (response) {
                        var data = response.data;
                        if (data.items && data.items.length) {
                            vm.data.items = data.items;
                            vm.data.type = data.type.Table[0];
                            vm.data.criteria = data.criteria;
                            vm.data.locationList = data.loc;
                            vm.data.filtered = vm.data.items;

                            if (data.images) {
                                angular.forEach(data.images, function (val, key) {

                                    if (!_.has(vm.data.images, val.Item)) {
                                        vm.data.images['' + val.Item + ''] = []
                                    }

                                    vm.data.images['' + val.Item + ''].push(val);

                                });
                               // console.log(vm.data.images);
                            }

                            vm.setCriterias(vm.data.items);

                            $timeout(function () {
                                vm.activeItem();
                            });

                            $rootScope.title = $filter('capitalize')(vm.$stateParams.grpId) + ' ' + vm.data.type.name_full;
                            $rootScope.tag = vm.data.type.name_full;
                            return true;
                        } else {
                            vm.alerts = {warning: ['Товар не найден в этой группе']}
                            return false;
                        }

                    }).then(function(){
                        return itemService.getStock(vm);
                    });
            }

            function setInStock() {
                vm.data.filters.inStock = !vm.data.filters.inStock;
            }

            function filterByStock(filtered) {
                var result = [];

                angular.forEach(filtered, function (val, key) {
                    if (vm.data.stock[val.Item] != undefined) {
                        var s = 0;
                        angular.forEach(vm.data.stock[val.Item], function (val, key) {
                            if (Number.parseInt(val.replace('>', '')) > 0) s = s + Number.parseInt(val.replace('>', ''));
                        });
                        if (s > 0) {
                            result.push(val);
                        }
                        //  console.log(s);
                    }
                });

                return result;
            }

            $scope.$watch("vm.$rootStateParams.artId", function (art) {
                if (vm.data.items.length) {
                    vm.activeItem();
                }
            });

            $scope.$watch("vm.data.active", function (val) {
                //console.log(val);
                $timeout(function () {
                    if (val >= 0) {
                        if (vm.data.filtered[val]) $state.go('car.items.replace', {artId: vm.data.filtered[val].Item});
                    }
                });
            });

            $scope.$watchCollection("vm.data.filters", function (filters, oldFilters) {
                var filtered = angular.copy(vm.data.items);


                if (filters.tag.length>0) {
                    angular.forEach(filters.tag, function (val) {
                        filtered = $filter("filter")(filtered, val);
                    });
                }

                if (filters.criteria != '') {
                    var query = filters.criteria.split(',');
                    angular.forEach(query, function (val) {
                        filtered = $filter("filter")(filtered, val);
                    });
                }

                if (filters.brand != '') filtered = $filter("filter")(filtered, filters.brand);

                if (filters.inStock) {
                    filtered = vm.filterByStock(filtered);
                }

                vm.data.filtered = filtered;

                $timeout(function () {
                    //vm.activeItem();
                    vm.data.active = 0;
                    if (vm.data.filtered[0]) $state.go('car.items.replace', {artId: vm.data.filtered[0].Item});
                });
                vm.inStock = false;
            });

            $scope.$watchCollection("vm.data.filters.tag", function (tag, oldFilters) {
                var filtered = angular.copy(vm.data.items);
                angular.forEach(tag, function (val) {
                    filtered = $filter("filter")(filtered, val);
                });

                if(vm.data.filters.brand) filtered = $filter("filter")(filtered, vm.data.filters.brand);

                vm.data.filtered = filtered;

            });

            function setTagFilter(tag) {
                var index = _.indexOf(vm.data.filters.tag, tag);
                if(index>=0){
                    vm.data.filters.tag.splice(index, 1);
                } else {
                    vm.data.filters.tag.push(tag);
                }
            }

            function inTagFilter(tag) {
                var index = _.indexOf(vm.data.filters.tag, tag);
                return index >= 0;
            }

            function activeItem() {
                if (vm.$rootStateParams.artId) {
                    vm.data.active = _.findIndex(vm.data.filtered, {Item: vm.$rootStateParams.artId});
                } else {
                    vm.data.active = 0;
                    $state.go('car.items.replace', {artId: vm.data.filtered[0].Item});
                }
            }

            function setItems(data) {
                vm.data.items = vm.data.filtered = data;
            }



            function setCriterias(items) {

                var rows = angular.copy(items);

                angular.forEach(rows, function (row, index) {
                    var type_cri = '';
                    angular.forEach(vm.data.criteria[row.Item], function (val) {
                        type_cri = type_cri + ' ' + val.name + ' ' + val.val + ' ' + val.val_txt;
                    });
                    row.type_cri = type_cri;
                });

                vm.data.items = rows;
            }
        }]);

