'use strict';

angular.module('reports')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("reports.debts", {
                url: "/debts",
                views:{
                    'main@reports': {
                        templateUrl: "/app/reports/debts/debts.html",
                        controller: "DebtsController",
                        controllerAs: "vm"
                    }
                }
            });
    }]);