'use strict';

angular.module('adm')
    .service('RestService', RestService);

function RestService($http, $rootScope) {

    //var baseAddress = '/marketing/advitems';

    var params = {
        name:'',
        url:'',
        ignoreLoadingBar:false
    };

    var service = {
        params:params,
        set: set,
        get: get,
        add: add,
        del: del,
        update: update
    };

    return service;

    function set(newParams) {
        angular.extend(params, newParams);
        $rootScope.$broadcast(params.set+':get', newParams);
    }

    function get(callback, data) {
        if(!data) data = {};

        var url = params.url;

        if(data.id){
            url += '/id/'+data.id;
        }
        //params:data,

        $http.get(url, {ignoreLoadingBar: params.ignoreLoadingBar}).success(callback);
        $rootScope.$broadcast(params.name+':get',data);
    }

    function add(data, callback) {
        if(!callback) callback = function(){};
        $http.post(params.url, data, {ignoreLoadingBar: params.ignoreLoadingBar}).success(callback); //, {ignoreLoadingBar: true}
       $rootScope.$broadcast(params.name+':add',data);
    }

    function update(id, data, callback) {
        if(!callback) callback = function(){};
        $http.put(params.url+'/id/'+id, data, {ignoreLoadingBar: params.ignoreLoadingBar}).success(callback);
        $rootScope.$broadcast(params.name+':update',data);
    }

    function del(id, callback) {
       if(!callback) callback = function(){};
       $http.delete(params.url+'/id/'+id, {}, {ignoreLoadingBar: service.ignoreLoadingBar}).success(callback);
       $rootScope.$broadcast(params.name+':del', id);
    }
}