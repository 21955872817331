'use strict';

angular.module('app')
    .controller('ArtReplaceModalController', ['$scope', '$http', '$timeout', '$modalInstance', '$localStorage', 'hotkeys', '$log', '$filter', '$modal', 'parent',
        function ($scope, $http, $timeout, $modalInstance, $localStorage, hotkeys, $log, $filter, $modal, parent) {
            var vm = this;
            vm.$storage = $localStorage;
            vm.parent = parent;
            vm.comId = localStorage.getItem('ComId');

            //vm.getData(vm.row);


            /*vm.data = {
                container: '.modal'
            };*/

            //vm.list = {};
           // vm.list.actionRow = actionRow;
            vm.ok = ok;
            vm.cancel = cancel;




            function ok(row) {
                $modalInstance.close(row);
            }

            function cancel() {
                $modalInstance.dismiss('cancel');
            }

            /*function actionRow(index) {
                if(!index){
                    ok();
                }
                vm.data.active = index;
            }*/
        }]);
