'use strict';


angular.module('app')
    .controller('VinMainItemsController',
        ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", '$state', '$stateParams', "hotkeys", 'itemService',
            function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, $state, $stateParams, hotkeys, itemService) {

                var vm = this;
                vm.$storage = $localStorage;
                vm.$stateParams = $stateParams;
                vm.$rootStateParams = $rootScope.$stateParams;
                vm.comId = localStorage.getItem('ComId');

                $scope.number = 8;
                $scope.getNumber = function (num) {
                    return new Array(Math.ceil(num));
                };
                $scope.md = function () {
                    return Math.ceil(vm.data.locationList.length / $scope.number);
                };
                vm.alerts = {};

                vm.data = {
                    items: [],
                    filtered: [],
                    images: {},
                    itemsBonusBox: {},
                    actionItems: [],
                    type: [],
                    stock: [],
                    active: -1,
                    searchBy: {},
                    specItems: {},
                    specItemsSalesPrice: {},
                    searchArt: vm.$rootStateParams.art//,
                    //search: vm.$stateParams.art
                };
                vm.getData = getData;
                vm.getItemsBonusBox = getItemsBonusBox;
                vm.activeItem = activeItem;
                vm.openReplacesModal = openReplacesModal;
                vm.openArtActions = openArtActions;

                vm.getData();

                function openArtActions(row) {

                    var modalInstance = $modal.open({
                        templateUrl: "/app/art/art-actions-modal.html",
                        controller: "ArtActionsModalController",
                        controllerAs: "vm",
                        //size: 'lg',
                        windowClass: "no-animation-modal",
                        resolve: {
                            parent: function () {
                                return row;
                            }
                        }
                    });
                    modalInstance.result.then(
                        function () {
                            //vm.getData();
                        },
                        function () {
                            //vm.getData();
                        }
                    );
                }

                function openReplacesModal(row) {

                    var modalInstance = $modal.open({
                        templateUrl: "/app/art/art-replace-items-modal.html",
                        controller: "ArtReplaceItemsModalController",
                        controllerAs: "modal",
                        //size: 'lg',
                        windowClass: "no-animation-modal",
                        resolve: {
                            parent: function () {
                                return row;
                            }
                        }
                    });
                    modalInstance.result.then(
                        function () {
                            //vm.getData();
                        },
                        function () {
                            //vm.getData();
                        }
                    );
                }

                $scope.$watch("vm.$rootStateParams.artId", function (art) {
                    if (vm.data.items.length) {
                        vm.activeItem();
                    }
                });

                $scope.$watch("vm.data.active", function (val) {
                    if (val >= 0) {
                        if (vm.data.filtered.length) $state.go('vin.grp.oem.replace', {artId: vm.data.filtered[val].Item});
                    }
                });

                function activeItem() {
                    if (vm.$rootStateParams.artId) {
                        vm.data.active = _.findIndex(vm.data.filtered, {Item: vm.$rootStateParams.artId});
                    }
                }

                function getItemsBonusBox() {
                    $timeout(function () { itemService.getActionsItems(vm); }, 100);
                }

                function getData() {
                    var item = vm.$stateParams.art.replace(/[^a-z0-9]/gi, '');
                    var params = {item: item};
                    $http.get('/api/catalog/search', {params: params})
                        .success(function (data, status) {
                            if (data.items.length) {
                                vm.data.items = data.items;
                                vm.data.filtered = data.items;
                                vm.data.locationList = data.loc;
                                itemService.getStock(vm);
                                //vm.getItemsBonusBox();
                                vm.data.active = 0;

                                if (data.by) {
                                    angular.forEach(data.by, function (val, key) {

                                        if (!_.has(vm.data.searchBy, val.item)) {
                                            vm.data.searchBy['' + val.item + ''] = ''
                                        }

                                        vm.data.searchBy[val.item] = vm.data.searchBy[val.item] + val.brand + ': ' + val.search + ' ';

                                    });
                                }

                                if (data.images) {
                                    angular.forEach(data.images, function (val, key) {

                                        if (!_.has(vm.data.images, val.Item)) {
                                            vm.data.images['' + val.Item + ''] = []
                                        }

                                        vm.data.images['' + val.Item + ''].push(val);

                                    });
                                    //console.log(vm.data.images);
                                }

                                //$rootScope.title = $filter('capitalize')(rows[0].nameField) + ' ' + $rootScope.tag + ' VIN:' + vm.$rootStateParams.vin;


                            } else {
                                vm.alerts = {warning: ['Товар не найден по ОЕ-артикулу: ' + item]}
                            }
                        });
                }

            }]);

