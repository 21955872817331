'use strict';

angular.module('reports')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("reports.invoices-lines", {
                url: "/invoices-lines",
                views:{
                    'main@reports': {
                        templateUrl: "/app/reports/invoices-lines/invoices-lines.html",
                        controller: "InvoicesLinesController",
                        controllerAs: "vm"
                    }
                }
            });
    }]);