"use strict";

angular.module("app")
    .controller("CartActionsController", ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", "hotkeys", "$upload",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, hotkeys, $upload) {

            var vm = $scope.vm;

            //Методы для фильтрации
            vm.removeFilter = removeFilter; //Убрать один фильтр
            vm.removeFilters = removeFilters; //Убрать все фильтры
            vm.addFilter = addFilter; //Добавить фильтр
            vm.filter = addFilter; //Добавить фильтр
            vm.updateFilters = updateFilters; //Добавить фильтр

            vm.clear = clear; //Очистка Корзины
            vm.deleteRows = deleteRows; //Удаление выделенных строк
            vm.deleteRow = deleteRow; //Удаление строки
            vm.upload = upload; //Загрузка из файла
            vm.minusQty = minusQty;  // Уменьшить количество позиции на 1 шт
            vm.plusQty = plusQty; // Увеличить количество позиции на 1 шт

            vm.exportCartToCsv = exportCartToCsv; // Увеличить количество позиции на 1 шт

            vm.filterByLocation = filterByLocation;

            // Наблюдение за фильтрами
            $scope.$watchCollection("vm.data.filters", function (filters) {
                vm.updateFilters();
            });

            function exportCartToCsv() {
                var mapForm = document.createElement("form");
                var milliseconds = new Date().getTime();
                var windowName = 'exportToExcel' + milliseconds;
                mapForm.target = windowName;
                mapForm.method = "POST";
                mapForm.action = '/api/reports/exportcarttocsv';

                var mapInput = document.createElement("input");
                mapInput.type = "hidden";
                mapInput.name = 'cart';
                mapInput.value = JSON.stringify(vm.data.filtered);
                mapForm.appendChild(mapInput);
                document.body.appendChild(mapForm);

                var map = window.open('', windowName);//, "status=0,title=0,height=600,width=800,scrollbars=1"

                if (map) {
                    mapForm.submit();
                } else {
                    alert('Что не так');
                }
            }


            function updateFilters() {
                vm.resetItems();
                angular.forEach(vm.data.filters, function (row, key) {
                    if (row.type == 'location') {
                        if (row.param != undefined) {
                            var param = row.param;
                        } else {
                            var param = false;
                        }
                        vm.filterByLocation(row.code, param);
                    }
                    if (row.type == 'tag') {
                        vm.data.filtered = $filter("filter")(vm.data.filtered, row.code);
                    }
                });
            }

            function removeFilter(index) {
                vm.data.filters.splice(index, 1);
                var row = vm.data.filters[index];
                if (row.type == 'location') {
                    vm.data.currentLocation = false;
                }
                if (row.type == 'tag') {

                }
            }

            function addFilter(filter) {
                var index = _.findIndex(vm.data.filters, {type: filter.type});
                /*if(filter.type == 'tag'){
                 vm.data.filtered = $filter("filter")(vm.data.items, filter.code);
                 }*/
                if (index >= 0) {
                    vm.data.filters[index] = filter;
                } else {
                    vm.data.filters.push(filter);
                }
            }

            function removeFilters() {
                vm.data.currentLocation = false;
                vm.data.filters = [];
            }

            // Фильтр по складу
            function filterByLocation(location, revers) {

                if (vm.data.items.length > 0) {

                    vm.data.selected = [];
                    vm.data.isAllSelect = false;

                    var data = {items: "''" + _.pluck(vm.data.items, 'Item').join("'',''") + "''"};

                    $http.post('/api/catalog/stockitems', data)
                        .success(function (data, status) {
                            var stock = {};
                            var filtered = [];
                            var filteredRows = [];

                            angular.forEach(data, function (val, key) {
                                if (!stock[val.ItemNo]) {
                                    stock[val.ItemNo] = {};
                                }
                                stock[val.ItemNo][val.LocationCode] = val.Qty;
                            });

                            if (!revers) {
                                angular.forEach(stock, function (val, key) {
                                    if (val[location] != 0 && val[location]) {
                                        //var index = _.findIndex(vm.data.items, {Item: key});
                                        filtered.push(key);
                                    }
                                });
                            } else {
                                angular.forEach(vm.data.items, function (row, key) {
                                    if (stock[row.Item]) {
                                        if (stock[row.Item][location] == undefined || stock[row.Item][location] == 0) {
                                            filtered.push(row.Item);
                                        }
                                    } else {
                                        filtered.push(row.Item);
                                    }


                                });
                            }

                            vm.data.stock = stock;
                            //model.filtered = filtered;


                            filteredRows = _.filter(vm.data.filtered, function (c) {
                                return filtered.indexOf(c.Item) != -1;
                            });


                            if (vm.data.search != '') {
                                filteredRows = $filter("filter")(filteredRows, vm.data.search);
                            }

                            vm.data.filtered = filteredRows;


                            if (revers) {
                                vm.data.currentLocation = 'Нет на ' + location;
                                //vm.addFilter({type:'location', code:'Нет на '+location, name:'Нет на '+location});
                            } else {
                                vm.data.currentLocation = location;
                                //vm.addFilter({type:'location', code:location, name:location});
                            }

                        });
                }
            }

            function clear() {
                vm.removeFilters();
                vm.alerts = {};
                if (confirm("Очистить текущую корзину?")) {
                    vm.$storage.cart = {items: [], name: '', createdAt: ''};
                    vm.getData();
                    return true;
                } else {
                    return false;
                }
            }

            function deleteRows() {
                vm.alerts = {};
                if (vm.data.items.length == 0) {
                    vm.alerts = {warning: ['Корзина пустая!']};
                    return false;
                }
                if (vm.data.selected.length == 0) {
                    vm.alerts = {warning: ['Не выбраны строки!']};
                    return false;
                }
                if (confirm("Удалить выбранные строки?")) {
                    angular.forEach(vm.data.selected, function (row) {
                        var index = _.findIndex(vm.data.items, row);
                        if (index >= 0) {
                            vm.data.items.splice(index, 1);
                        }
                    });
                    vm.getData();
                    vm.updateFilters();
                }
            }

            function deleteRow(row) {
                vm.alerts = {};
                angular.forEach([row], function (row) {
                    var index = _.findIndex(vm.data.items, row);
                    if (index >= 0) {
                        vm.data.items.splice(index, 1);
                    }
                    var index2 = _.findIndex(vm.data.filtered, row);
                    if (index2 >= 0) {
                        vm.data.filtered.splice(index2, 1);
                    }

                    if (vm.data.filtered.length == 0) {
                        vm.resetItems();
                        vm.data.filters = [];
                    }

                });
                //vm.getData();
            }

            function upload(files) {

                if (vm.$storage.cart.items.length > 0) {
                    if (!vm.clear()) {
                        return false;
                    }
                }

                if (files && files.length) {
                    for (var i = 0; i < files.length; i++) {
                        var file = files[i];
                        $upload.upload({
                            url: '/api/cart/upload',
                            /*fields: {
                             'username': $scope.username
                             },*/
                            file: file
                        }).progress(function (evt) {
                            //var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                            //console.log('progress: ' + progressPercentage + '% ' + evt.config.file.name);
                        }).success(function (data, status, headers, config) {

                            if (data.alerts) vm.data.alerts = data.alerts;

                            vm.$storage.cart.items = [];

                            angular.forEach(data.items, function (val, key) {
                                vm.$storage.cart.items.push({Item: val.Item, qnty: data.qnty[val.Item]});
                            });

                            vm.getData();
                            vm.alerts = data.alerts;
                            //$scope.addAlerts(data.alerts);
                        }).error(function () {

                            vm.alerts = {danger: ['Не верный формат файла']};

                            //$scope.setAlerts('danger', ['Не верный формат файла']);

                        });
                    }
                }
            }

            function plusQty(row) {
                row.qnty = Number(row.qnty) + 1;
                vm.calcAll();
            }

            function minusQty(row) {
                row.qnty = Number(row.qnty) - 1;
                if (row.qnty < 1) {
                    row.qnty = 1;
                }
                vm.calcAll();
            }
        }]);

