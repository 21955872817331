"use strict";

angular.module("app")
    .controller("OrderModalController", ["$scope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modalInstance", "$localStorage", "$modal", "hotkeys", "row", "parent",
        function ($scope, $http, $location, $timeout, $filter, $document, $log, $modalInstance, $localStorage, $modal, hotkeys, row, parent) {

            $scope.$storage = $localStorage;

            var today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth() + 1; //January is 0!
            var yyyy = today.getFullYear();

            moment.locale('ru');

            var model = $scope.model = {
                container: '.modal',
                row: {},
                items: [],
                filtered: [],
                selected: [],
                active: -1,
                search: {},
                searchBy: {},
                total: 0,
                parent: parent,
                deliveryTypes: ['Не выбран...', 'Самовывоз', 'Доставка'],
                //deliveryTypes: ['Не выбран...', 'Доставка'],
                deliveryType: 0,
                deliveryDate: moment().format('DD.MM.YYYY'), //,//'16.04.2015',.add(1,'days').
                deliveryAddress: [],
                deliveryList: [],
                selectedDeliveryAddress: {},
                selectedDeliveryTime: {},
                searchitems: [],
                searchreplace: [],
                replaceitem: '',
                replaceitems: [],
                stock: {},
                isAllSelect: false //,
                //calendarDate: moment(moment().format('DD.MM.YYYY')).calendar()//
                ,
                comId: '',
                webSelfPickupBlocked: true,
                webDeliveryBlocked: true,
                alerts: []
            };

            if (row) {
                model.row = angular.copy(row);
            }

            $scope.resetFilters = function () {
                $scope.getData();
            };

            $timeout(function () {
                $('#order-date').datepicker({
                    format: "dd.mm.yyyy",
                    language: "ru",
                    autoclose: true
                });
            });

            $scope.exportOrderToExcel = function (orderNo) {
                window.open('/api/orders/ordertocsv?id=' + orderNo, '_blank');
            };


            $scope.openReplace = function (row) {

                row.Item = row.No;

                var modalInstance = $modal.open({
                    templateUrl: "/app/art/art-replace-modal.html",
                    controller: "ArtReplaceModalController",
                    controllerAs: "ctrl",
                    //size: 'lg',
                    windowClass: "no-animation-modal",
                    resolve: {
                        parent: function () {
                            var vm = {};
                            vm.row = row;
                            return vm;
                        }
                    }
                });
                modalInstance.result.then(
                    function (newRow) {
                        $scope.deleteRow(row);
                        $scope.addItem(newRow);
                        $scope.bindTableItem();
                    },
                    function () {
                        $scope.getData();
                        $scope.bindTableItem();
                    }
                );
            };

            $scope.getData = function () {
                model.selected = [];
                model.isAllSelect = false;
                $http.post("/api/orders/order", model.row)
                    .success(function (data, status) {
                        model.row = data.row[0];
                        model.attr = data.attr;
                        model.orderParams = data.params;
                        model.items = data.rows;
                        model.comId = data.comId;
                        model.webSelfPickupBlocked = data.row[0].WebSelfPickupBlocked == 1;
                        model.webDeliveryBlocked = data.row[0].WebDeliveryBlocked == 1;

                        model.alerts = []
                        if (model.webSelfPickupBlocked) {
                            $scope.addAlerts({ danger: ["Самовывоз временно недоступен"] });
                        }
                        if (model.webDeliveryBlocked) {
                            $scope.addAlerts({ danger: ["Доставка временно недоступна со склада " + data.row[0].LocationCode] });
                        }

                        //model.items[0].Reserve = "";
                        //console.log(model.items);

                        model.filtered = data.rows;
                        $timeout(function () {

                            if (model.attr.length == 1 && !model.row.Letter_of_Attorney_No_) {
                                $scope.setAttorney(model.attr[0]['Но']);
                            }

                            if (model.orderParams.DeliveryAddress.length) {

                                $scope.getDeliveryList(model.orderParams.DeliveryAddress[0]);
                                //$scope.setAttorney(model.attr[0]['Но']);
                            }

                            // $('#srch_art').focus();
                        });
                    });
            };

            $scope.resetSearchItems = function () {
                model.searchitems = [];
                model.searchreplace = [];
                model.replaceitem = '';
                model.replaceitems = [];
            };

            $scope.getItems = function () {
                $scope.resetSearchItems();
                var data = {
                    "ItemNo": model.search.art.replace(/[^a-z0-9]/gi, ''),
                    "Item": model.search.art,
                    "locFilter": model.row.LocFilter
                };

                $http.post("/api/orders/searchitem", data)
                    .success(function (data, status) {
                        model.searchitems = data.items;
                        model.searchreplace = data.repl;
                        model.searchBy = {};
                        if (data.by) {
                            angular.forEach(data.by, function (val, key) {

                                if (!_.has(model.searchBy, val.item)) {
                                    model.searchBy['' + val.item + ''] = ''
                                }

                                model.searchBy[val.item] = (model.searchBy[val.item] == undefined ? ' ' : model.searchBy[val.item]) + ' ' + val.brand + ': ' + val.search + ' ';

                            });
                        }


                        $scope.getStock();
                    });
                return true;
            };

            $scope.getReplace = function (item) {
                model.replaceitem = item;
                model.replaceitems = [];
                var data = {
                    "Item": item
                };
                $http.post("/api/orders/searchreplace", data)
                    .success(function (data, status) {
                        model.replaceitems = data.items;
                        $scope.getStock();
                    });
                return true;
            };
            $scope.isReplaceActiveRow = function (item) {
                return model.replaceitem == item;
            };

            $scope.getStock = function () {

                var searchitems = _.pluck(model.searchitems, 'Item');
                var searchreplace = _.pluck(model.searchreplace, 'Item');
                var replaceitems = _.pluck(model.replaceitems, 'Item');

                var data = { items: "''" + _.union(searchitems, searchreplace, replaceitems).join("'',''") + "''" };

                $http.post('/api/catalog/stockitems', data)
                    .success(function (data, status) {
                        var stock = {};
                        angular.forEach(data, function (val, key) {
                            if (!stock[val.ItemNo]) {
                                stock[val.ItemNo] = {};
                            }
                            stock[val.ItemNo][val.LocationCode] = val.Qty;
                        });
                        model.stock = stock;
                    });
            };


            $scope.addItem = function (row) {
                var data = { "OrderNo": model.row.OrderNo, "Item": row['Item'] };
                model.search.art = '';
                model.searchitems = [];
                $http.post("/api/orders/lineadd", data)
                    .success(function (data, status) {
                        $scope.getData();
                    });
                return true;
            };

            $scope.isInOrder = function (row) {
                var index = _.findIndex(model.items, { 'No': row['Item'] });
                if (index >= 0) {
                    return true;
                } else {
                    return false;
                }
            };

            $scope.newOrder = function () {
                $http.post("/api/orders/add")
                    .success(function (data, status) {
                        model.row = data.row[0];
                        $scope.$storage.activeOrder = model.row.OrderNo;
                        $scope.getData();
                    });
                return true;
            };

            if (model.row.OrderNo) {
                $scope.getData();
            } else {
                $scope.newOrder();
            }

            $scope.setLocation = function (code) {
                var data = { "OrderNo": model.row.OrderNo, "code": code };
                $http.post("/api/orders/setlocation", data)
                    .success(function (data, status) {
                        //model.row = data.row[0];
                        $scope.getData();
                        $scope.getDeliveryAddress(code);
                    });
                return true;
            };

            $scope.setAttorney = function (code) {
                var data = { "OrderNo": model.row.OrderNo, "code": code };
                $http.post("/api/orders/setattorney", data)
                    .success(function (data, status) {
                        //model.row = data.row[0];
                        $scope.getData();
                    });
            };


            $scope.lineUpdate = function (line, qty) {
                var data = { "OrderNo": model.row.OrderNo, "Qty": qty, "LineNo": line };

                if (qty != '' && qty >= 0) {
                    $http.post("/api/orders/lineupdate", data)
                        .success(function (data, status) {
                            if (data.alerts) model.alerts = data.alerts;
                            $scope.getData();
                        });
                }
                return true;
            };


            $scope.getDeliveryAddress = function (code) {
                if (!code) {
                    code = model.row.LocationCode;
                }

                var data = { "LocationCode": code };
                $http.post("/api/orders/deliveryaddress", data)
                    .success(function (data, status) {
                        model.orderParams.DeliveryAddress = data.rows;
                    });
                return true;
            };

            $scope.getDeliveryList = function (row) {
                //model.alerts = {}
                if (!row && model.selectedDeliveryAddress) {
                    row = model.selectedDeliveryAddress;
                }
                model.selectedDeliveryAddress = row;
                var data = { "OrderNo": model.row.OrderNo, "zone": row["##delZone"], "deliveryDate": model.deliveryDate };
                $http.post("/api/orders/deliverylist", data)
                    .success(function (data, status) {

                        /*                SD-44283                */
                        function filt(x) {
                            return new Date(new Date(2019, 11, 31).toLocaleDateString().split('.').reverse().join('.') + ' ' + x['Выезд'].replace(/ \( [\d]{2}.[\d]{2}.[\d]{4} \)/g, '')) <=
                                new Date(new Date(2019, 11, 31).toLocaleDateString().split('.').reverse().join('.') + ' 11:00:00');
                        }

                        if (model.deliveryDate == '31.12.2019') {

                            if (model.row.LocationCode == 'КИЕВ1' || model.row.LocationCode == 'КИЕВ2') {
                                $scope.addAlerts({ danger: ['Нет доступных отгрузок'] });
                                model.deliveryList = [];
                                model.selectedDeliveryTime = {};
                                return;
                            }
                            else {
                                data.rows = data.rows.filter(filt);
                                if(!data.rows.length) 
                                $scope.addAlerts({ danger: ['Нет доступных отгрузок'] });
                            }
                        }

                        /*                SD-44283                */
                        
                        model.deliveryList = data.rows;

                        model.selectedDeliveryTime = {};
                    });
                return true;
            };

            $scope.updateDeliveryDate = function () {

                //model.calendarDate = moment(model.deliveryDate, 'DD.MM.YYYY').add(1,'days').calendar();//

                //var today = moment(new Date()).format('YYYY-MM-DD');
                var today = moment().add(0, 'days');///.format('DD.MM.YYYY');

                // console.log(model.deliveryDate);

                // if(!moment(moment(model.deliveryDate, 'DD.MM.YYYY')).isBetween(moment(new Date()).format('YYYY-MM-DD').toString(), moment(new Date()).add(5, 'days').format('YYYY-MM-DD').toString()  )){

                if (!moment(model.deliveryDate, 'DD.MM.YYYY').isBetween(moment().add(-1, 'days'), moment().add(5, 'days'))) {
                    $scope.addAlerts({ danger: ['Неправильная дата отгрузки! Максимум +5 дней от сегодняшней даты.'] });

                    model.deliveryDate = moment().add(0, 'days').format('DD.MM.YYYY');
                    /*$timeout(function () {
                        $('#order-date').datepicker().val(model.deliveryDate);
                    });*/
                } else {
                    model.alerts = {};
                }

                $scope.getDeliveryList();
                model.selectedDeliveryTime = {};
            };

            $scope.isReserve = function () {
                var result = true;
                angular.forEach(model.items, function (row) {
                    if (Number(row.Quantity) != Number(row.Reserve)) {
                        result = false;
                        //console.log(Number(row.Reserve));
                    }
                });
                if (!result) {
                    $scope.addAlerts({ danger: ['Товар не зарезервирован'] });
                }
                return result;
            };

            $scope.delivery = function () {

                if (!$scope.isReserve()) {
                    return false;
                }

                var data = {
                    "OrderNo": model.row.OrderNo,
                    "code": model.selectedDeliveryTime["##RouteCode"],
                    "sa": model.selectedDeliveryTime["##SACode"],
                    "sas": model.selectedDeliveryTime["##SASCode"],
                    "DeliveryDate": model.deliveryDate,
                    "DeliveryCode": model.selectedDeliveryAddress["##Код"]
                };

                $http.post("/api/orders/deliveryadd", data)
                    .success(function (data, status) {
                        if (!data.alerts) {
                            $scope.ok();
                        } else {
                            $scope.addAlerts(data.alerts);
                        }
                    });
                return true;
            };

            $scope.shipment = function () {

                if (!$scope.isReserve()) {
                    return false;
                }

                var data = {
                    "OrderNo": model.row.OrderNo
                };


                // console.log(model.row);
                // return false;

                $http.post("/api/orders/shipmentadd", data)
                    .success(function (data, status) {
                        if (!data.alerts) {
                            $scope.ok();
                        } else {
                            $scope.addAlerts(data.alerts);
                        }
                    });
                return true;
            };

            $scope.filterByZero = function () {
                model.filtered = $filter("filter")(model.items, { 'Quantity': 0 });
                return true;
            };

            $scope.closeAlert = function (index) {
                delete model.alerts[index];
            };

            $scope.addAlerts = function (alerts) {
                model.alerts = alerts;
                if (model.alerts.success && model.alerts.success.length > 0) {
                    $timeout(function () {
                        delete model.alerts.success;
                    }, 4000);
                }
            };


            // Удалене строк из таблицы
            $scope.deleteRows = function () {
                if (confirm("Удалить выбранные строки?")) {
                    model.alerts = [];
                    var rows = _.map(
                        model.selected,
                        function (row) {
                            return { LineNo: row.LineNo };
                        }
                    );

                    var data = { 'OrderNo': model.row.OrderNo, rows: rows };

                    $http.post("/api/orders/linedelete", data)
                        .success(function (data) {
                            $scope.addAlerts(data.alerts);
                            $scope.getData();
                            model.isAllSelect = false;
                        });
                    return true;
                } else {
                }

            };

            $scope.deleteRow = function (row) {
                //if (confirm("Удалить строку?")) {
                model.alerts = [];
                var rows = _.map(
                    [row],
                    function (row) {
                        return { LineNo: row.LineNo };
                    }
                );

                var data = { 'OrderNo': model.row.OrderNo, rows: rows };

                $http.post("/api/orders/linedelete", data)
                    .success(function (data) {
                        if (data.alerts.danger != undefined) $scope.addAlerts(data.alerts);
                        $scope.getData();
                        model.isAllSelect = false;
                    });
                return true;
                //} else {}

            };

            $scope.deleteOrder = function () {
                if (confirm("Удалить заказ?")) {
                    //$scope.parent.model.alerts = [];
                    $http.post("/api/orders/delete", [{ OrderNo: model.row.OrderNo }])
                        .success(function (data) {
                            //$scope.parent.addAlerts(data.alerts);
                            $scope.cancel(data.alerts);
                        });
                    return true;
                } else {
                }
            };

            $scope.actionRow = function (row) {
                //console.log(model.search.items);
                if (model.search.art) {
                    $scope.getItems();
                }
            };

            $scope.ok = function () {
                $modalInstance.close(model.row);
            };

            $scope.cancel = function (alerts) {
                if (!alerts) {
                    alerts = 'cancel';
                }

                $modalInstance.dismiss(alerts);
            };

            $scope.bonusAdd = function () {
                var data = { "OrderNo": model.row.OrderNo };
                $http.post("/api/orders/BonusAdd", data)
                    .success(function (data, alerts) {
                        $scope.addAlerts(data.alerts);
                        $scope.getData();
                    });
            };

            $scope.bonusRemove = function () {
                var data = { "OrderNo": model.row.OrderNo };
                $http.post("/api/orders/BonusRemove", data)
                    .success(function (data, status) {
                        $scope.getData();
                    });
            };

        }]);
