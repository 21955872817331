'use strict';


angular.module('app')
    .controller('CarItemToCartController',
    ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", '$state', '$stateParams', "hotkeys",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, $state, $stateParams, hotkeys) {

            var vm = $scope.vm;

            vm.addToCart = addToCart;
            vm.isItemInCart = isItemInCart;
            vm.getCartQnty = getCartQnty;

            function addToCart(index) {
                if (vm.$storage.cart) {

                    if (index == undefined) {
                        index = vm.data.active;
                    }

                    var row = {};
                    row.Item = vm.data.filtered[index].Item;

                    var qnty;
                    if (vm.data.filtered[index].qnty) {
                        qnty = vm.data.filtered[index].qnty;
                    } else {
                        qnty = 1;
                    }


                    if (vm.$stateParams.art) {
                        if (vm.$stateParams.art != '') row.tag = vm.$stateParams.art;
                    }


                    row.tag = $rootScope.tag;

                    /* if (vm.data.type) {
                         if (vm.data.type.name_full != undefined) row.tag = vm.data.type.name_full;
                     }

                     if (vm.data.search) {
                         if (vm.data.search.art != undefined) row.tag = vm.data.search.art;
                     }

                     if (vm.$storage.vinType) {
                         if (vm.$storage.vinType.nameFull != undefined) row.tag = vm.$storage.vinType.nameFull;
                     }*/


                    var indexCart = _.findIndex(vm.$storage.cart.items, {Item: vm.data.filtered[index].Item});

                    if (indexCart >= 0) {
                        vm.$storage.cart.items.splice(indexCart, 1);
                    } else {
                        row.qnty = qnty;
                        vm.$storage.cart.items.push(row);
                    }
                }
            }

            function isItemInCart(row) {
                var index = _.findIndex(vm.$storage.cart.items, {Item: row.Item});
                return index >= 0;
            }

            function getCartQnty(row) {
                var index = _.findIndex(vm.$storage.cart.items, {Item: row.Item});
                if (index >= 0) {
                    return vm.$storage.cart.items[index].qnty;
                } else {
                    return 0;
                }
            }

        }]);

