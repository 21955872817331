'use strict';


angular.module('app')
    .controller('AlertsController',
    ["$scope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", '$stateParams', "hotkeys",
        function ($scope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, $stateParams, hotkeys) {
            $scope.close = function (key) {
               if($scope.vm.alerts[key]) delete $scope.vm.alerts[key];
            };

            $scope.$watchCollection("vm.alerts", function () {
                $scope.autoCloseSuccess();
            });

            $scope.autoCloseSuccess = function () {
                if($scope.vm.alerts != undefined && $scope.vm.alerts.success != undefined){
                    $timeout(function () {
                        $scope.close('success');
                    },4000);
                }
            };
            $scope.autoCloseSuccess();
        }]);