'use strict';

angular.module('app')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("ugoods", {
                url: "/ugoods",
                views:{
                    "@" : {
                        templateUrl: "/app/goods/ugoods.html",
                        controller: "GoodsController",
                        controllerAs:"car"
                    },
                    'groups@ugoods':{
                        templateUrl: "/app/goods/goods-groups-items.html",
                        controller: "GoodsGroupsItemsController",
                        controllerAs:"vm"
                    },
                    'items@ugoods': {
                        templateUrl: "/app/goods/goods-main.html"
                    }
                }
            })

            .state("goods", {
                url: "/goods",
                views:{
                    "@" : {
                        templateUrl: "/app/goods/goods.html",
                        controller: "GoodsController",
                        controllerAs:"car"
                    },
                    'groups@goods':{
                        templateUrl: "/app/goods/goods-groups-items.html",
                        controller: "GoodsGroupsItemsController",
                        controllerAs:"vm"
                    }
                }
            })
            .state("goods.items", {
                url: "/grp/:grpId",               
                views:{
                    'items@goods': {
                        templateUrl: "/app/goods/goods-main-items.html",
                        controller: "GoodsMainItemsController",
                        controllerAs: "vm"
                    }
                }
            })

            


            .state("goods.items.replace", {
                params: {
                    artId:''
                },
                views:{
                    'replace@goods': {
                        templateUrl: "/app/art/art-replace-items.html",
                        controller: "ArtReplaceItemsController",
                        controllerAs: "vm"
                    }
                }
            });
            /*

            .state("goods", {
                url: "/goods",
                views:{
                    "@" : {
                        templateUrl: "/app/art/art.html",
                        controller: "ArtController",
                        controllerAs:"art"
                    },
                    'items@goods':{
                        templateUrl: "/app/art/art-main-items.html",
                        controller: "GoodsMainItemsController",
                        controllerAs:"vm"
                    },
                    'groups@goods':{
                        templateUrl: "/app/goods/goods-groups-items.html",
                        controller: "GoodsGroupsItemsController",
                        controllerAs:"vm"
                    }

                    /!*,
                    'toolbar@art':{
                        templateUrl: "/app/art/art-toolbar.html"//,
                        //controller: "ArtToolbarController",
                        //controllerAs:"vm"
                    }*!/
                }
            })
            .state("goods.group", {
                url: "/grp/:grpId",
                views:{
                    'items@goods':{
                        templateUrl: "/app/art/art-main-items.html",
                        controller: "GoodsMainItemsController",
                        controllerAs:"vm"
                    }
                }
            })
            .state("goods.replace", {
                //url: "/item/:artId",
                params: {
                    artId:''
                },
                views:{
                    'replace@goods': {
                        templateUrl: "/app/art/art-replace-items.html",
                        controller: "ArtReplaceItemsController",
                        controllerAs: "vm"
                    }
                }
            })
            .state("goods.group.replace", {
                //url: "/item/:artId",
                params: {
                    artId:''
                },
                views:{
                    'replace@goods': {
                        templateUrl: "/app/art/art-replace-items.html",
                        controller: "ArtReplaceItemsController",
                        controllerAs: "vm"
                    }
                }
            });*/
    }]);