

angular.module('app')
    .controller('ReminderModalController', ['$scope', '$http', '$location', '$timeout', '$modalInstance', '$localStorage', 'hotkeys', '$filter', '$modal', 'RestService', 'reminder',
        function ($scope, $http, $location, $timeout, $modalInstance, $localStorage, hotkeys, $filter, $modal, RestService, reminder) {

            var vm = this;
            vm.reminder = reminder.Message;
            vm.delayCount = 5 - reminder.DelayCount; 
            vm.RCPhone = reminder.RCPhone;
            vm.SPName = reminder.SPName;
            vm.SPMobilePhone = reminder.SPMobilePhone;
            vm.SPEMail = reminder.SPEMail;
            vm.bankTransactionAmount = reminder.bankTransactionAmount;
            vm.balanceDocList = reminder.balanceDocList;
            vm.from = reminder.balanceDocList[0].From;
            vm.to = reminder.balanceDocList[0].To;



            vm.agree = agree;
            vm.notAgree = notAgree;
            vm.delay = delay;
            vm.comment = '';

            function agree() {
                changeReminderStatus(1);
                window.location = window.location;
            }
            function notAgree() {
                changeReminderStatus(2);
                window.location = window.location;
            }
            function delay() {
                if (vm.delayCount < 1) return;
                changeReminderStatus(3);
                $location.path('/reports/balance');
            }
            function changeReminderStatus(status) {
                $http.post("/home/changeReminderStatus?message=" + reminder.Message+"&status=" + status + "&reminderId=" + reminder.reminderId + "&comment=" + vm.comment + "&balance=" + reminder.balance + "&balanceDate=" + reminder.balanceDate).success(function (data) {
                    $modalInstance.dismiss('cancel');
                });
            }
        }]);
