'use strict';


angular.module('reports')
    .controller('ReturnDetailsController',
        ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", "hotkeys", "$stateParams", "cfpLoadingBar",
            function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, hotkeys, $stateParams, cfpLoadingBar) {

                $rootScope.title = 'Возврат';
                $rootScope.docNo = $stateParams.docNo;

                var vm = this;
                vm.$stateParams = $stateParams;

                vm.alerts = {};
                var model = $scope.model = { alerts: [] };
                vm.data = {
                    items: [],
                    lines: [],
                    header: {}
                };

                vm.getData = getData;
                vm.getData();

                function getData() {
                    cfpLoadingBar.start();
                    $http.post('/api/returns/detail', { docNo: $rootScope.docNo })
                        .success(function (data) {
                            vm.data.header = data.header[0];
                            vm.data.lines = data.lines;
                            cfpLoadingBar.complete();
                        }).error(function (error) {
                            $scope.addAlerts({ danger: [error] });
                        });

                }

                // Добавить товар в список
                $scope.open = function () {
                    if ($scope.disabledFor('lineAdd')) return;

                    var modalInstance = $modal.open({
                        templateUrl: "/app/reports/returns/return-modal.html",
                        controller: "ReturnModalController",
                        size: 'lg',
                        windowClass: "no-animation-modal",
                        resolve: {
                            header: function () {
                                return vm.data.header;
                            }
                        }
                    });

                    modalInstance.result.then(function (returnRow) {

                        cfpLoadingBar.start();
                        var item = {
                            docNo: vm.data.header.OrderNo,
                            itemNo: returnRow.ItemNo,
                            qty: returnRow.NotReturned,
                            entryNo: returnRow.EntryNo
                        };
                        $http.post("/api/Returns/LineAdd", item).success(function (response) {
                            if (response.replace('"', '').toLowerCase().startsWith('ok')) {
                                getData();
                            } else {
                                $scope.addAlerts({ danger: [response] });
                            }
                            cfpLoadingBar.complete();
                        });

                    });
                };

                //удалить строку из документа
                $scope.lineDelete = function (lineNo) {
                    if ($scope.disabledFor('lineDelete')) return;
                    cfpLoadingBar.start();
                    $http.post("/api/Returns/LineDelete", { docNo: vm.data.header.OrderNo, lineNo: lineNo })
                        .success(function (response) {
                            if (response.replace('"', '').toLowerCase().startsWith('ok')) {
                                getData();
                            } else {
                                $scope.addAlerts({ danger: [response] });
                            }
                            cfpLoadingBar.complete();
                        });
                };

                //скачать документ
                $scope.downloadSR = function (docNo) {
                    window.open("/api/reports/downloadFile?docNo=" + encodeURI(docNo) + "&type=3", "_blank");
                };

                //удалить возврат
                $scope.returnDelete = function () {
                    if ($scope.disabledFor('returnDelete')) return;
                    cfpLoadingBar.start();
                    $http.post("/api/Returns/ReturnDelete", { docNo: vm.data.header.OrderNo })
                        .success(function (response) {
                            if (response.replace('"', '').toLowerCase().startsWith('ok')) {
                                window.location.assign('/reports/returns');
                            } else {
                                $scope.addAlerts({ danger: [response] });
                            }
                            cfpLoadingBar.complete();
                        });
                };

                //выпустить возврат + выбор адреса
                $scope.returnRelease = function () {
                    if ($scope.disabledFor('release')) return;

                    var modalInstance = $modal.open({
                        templateUrl: "/app/reports/returns/return-release-modal.html",
                        controller: "ReturnReleaseModalController",
                        size: 'md',
                        windowClass: "no-animation-modal",
                        resolve: {
                            header: function () {
                                return vm.data.header;
                            }
                        }
                    });


                    modalInstance.result.then(function (returnRow) {

                        var data = {
                            docNo: vm.data.header.OrderNo,
                            code: returnRow.Code
                        };

                        cfpLoadingBar.start();
                        $http.post("/api/Returns/ReturnRelease", data)
                            .success(function (response) {
                                if (response.replace('"', '').toLowerCase().startsWith('ok')) {
                                    getData();
                                } else {
                                    $scope.addAlerts({ danger: [response] });
                                }
                                cfpLoadingBar.complete();
                            });
                    });
                };

                $scope.lineUpdate = function (lineNo, qty, notReturned) {
                    if ($scope.disabledFor('lineUpdate')) return;

                    if (qty < 1) qty = 1;
                    //if (qty > notReturned && qty != 1) qty = notReturned;

                    cfpLoadingBar.start();
                    $http.post("/api/Returns/LineUpdate", { docNo: vm.data.header.OrderNo, lineNo: lineNo, qty: qty })
                        .success(function (response) {
                            if (response.replace('"', '').toLowerCase().startsWith('ok')) {
                                getData();
                            } else {
                                $scope.addAlerts({ danger: [response] });
                            }
                            cfpLoadingBar.complete();
                        });
                };

                $scope.disabledFor = function (type) {
                    type = type.toLowerCase();
                    if (vm.data.header.Status == 0 && type == 'print' ||
                        vm.data.header.Status == 1 && type != 'print') {
                        return true;
                    } else return false;
                };

                $scope.closeAlert = function (index) {
                    delete model.alerts[index];
                };

                $scope.addAlerts = function (alerts) {
                    model.alerts = alerts;
                    if (model.alerts.success && model.alerts.success.length > 0) {
                        $timeout(function () {
                            delete model.alerts.success;
                        }, 4000);
                    }
                };



            }
        ]);
