'use strict';

angular.module('reports')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("reports.reclamation", {
                url: "/reclamation",
                views:{
                    'main@reports': {
                        templateUrl: "/app/reports/reclamation/reclamation.html",
                        controller: "ReclamationController",
                        controllerAs: "vm"
                    }
                }
            });
    }]);