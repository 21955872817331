'use strict';

angular.module('app')
    .controller('ArtMainItemsController',
    ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", '$state', '$stateParams', "hotkeys", "itemService",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, $state, $stateParams, hotkeys, itemService) {

            var vm = this;
            vm.$storage = $localStorage;
            vm.$stateParams = $stateParams;
            vm.$rootStateParams = $rootScope.$stateParams;
            vm.comId = localStorage.getItem('ComId');
            vm.alerts = {};
            vm.typeSearch = 1;
            $scope.number = 8;
            $scope.getNumber = function(num) {
                return new Array(Math.ceil(num));
            };
            $scope.md = function() {
                return  Math.ceil(vm.data.locationList.length / $scope.number);
            };

            vm.data = {
                numView: 1,
                view: 'mainview',
                items1: [],
                filtered1: [],
                items: [],
                filtered: [],
                images: {},
                itemsBonusBox:{},
                type: [],
                stock: [],
                active: -1,
                searchBy: {},
                searchArt: vm.$stateParams.art,
                specItems: {},
                specItemsSalesPrice: {},
                actionItems: [],
                adClubLink: ""
                //,
                //search: vm.$stateParams.art
            };
            vm.getData = getData; //Поиск по точному совпадению или с использованием *
            vm.getItemsBonusBox = getItemsBonusBox;
            // vm.setItems = setItems;
            //vm.artService = artService;
            vm.activeItem = activeItem;
            //vm.activeRowExactly = activeRowExactly;
            vm.openReplacesModal = openReplacesModal;
            vm.openArtActions = openArtActions;

            vm.getData();
            //console.log($scope);


            function openArtActions(row) {

                var modalInstance = $modal.open({
                    templateUrl: "/app/art/art-actions-modal.html",
                    controller: "ArtActionsModalController",
                    controllerAs: "vm",
                    //size: 'lg',
                    windowClass: "no-animation-modal",
                    resolve: {
                        parent: function () {
                            return row;
                        }
                    }
                });
                modalInstance.result.then(
                    function () {
                        //vm.getData();
                    },
                    function () {
                        //vm.getData();
                    }
                );
            }

            function openReplacesModal(row) {

                var modalInstance = $modal.open({
                    templateUrl: "/app/art/art-replace-items-modal.html",
                    controller: "ArtReplaceItemsModalController",
                    controllerAs: "modal",
                    //size: 'lg',
                    windowClass: "no-animation-modal",
                    resolve: {
                        parent: function () {
                            return row;
                        }
                    }
                });
                modalInstance.result.then(
                    function () {
                        //vm.getData();
                    },
                    function () {
                        //vm.getData();
                    }
                );
            }


            $scope.$watch("vm.$rootStateParams.artId", function (art) {
                if (vm.data.items.length) {
                    //  vm.activeItem();
                }
            });

            $scope.$watch("vm.data.active", function (val) {
                if (val >= 0) {
                    if (vm.data.filtered.length) {
                        if (vm.$stateParams.grpId) {
                            $state.go('art.group.replace', {artId: vm.data.filtered[val].Item});
                        } else {
                            $state.go('art.replace', {artId: vm.data.filtered[val].Item});
                        }


                    }
                }
            });

            /*function activeRowExactly(index) {
             //if (index >= 0) {
             //  if(vm.data.filtered1.length) $state.go('art.replace', {artId: vm.data.filtered1[index].Item});
             //}
             }*/

            function getItemsBonusBox() {
                //console.log("In main ctrl");
                $timeout(function() { itemService.getActionsItems(vm); }, 100);
            }

            function activeItem() {
                if (vm.$rootStateParams.artId) {
                    vm.data.active = _.findIndex(vm.data.filtered, {Item: vm.$rootStateParams.artId});
                }
            }

            /* function setItems(data) {
             vm.data.items = vm.data.filtered = data;
             }*/

            $scope.$on('artMainItems:setType', function(event, type) {
                $timeout(function () {
                    if(vm.typeSearch == type){
                        vm.getData();
                    }
                },200);
            });


            function getData() {

                var item;

                //if(vm.typeSearch == 1) item = vm.$stateParams.art.replace(/[^a-z0-9]/gi, '');
                //if(vm.typeSearch == 2) item = vm.$stateParams.art+'*';//.replace(/[^a-z0-9]/gi, '');
                //if(vm.typeSearch == 3) item = '*'+vm.$stateParams.art+'*';//.replace(/[^a-z0-9]/gi, '');

               // if(vm.$stateParams.art.indexOf('*') + 1){
                    //item = vm.$stateParams.art;
              //  } else {
                    item = vm.$stateParams.art.replace(/[^a-z0-9*]/gi, '');
               // }



                if (item.length) {

                    $rootScope.title = item;

                    if (vm.$storage.vinType) vm.$storage.vinType.nameFull = '';
                    var params = {item: item, grp: ''};

                    if (vm.$stateParams.grpId) {
                        params.grp = vm.$stateParams.grpId;
                    }

                    $http.get('/api/catalog/search', {params: params})
                        .success(function (data, status) {
                            if (data.userIsBlocked) {
                                window.location = "/Account/Logout";
                            }
                            if (data.allowedSearch) {
                                vm.alerts = {
                                    warning: ['Исчерпан суточный лимит запросов, обратитесь к торговому представителю!']
                                };
                            } else {
                            if (data.items.length) {
                                // vm.data.items1 = data.items1;
                                vm.data.items = data.items;
                                //vm.data.filtered1 = data.items1;
                                vm.data.filtered = data.items;
                                vm.data.locationList = data.loc;
                                itemService.getStock(vm);
                                vm.getItemsBonusBox();

                                vm.data.active = 0;

                                if (data.by) {
                                    angular.forEach(data.by,
                                        function(val, key) {

                                            if (!_.has(vm.data.searchBy, val.item)) {
                                                vm.data.searchBy['' + val.item + ''] = ''
                                            }

                                            vm.data.searchBy[val.item] =
                                                (vm.data.searchBy[val.item] == undefined
                                                    ? ' '
                                                    : vm.data.searchBy[val.item]) +
                                                ' ' +
                                                val.brand +
                                                ': ' +
                                                val.search +
                                                ' ';

                                        });
                                }

                                if (data.images) {

                                    data.images.forEach(function(val) {
                                        if (!vm.data.images[val.Item]) {
                                            vm.data.images[val.Item] = [];
                                        }
                                        vm.data.images[val.Item].push(val);
                                    });
                                }

                                $rootScope.tag = item;


                            } else {

                                vm.alerts = { warning: ['Товар не найден по точному совпадению с запросом: ' + item] };
                                //var type = 1;
                                if (vm.typeSearch == 1) {
                                    //type = 2;
                                    // $rootScope.$broadcast('artMainItems:setType',2);
                                } else if (vm.typeSearch == 2) {
                                    type = 3;
                                }
                                // vm.getDataStart();
                            }
                        }
                        });

                } else {
                    vm.alerts = {warning: ['Товар не найден по пустому запросу']};
                }
            }


/*
            function getDataStart() {
                var item = vm.$stateParams.art+'*';//.replace(/[^a-z0-9]/gi, '');

                if (item.length) {

                    $rootScope.title = item;

                    //if (vm.$storage.vinType) vm.$storage.vinType.nameFull = '';

                    var params = {item: item, grp: ''};

                    if (vm.$stateParams.grpId) {
                        params.grp = vm.$stateParams.grpId;
                    }

                    $http.get('/api/catalog/search', {params: params})
                        .success(function (data, status) {
                            if (data.items.length) {
                                vm.data.itemsStart = data.items;
                                vm.data.filteredStart = data.items;
                                //vm.data.locationList = data.loc;
                                //itemService.getStock(vm.data.itemsStart);

                                var params = {items: "''" + _.pluck(vm.data.itemsStart, 'Item').join("'',''") + "''"};
                                $http.post('/api/catalog/stockitems', params)
                                    .success(function (data, status) {
                                        var stock = {};
                                        if (data.length) {
                                            angular.forEach(data, function (val, key) {
                                                if (!stock[val.ItemNo]) {
                                                    stock[val.ItemNo] = {};
                                                }
                                                stock[val.ItemNo][val.LocationCode] = val.Qty;
                                            });
                                        }
                                        vm.data.stock = stock;
                                    });

                                $rootScope.tag = item;

                                //console.log(data.by);
                                //console.log(vm.data.searchBy);

                            } else {
                                vm.alerts = {warning: ['Товар не найден по началу номера: ' + item]};
                            }
                        });

                } else {
                    vm.alerts = {warning: ['Товар не найден по пустому запросу']};
                }
            }


            function getDataСontains() {
                var item = vm.$stateParams.art.replace(/[^a-z0-9]/gi, '');

                if (item.length) {

                    $rootScope.title = item;

                    if (vm.$storage.vinType) vm.$storage.vinType.nameFull = '';
                    var params = {item: item, grp: ''};

                    if (vm.$stateParams.grpId) {
                        params.grp = vm.$stateParams.grpId;
                    }

                    $http.get('/api/catalog/search', {params: params})
                        .success(function (data, status) {
                            if (data.items.length) {
                                // vm.data.items1 = data.items1;
                                vm.data.items = data.items;
                                //vm.data.filtered1 = data.items1;
                                vm.data.filtered = data.items;
                                vm.data.locationList = data.loc;
                                itemService.getStock(vm);
                                vm.data.active = 0;

                                if (data.by) {
                                    angular.forEach(data.by, function (val, key) {

                                        if (!_.has(vm.data.searchBy, val.item)) {
                                            vm.data.searchBy['' + val.item + ''] = ''
                                        }

                                        vm.data.searchBy[val.item] = (vm.data.searchBy[val.item] == undefined ? ' ' : vm.data.searchBy[val.item]) + ' ' + val.brand + ': ' + val.search + ' ';

                                    });
                                }

                                $rootScope.tag = item;

                                //console.log(data.by);
                                //console.log(vm.data.searchBy);

                            } else {
                                vm.alerts = {warning: ['Товар не найден по запросу: ' + item]};
                            }
                        });

                } else {
                    vm.alerts = {warning: ['Товар не найден по пустому запросу']};
                }
            }
*/


        }]);

