
angular.module('ui.table.items', [])

	.controller('TableItemsController', ['$scope', '$filter', 'hotkeys',
		function ($scope, $filter, hotkeys) {
			//var ctrl = this,
				//var model = $scope.model;

            $scope.model.isAllSelect = false;

            $scope.model.shift = [false, false];

			// Фильтр по поиску
			$scope.$watchCollection("model.search", function (query) {
                $scope.model.active = -1;
                $scope.model.selected = [];
                $scope.model.filtered = $filter("filter")($scope.model.items, query.items);
			});

            $scope.$watchCollection("model.selected", function (query) {
                if($scope.model.selected.length == 0){
                    $scope.model.shift = [false, false];
                }
            });

			// Поиск индекса активной строки
            $scope.findActiveRow = function (row) {
				var index = _.findIndex($scope.model.filtered, row);
				if (index >= 0) {
					return index;
				} else {
					return -2;
				}
			};

			// Проверка активна ли строка
            $scope.isActiveRow = function (row) {
				if ($scope.findActiveRow(row) == $scope.model.active) {
					return true;
				} else {
					return false;
				}
			};

			// Поиск индекса выделенной строки
            $scope.findSelectedRow = function (row) {
				var index = _.findIndex($scope.model.selected, row);
				if (index >= 0) {
					return index;
				} else {
					return -5;
				}
			};

			// Проверка выделена ли строка
            $scope.isSelectedRow = function (row) {
				//var index = _.findIndex($scope.model.selected, row);
				if ($scope.findSelectedRow(row) >= 0) {
					return true;
				} else {
					return false;
				}
			};

			// Выделение строки
            $scope.selectRow = function (row) {

                if( !$scope.model.shift){
                    $scope.model.shift = [false, false];
                }

                if (!row) {
					row = $scope.model.filtered[$scope.model.active];
				}

                var indexRow = _.findIndex($scope.model.filtered, row);

				var index = $scope.findSelectedRow(row);
				if (index >= 0) {
                    $scope.model.selected.splice(index, 1);
					return false;
				} else {



                    if (row) $scope.model.selected.push(row);

                    if (shifted && $scope.model.shift[0]) {
                        //console.log(shifted);
                        $scope.model.shift[1] = indexRow;
                    }

                    //if (!shifted && !vm.data.shift[1]) {
                    if (!$scope.model.shift[1]) {
                        $scope.model.shift[0] = indexRow;
                    }

                   /*if(!shifted && !$scope.model.shift[1]){
                       $scope.model.shift[0] = indexRow;
                   }

                    if(shifted && $scope.model.shift[0]){
                        $scope.model.shift[1] = indexRow;
                    }*/

                    if(shifted && $scope.model.shift[0] && $scope.model.shift[1]){
                        var start =  $scope.model.shift[0];
                        var end =  $scope.model.shift[1];

                        if(start < end){
                            for (var i = start+1; i < end; i++) {
                                $scope.model.selected.push($scope.model.filtered[i])
                            }
                        } else {
                            for (var i = end+1; i < start; i++) {
                                $scope.model.selected.push($scope.model.filtered[i])
                            }
                        }
                        $scope.model.shift = [false, false];
                    }
					return true;
				}
			};

			// Активирование строки
            $scope.activeRow = function (index) {
                $scope.model.active = index;
			};



			// Выделение всех строк
            $scope.selectAllRows = function () {
                $scope.model.isAllSelect = !$scope.model.isAllSelect;
                $scope.model.shift = [false, false];

                if(!$scope.model.isAllSelect){
                    $scope.model.selected = [];
                    return true;
                }


				angular.forEach($scope.model.filtered, function (row, key) {
					var index = $scope.findSelectedRow(row);
					if (index >= 0) {
						if (!$scope.model.isAllSelect) $scope.model.selected.splice(index, 1);
					} else {
						if ($scope.model.isAllSelect) $scope.model.selected.push(row);
					}
				});
			};

            $scope.scrollIntoView = function (index, element, container) {

                if(!container){
                    container = $scope.model.container;
                }

                var compensation;

                if(container == ".modal"){
                    compensation = 114;
                } else {
                    var c = $(container).position();
                    compensation = c.top;
                }


                var containerTop = $(container).scrollTop();
                var containerBottom = containerTop + $(container).height()- compensation;
                var offset = element.position();
                var elemTop = offset.top+compensation;
                var elemBottom = elemTop + $(element).height();

               // var maxScrollPosition = $(container)[0].scrollHeight - $(container)[0].clientHeight;
                //console.log(maxScrollPosition);

                if (elemTop < containerTop) {
                    if(index == 0){
                        $(container).scrollTop(0);
                    } else {
                        $(container).scrollTop(elemTop);
                    }
                } else if (elemBottom > containerBottom) {

                    var vv = elemBottom - $(container).height()+compensation;

                    $(container).scrollTop(vv);
                   // console.log('toScroll:' +vv);
                    //$(container).scrollTop(containerBottom);
                }
            };

            $scope.bindTableItem = function () {

                hotkeys

                    .add({
                        combo: "up",
                        description: "Движение вверх по списку",
                        allowIn: ["INPUT", "SELECT", "TEXTAREA"],
                        callback: function (event, hotkey) {
                            event.preventDefault();
                            if ($scope.model.active >= 0) {
                                $scope.model.active -= 1;
                            }
                            if ($scope.model.active < 0) {
                                $scope.model.active = $scope.model.filtered.length - 1;
                            }
                            if($("#grid_row_"+$scope.model.active).length) $scope.scrollIntoView($scope.model.active,$("#grid_row_"+$scope.model.active));
                        }
                    });

                hotkeys

                    .add({
                        combo: "pageup",
                        description: "Движение вверх по списку",
                        allowIn: ["INPUT", "SELECT", "TEXTAREA"],
                        callback: function (event, hotkey) {
                            event.preventDefault();
                            if ($scope.model.active >= 0) {
                                $scope.model.active -= 10;
                            }
                            if ($scope.model.active < 0) {
                                $scope.model.active = $scope.model.filtered.length - 1;
                            }
                            if($("#grid_row_"+$scope.model.active).length) $scope.scrollIntoView($scope.model.active, $("#grid_row_"+$scope.model.active));
                        }
                    });

                hotkeys.add({
                        combo: "down",
                        description: "Движение вниз по списку",
                        allowIn: ["INPUT", "SELECT", "TEXTAREA"],
                        callback: function (event, hotkey) {
                            event.preventDefault();
                            if ($scope.model.active <= ($scope.model.filtered.length - 1)) {
                                $scope.model.active += 1;
                            }
                            if ($scope.model.active > $scope.model.filtered.length - 1) {
                                $scope.model.active = 0;
                            }
                            if($("#grid_row_"+$scope.model.active).length) $scope.scrollIntoView($scope.model.active, $("#grid_row_"+$scope.model.active));
                        }

                    });

                hotkeys.add({
                    combo: "pagedown",
                    description: "Движение вниз по списку",
                    allowIn: ["INPUT", "SELECT", "TEXTAREA"],
                    callback: function (event, hotkey) {
                        event.preventDefault();
                        if ($scope.model.active <= ($scope.model.filtered.length - 1)) {
                            $scope.model.active += 10;
                        }
                        if ($scope.model.active > $scope.model.filtered.length - 1) {
                            $scope.model.active = 0;
                        }
                        if($("#grid_row_"+$scope.model.active).length) $scope.scrollIntoView($scope.model.active, $("#grid_row_"+$scope.model.active), ".mainview");
                    }

                });

                hotkeys.add({
                        combo: "space",
                        description: "Выбор строки",
                        //allowIn: ["INPUT", "SELECT", "TEXTAREA"],
                        callback: function (event, hotkey) {
                            event.preventDefault();
                            if($scope.selectRow) $scope.selectRow();

                            if ($scope.model.active <= ($scope.model.filtered.length - 1)) {
                                $scope.model.active += 1;
                            }
                            if ($scope.model.active > $scope.model.filtered.length - 1) {
                                $scope.model.active = 0;
                            }

                            //model.active += 1;
                        }
                    });
                hotkeys.add({
                        combo: "enter", description: "Открытие", //allowIn: ["INPUT", "SELECT", "TEXTAREA"],
                        callback: function (event, hotkey) {
                            event.preventDefault();
                           if($scope.actionRow) $scope.actionRow();
                        }
                    });


			/*hotkeys.bindTo($scope)

				.add({
					combo: "up",
					description: "Движение вверх по списку",
					allowIn: ["INPUT", "SELECT", "TEXTAREA"],
					callback: function (event, hotkey) {
						event.preventDefault();
						if (model.active >= 0) {
							model.active -= 1;
						}
						if (model.active < 0) {
							model.active = model.filtered.length - 1;
						}
					}
				})

				.add({
					combo: "down",
					description: "Движение вниз по списку",
					allowIn: ["INPUT", "SELECT", "TEXTAREA"],
					callback: function (event, hotkey) {
						event.preventDefault();
						if (model.active <= (model.filtered.length - 1)) {
							model.active += 1;
						}
						if (model.active > model.filtered.length - 1) {
							model.active = 0;
						}
					}
				})

				.add({
					combo: "space",
					description: "Выбор строки",
					//allowIn: ["INPUT", "SELECT", "TEXTAREA"],
					callback: function (event, hotkey) {
						event.preventDefault();
                        $scope.selectRow();

						if (model.active <= (model.filtered.length - 1)) {
							model.active += 1;
						}
						if (model.active > model.filtered.length - 1) {
							model.active = 0;
						}

						//model.active += 1;
					}
				})
				.add({
					combo: "enter", description: "Открытие", allowIn: ["INPUT", "SELECT", "TEXTAREA"],
					callback: function (event, hotkey) {
						event.preventDefault();
                        $scope.actionRow();
					}
				});*/


		};

            $scope.bindTableItem();

		/*var destroyed;
		$scope.$on('$destroy', function() {
			destroyed = true;
			//$scope.$parent.bindTableItem();

            //console.log($scope);

			//if(parent) parent.bindAll();
		});*/
	}])
	.directive('tableItems', function() {
		return {
			restrict: 'EA',
			transclude: false,
			replace: false,
			/*scope:{
				//model:'='
			},*/
			controller: 'TableItemsController',
			link: function(scope, element, attrs) {
				/*scope.vertical = angular.isDefined(attrs.vertical) ? scope.$parent.$eval(attrs.vertical) : false;
				scope.justified = angular.isDefined(attrs.justified) ? scope.$parent.$eval(attrs.justified) : false;*/


                //$timeout(function(){

                //});



			}
		};
	})
    /*.directive('tableItemsThSelect', function() {
        return {
            restrict: 'EA',
            transclude: true,
            replace: false,
            template:'<th class="item-check">' +
            '<input ng-click="selectAllRows()" ng-checked="model.isAllSelect" type="checkbox" class="form-control form-group-sm"/>' +
            '</th>',
            controller: 'TableItemsController',
            link: function(scope, element, attrs) {
                *//*scope.vertical = angular.isDefined(attrs.vertical) ? scope.$parent.$eval(attrs.vertical) : false;
                 scope.justified = angular.isDefined(attrs.justified) ? scope.$parent.$eval(attrs.justified) : false;*//*
            }
        };
    })*/
;