'use strict';

//angular.module('reports', []);

angular.module('settings', [

]).config(["$stateProvider", function ($stateProvider) {
    $stateProvider
        .state("settings", {
            url: "/settings",
            views:{
                "@" : {
                    templateUrl: "/app/settings/settings.html"
                },
                'menu@settings':{
                    templateUrl: "/app/settings/settings-menu.html"
                }
            }
        });
}]);