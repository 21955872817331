'use strict';

angular.module('adm.adv-items')
    .service('AdvItemsService', AdvItemsService);

function AdvItemsService($http, $rootScope) {

    var baseAddress = '/api/advitems';

    var service = {
        get: get,
        add: add,
        del: del,
        update: update
    };

    return service;

    function get(callback, data) {
        if(!data) data = {};
        $http.get(baseAddress, data, {ignoreLoadingBar: true}).success(callback);
    }

    function add(data, callback) {
        if(!callback) callback = function(){};
        $http.post(baseAddress, data).success(callback); //, {ignoreLoadingBar: true}
       // $rootScope.$broadcast('userCars:add',data);
    }

    function update(id, data, callback) {
        if(!callback) callback = function(){};
        $http.put(baseAddress+'/'+id, data, {ignoreLoadingBar: true}).success(callback);
    }

    function del(id, callback) {
        if(!callback) callback = function(){};
        $http.delete(baseAddress+'/id/'+id, {}, {ignoreLoadingBar: true}).success(callback);
       // $rootScope.$broadcast('userCars:del');
    }
}