'use strict';

angular.module('app')
    .service('itemService', itemService);

function itemService($http, $modal) {
    var service = {
        getStock: getStock,
        getActionsItems: getActionsItems,
        setSpecItems: setSpecItems,
        setSearchBy: setSearchBy,
        setFilters: setFilters,
        openReplacesModal: openReplacesModal,
        openArtActions: openArtActions

    };
    return service;

    function openArtActions(row) {
        var modalInstance = $modal.open({
            templateUrl: "/app/art/art-actions-modal.html",
            controller: "ArtActionsModalController",
            controllerAs: "vm",
            //size: 'lg',
            windowClass: "no-animation-modal",
            resolve: {
                parent: function () {
                    return row;
                }
            }
        });
        modalInstance.result.then(
            function () {
                //vm.getData();
            },
            function () {
                //vm.getData();
            }
        );
    }

    function openReplacesModal(row) {
        var modalInstance = $modal.open({
            templateUrl: "/app/art/art-replace-items-modal.html",
            controller: "ArtReplaceItemsModalController",
            controllerAs: "modal",
            //size: 'lg',
            windowClass: "no-animation-modal",
            resolve: {
                parent: function () {
                    return row;
                }
            }
        });
        modalInstance.result.then(
            function () {
                //vm.getData();
            },
            function () {
                //vm.getData();
            }
        );
    }
    function getStock(vm) {
        service.setSearchBy(vm);
        service.setFilters(vm);
        var result;
        if (vm.data.items.length > 0) {
            var data = {items: "''" + _.pluck(vm.data.items, 'Item').join("'',''") + "''"};
            result = $http.post('/api/catalog/stockitems', data)
                .then(function (response) {
                    var data = response.data;
                    var stock = {};
                    if (data.length) {
                        angular.forEach(data, function (val, key) {
                            if (!stock[val.ItemNo]) {
                                stock[val.ItemNo] = {};
                            }
                            stock[val.ItemNo][val.LocationCode] = val.Qty;
                        });
                    }
                    vm.data.stock = stock;
                }).then(function () {
                    return service.getActionsItems(vm);
                });
        } else {
            result = true;
        }
        return result;
    }

    function setFilters(vm) {
        var brands = [];
        var tags = [];
        angular.forEach(vm.data.items, function (row, index) {
            if (_.indexOf(brands, row['Бренд']) == -1) {
                brands.push(row['Бренд']);
            }

            /*if (_.indexOf(tags, row['Название']) == -1) {
                tags.push(row['Название']);
            }*/

            angular.forEach(row['Название'].split(' '), function (val, i) {
                if (_.indexOf(tags, val) == -1) {
                    tags.push(val);
                }
            });
        });
        vm.data.brands = brands;
        vm.data.tags = tags;

    }

    function getActionsItems(vm) {
        //console.log("In item service");
        var result = $http.post('/api/marketing/itemsbonusbox', _.pluck(vm.data.items, 'Item'))
            .then(function (response) {
                var data = response.data;
                if (data.items && data.items.length > 0) {
                    var items = {};
                    angular.forEach(data.items, function (val, key) {
                        items[val.Item] = true;
                    });
                    vm.data.itemsBonusBox = items;
                }
                vm.data.actionItems = data.actionItems;
                vm.data.adClubLink = data.AdClubLink;

                service.setSpecItems(vm, data.spec, data.specSales);
                return data;
            });
        return result;
    }

    function setSpecItems(vm, spec, specSales) {

        if (spec && spec.length) {
            var items = {};
            angular.forEach(spec,
                function(val, key) {
                    if (!items[val.Item]) {
                        items[val.Item] = [];
                    }
                    items[val.Item].push({ qty: val.Qty, discount: val.Discount });
                });

            vm.data.specItems = items;
        }
        // disable new feature
        /*
          if (specSales && specSales.length) {
         
            var itemsPrice = {};
            angular.forEach(specSales,
                function(val, key) {
                    if (!itemsPrice[val.Item]) {
                        itemsPrice[val.Item] = [];
                    }
                    itemsPrice[val.Item].push({ qty: val.Qty, price: val.Price });
                });

            vm.data.specItemsSalesPrice = itemsPrice;
        }
        */
    }

    function setSearchBy(vm) {
        var items = {};
        if (vm.data.by) {
            angular.forEach(vm.data.by, function (val, key) {

                if (!_.has(items, val.item)) {
                    vm.data.searchBy['' + val.item + ''] = ''
                }

                items[val.item] = (items[val.item] == undefined ? ' ' : items[val.item]) + ' ' + val.brand + ': ' + val.search + ' ';

            });
            vm.data.searchBy = items;
        }
    }
}