'use strict';

angular.module('app')
    .controller('ToOrderModalController', ['$scope', '$http', '$timeout', '$modalInstance', '$localStorage', 'hotkeys', '$log', '$filter', '$modal', 'parent',
        function ($scope, $http, $timeout, $modalInstance, $localStorage, hotkeys, $log, $filter, $modal, parent) {
            var vm = this;
            vm.$storage = $localStorage;
            vm.parent = parent;
            vm.data = {
                container: '.modal',
                filtered: parent.data.LocationList,
                items: parent.data.LocationList,
                selected: [],
                active: 0
            };

            vm.list = {};
            vm.list.actionRow = actionRow;
            vm.ok = ok;
            vm.cancel = cancel;

            function ok() {
                var loc = vm.data.filtered[vm.data.active];
                if ((loc.indexOf('УЦН') > -1) && !(loc.indexOf(' УЦН') > -1)) {
                    loc = loc.replace("УЦН", " УЦН");
                }
                $modalInstance.close(loc);
            }

            function cancel() {
                $modalInstance.dismiss('cancel');
            }

            function actionRow(index) {
                if(!index){
                    ok();
                }
                vm.data.active = index;
            }
        }]);
