'use strict';

angular.module('adm.adv-items')
    .controller('AdvItemsModalController', ['$scope', '$http', '$timeout', '$modalInstance', '$localStorage', 'hotkeys', '$log', '$filter', '$modal', '$upload', 'parent', 'RestService',
        function ($scope, $http, $timeout, $modalInstance, $localStorage, hotkeys, $log, $filter, $modal, $upload, parent, RestService) {

            $scope.openedStart = false;
            $scope.openedEnd = false;

            $scope.openStart = function($event) {
                $event.preventDefault();
                $event.stopPropagation();
                $scope.openedStart = true;
            };

            $scope.openEnd = function($event) {
                $event.preventDefault();
                $event.stopPropagation();
                $scope.openedEnd = true;
            };

            $scope.format = 'dd.MM.yyyy';

            var vm = this;
            //vm.$storage = $localStorage;

            vm.alerts = {};

            vm.rest = RestService;
            vm.model = {};


            vm.data = {
                container: '.modal',
               uploded: false
            };

            /*vm.datepickerOptions ={
                start: {
                    format: "dd.mm.yyyy",
                    language: "ru",
                    autoclose: true
                }
            };*/

            // console.log($scope);

            //vm.deleteRow = deleteRow;
            // vm.actionRow = actionRow;
            vm.save = save;
            vm.cancel = cancel;
            vm.getModel = getModel;
            vm.upload = upload;

            vm.getModel();


            function upload(files) {

                if (files && files.length) {
                    for (var i = 0; i < files.length; i++) {
                        var file = files[i];
                        $upload.upload({
                            url: '/api/tmp/upload',
                            /*fields: {
                             'username': $scope.username
                             },*/
                            file: file
                        }).progress(function (evt) {
                            //var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                            //console.log('progress: ' + progressPercentage + '% ' + evt.config.file.name);
                        }).success(function (data, status, headers, config) {

                            //vm.data.path = data.path;
                            //vm.model.Image = data.path;
                            vm.model.ImageHash = data.path;
                            vm.data.uploaded = true;
                           /* if (data.alerts) vm.data.alerts = data.alerts;

                            vm.$storage.cart.items = [];

                            angular.forEach(data.items, function (val, key) {
                                vm.$storage.cart.items.push({Item: val.Item, qnty: data.qnty[val.Item]});
                            });

                            vm.getData();
                            vm.alerts = data.alerts;*/
                            //$scope.addAlerts(data.alerts);
                        }).error(function () {

                            vm.alerts = {danger: ['Не верный формат файла']};

                            //$scope.setAlerts('danger', ['Не верный формат файла']);

                        });
                    }
                }
            }


           /*$timeout(function(){
                $('#AdvItems-Start').datepicker({
                    format: "yyyy-mm-dd",
                    language: "ru",
                    autoclose: true
                });
                $('#AdvItems-End').datepicker({
                    format: "yyyy-mm-dd",
                    language: "ru",
                    autoclose: true
                });
            });*/


            function getModel() {
                if(parent.id){

                    var params = {id:parent.id};
                    vm.rest.get(function(data){
                        var model = data;
                       // if(model.Start) model.Start = model.Start.replace('T00:00:00','');
                        //if(model.End) model.End = model.End.replace('T00:00:00','');
                        vm.model = model;
                    }, params);
                }
            }

            /*function deleteRow() {
             if (confirm("Удалить выбранные автомобили?")) {
             angular.forEach(vm.data.selected, function (row, key) {
             var index = _.findIndex($scope.$storage.cars, row);
             if (index >= 0) {
             $scope.$storage.cars.splice(index, 1);
             }
             });
             } else {}
             }*/

            function save() {
                if (vm.model && vm.model.id != undefined && vm.model.id) {
                    vm.rest.update(vm.model.id, vm.model, function (data) {
                        if (data.alerts) {
                            vm.alerts = data.alerts;
                        } else {
                            $modalInstance.close(vm.data.form);
                        }
                    });
                } else {
                    vm.rest.add(vm.model, function (data) {
                        if (data.alerts) {
                            vm.alerts = data.alerts;
                        } else {
                            $modalInstance.close(vm.data.form);
                        }

                    });
                }
            }

            function cancel() {
                $modalInstance.dismiss('cancel');
            }

            /*
             function actionRow(row) {
             if (!row) {
             row = vm.data.filtered[vm.data.active];
             }
             $scope.ok(row);
             }*/
        }]);
