"use strict";

//$(".mainview").focus();

function scrollIntoView(element, container) {
    var containerTop = $(container).scrollTop();
    var containerBottom = containerTop + $(container).height();
    var elemTop = element.offsetTop;
    var elemBottom = elemTop + $(element).height();
    if (elemTop < containerTop) {
        $(container).scrollTop(elemTop);
    } else if (elemBottom > containerBottom) {
        $(container).scrollTop(elemBottom - $(container).height());
    }
}


angular.module("app")
	.controller("OrdersController", ["$scope","$rootScope",  "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", "hotkeys","cfpLoadingBar",
		function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, hotkeys, cfpLoadingBar) {
			$scope.$storage = $localStorage;

            /*$http.post('/api/orders/loclist')
                .success(function (data, status) {
                    $scope.$storage.locList = data.rows;
                    /!*angular.forEach(data.rows, function (row) {
                     $scope.$storage.locList.push(row.Code);
                     });*!/
                });*/


			var model = $scope.model = {
                container:'.mainview',
                items: [],
				filtered: [],
				selected: [],
				active: -1,
				search: {},
                errors: [],
				total: 0
			};


            openModal();

            function openModal() {
                if (window.rem) {
                    return;
                } else {
                    window.rem = true;
                }
                var today = new Date();
                var milliseconds = today.getMilliseconds();
                $http.get("/home/getReminder?milliseconds=" + milliseconds)
                    .success(function (data, status) {
                        if (data != "") {
                            var modalInstance = $modal.open({
                                templateUrl: "/app/home/reminder-modal.html",
                                controller: "ReminderModalController",
                                controllerAs: "vm",
                                backdrop: 'static',
                                windowClass: "no-animation-modal",
                                resolve: {
                                    reminder: function() {
                                        return data;
                                    }
                                }
                            });
                            modalInstance.result.then(
                                function (result) {
                                    window.rem = false;
                                },
                                function (result) {
                                    if (result == "escape key press") {
                                        window.rem = false;
                                        openModal();
                                    } else {
                                        window.rem = false;
                                    }
                                }
                            );
                        }
                    });

            }





			$scope.$watchCollection("model", function (query) {
                $scope.calcTotal();
			});

			// Расчет суммы заказов TODO:from API
            $scope.calcTotal = function () {
				var total = 0;
				angular.forEach(model.items, function (row, key) {
					row.Total = Number(row.Total);
					total = total + row.Total;
				});
				model.total = total;

                $rootScope.title = 'Заказов: '+model.items.length+' На сумму: '+$filter('number')(model.total,2);

            };

            $scope.getData = function() {
                $http.post("/api/orders/list")
                    .success(function(data, status) {
                        model.items = data.rows;
                        model.filtered = data.rows;
                        $scope.$storage.params = model.items.length
                        $scope.calcTotal();
                    });
                return true;
            };

            $scope.getData();


            // Открыть заказ
            $scope.open = function (row) {
                var modalInstance = $modal.open({
                    templateUrl: "/app/orders/order-modal.html",
                    controller: "OrderModalController",
                    size: 'lg',
                    windowClass: "no-animation-modal",
                    resolve: {
                        row: function () {
                            return row;
                        },
                        parent:function () {
                            return model;
                        }
                    }
                });

                modalInstance.result.then(function (returnRow) {
                    cfpLoadingBar.start();
                    $timeout(function () {
                        $timeout(function(){
                            $scope.getData();
                            cfpLoadingBar.complete();
                        },1500);
                       $scope.bindTableItem();
                    });
                }, function (alerts) {
                    cfpLoadingBar.start();
                    $timeout(function () {
                        $scope.getData();
                        if( (typeof alerts === "object") && (alerts !== null)){ // && // && alerts != 'cancel' && alerts != 'escape key press' && alerts != 'back drop  click'
                            $scope.addAlerts(alerts);
                        }
                        $scope.bindTableItem();
                        cfpLoadingBar.complete();
                    }, 1500);
                });
            };

            $scope.closeAlert = function(index){
               delete model.alerts[index];
            };

            $scope.addAlerts = function(alerts){
                model.alerts = alerts;
                if(model.alerts.success && model.alerts.success.length > 0){
                    $timeout(function(){
                        delete model.alerts.success;
                    },4000);
                }
            };

			// Добавление товара из заказа
            $scope.addToCartFromOrder = function () {
                if (confirm("Добавить выбранные заказы в корзину?")) {
                    var cart = angular.copy($scope.$storage.cart.items);
                    var added = 0;
                    var updated = 0;
                    angular.forEach(model.selected, function(row) {
                        $http.post("/api/orders/order", row)
                            .success(function (data, status) {
                                angular.forEach(data.rows, function(val) {
                                    var index = _.findIndex(cart, {Item:val.No});
                                    if(index>=0){
                                        cart[index].qnty = Number(cart[index].qnty)+Number(val.Quantity);
                                        updated++;
                                    } else {
                                        cart.push({Item:val.No,qnty:val.Quantity, tag:row.OrderNo });
                                        added++;
                                    }
                                });
                            });
                    });

                    $scope.$storage.cart.items = cart;

                    $timeout(function(){
                        $scope.addAlerts({success: ['В корзину добавлено: '+(added)+' Обновлено: '+(updated)]});
                    },1000);
                } else {}
			};

            // Экспорт заказов в Excel
            $scope.exportOrdersToExcel = function () {

            };


			// Удалене заказа //
            $scope.deleteRows = function () {
                if (confirm("Удалить выбранные заказы?")) {
                    model.alerts = [];
                    $http.post("/api/orders/delete", model.selected)
                        .success(function(data) {
                            $scope.addAlerts(data.alerts);
                            $scope.getData();
                        });
                    return true;
                } else {}
			};


            $scope.deleteRow = function (row) {
                if (confirm("Удалить заказ № "+row.OrderNo+" ?")) {
                    model.alerts = [];
                    $http.post("/api/orders/delete", [row])
                        .success(function(data) {
                            $scope.addAlerts(data.alerts);
                            $scope.getData();
                        });
                    return true;
                } else {}
            };

            $scope.actionRow = function () {
                $scope.open(model.filtered[model.active]);
			};

            $scope.setActiveOrder = function () {
                if(model.selected.length == 1 && model.selected[0].Document_Status_Name == 'Открыто'){
                    $scope.$storage.activeOrder = model.selected[0].OrderNo;
                }
			};

            $scope.bindAll = function () {
                hotkeys
                    .bindTo($scope)
                    .add({
                        combo: "alt+a",
                        description: "Новый заказ",
                        allowIn: ["INPUT", "SELECT", "TEXTAREA"],
                        callback: function (event, hotkey) {
                            event.preventDefault();
                            $scope.open();
                        }
                    })
                ;
            };

            $scope.bindAll();

		}]);
