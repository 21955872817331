'use strict';

angular.module('adm.adv-items')
    .controller('AdvItemsController',
    ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", "hotkeys", "RestService",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, hotkeys, RestService) {

            $rootScope.title = 'Акции';

            var vm = this;

            vm.rest = RestService;
            vm.rest.set({name: 'AdvItems', url: '/api/advitems'});

            vm.alerts = {};

            vm.data = {
                search: '',
                items: [],
                filtered: [],
                selected: [],
                active: -1
            };

            vm.getData = getData;
            vm.open = open;
            vm.deleteRows = deleteRows;
            vm.actionRow = actionRow;

            vm.getData();

            function getData() {
                vm.rest.get(function (data) {
                    vm.data.filtered = data;
                    vm.data.items = data;
                    $rootScope.$broadcast('AdvItems:getData');
                    //$scope.$apply(vm.data.filtered);
                });
            }

            function deleteRows() {

                var i = 0;

                angular.forEach(vm.data.selected, function (row) {
                    vm.rest.del(row.id, function (data) {
                        i++;
                        var index = _.findIndex(vm.data.filtered, row);
                        vm.data.filtered.slice(index, 1);
                    });
                });

                $timeout(function () {
                    vm.getData();
                    vm.alerts = {success: ['Удалено строк: ' + (i)]};
                }, 1000);
            }

            function open(row) {
                if (!row) {
                    row = {}
                }
                var modalInstance = $modal.open({
                    templateUrl: "/app/adm/adv-items/adv-items-modal.html",
                    controller: "AdvItemsModalController",
                    controllerAs: "vm",
                    size: 'lg',
                    windowClass: "no-animation-modal",
                    resolve: {
                        parent: function () {
                            return row;
                        }
                    }
                });
                modalInstance.result.then(function (model) {
                    vm.bindTableItem();
                    vm.getData();
                    //vm.save(model);
                }, function () {
                    vm.bindTableItem();
                    vm.getData();
                });
            }


            function actionRow() {
                vm.open(vm.data.filtered[vm.data.active]);
            }


        }]);

