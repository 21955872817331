'use strict';

angular.module('adm.adv-items', []);

angular.module('adm', [
    'adm.adv-items'
]).config(["$stateProvider", function ($stateProvider) {
    $stateProvider
        .state("adm", {
            url: "/adm",
            views:{
                "@" : {
                    templateUrl: "/app/adm/adm.html"/*,
                 controller: "AdvItemsController",
                 controllerAs:"vm"*/
                }
            }
        });
}]);

