'use strict';


angular.module('app')
    .controller('TitleController',
    ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$state", '$stateParams', "$localStorage", "hotkeys",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $state, $stateParams, $localStorage, hotkeys) {

            var vm = this;

            vm.$storage = $localStorage;
            vm.$stateParams = $stateParams;
            vm.$rootStateParams = $rootScope.$stateParams;

            vm.title = '';

            $scope.$watch("vm.$rootStateParams", function (params) {
                if(params.art){
                    vm.title = params.art;
                }
            });


        }]);

