'use strict';


angular.module('app')
    .controller('CarListItemsController',
    ["$scope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", "hotkeys",
        function ($scope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, hotkeys) {

            var vm = this;

            vm.isActiveRow = isActiveRow;
            //vm.data = $scope.main.data;




            function isActiveRow(index) {
                //return $scope.cars.data.activeView == $scope.vm.data.numView && index == $scope.cars.items.active;
            }

          // console.log($scope.vm);
           // console.log(vm);

            $scope.getData = function() {
                $http.get("/api/catalog/marks")
                    .success(function(data) {
                        $scope.setItems(data);
                    });
            };
            //$scope.getData();

        }]);

