'use strict';

angular.module('app')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("home", {
                url: "/",
                templateUrl: "/app/home/home.html",
                controller: "HomeController",
                controllerAs:"ctrl"

            });
    }]);