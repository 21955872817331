'use strict';

angular.module('reports')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("reports.wallet", {
                url: "/wallet",
                views:{
                    'main@reports': {
                        templateUrl: "/app/reports/wallet/wallet.html",
                        controller: "WalletController",
                        controllerAs: "vm"
                    }
                }
            });
    }]);