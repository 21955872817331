'use strict';

angular.module('app')
    .controller('ArtTopItemsController',
    ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", '$state', '$stateParams', "hotkeys", "itemService",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, $state, $stateParams, hotkeys, itemService) {

            var vm = this;
            vm.$storage = $localStorage;
            vm.$stateParams = $stateParams;
            vm.$rootStateParams = $rootScope.$stateParams;

            vm.alerts = {};
            vm.typeSearch = 1;

            vm.data = {
                numView: 1,
                view: 'mainview',
                items1: [],
                filtered1: [],
                items: [],
                images: {},
                filtered: [],
                itemsBonusBox:{},
                type: [],
                stock: [],
                active: -1,
                searchBy: {},
                searchArt: vm.$stateParams.art,
                specItems: {},
                specItemsSalesPrice: {},
                actionItems: [],
                ComId:""
            };
            vm.getData = getData; //Поиск по точному совпадению или с использованием *
            vm.getItemsBonusBox = getItemsBonusBox;
            vm.activeItem = activeItem;
            vm.openReplacesModal = openReplacesModal;
            vm.openArtActions = openArtActions;
            vm.getData();

            function getData() {
                vm.comId = localStorage.getItem('ComId');
                $http.get('/api/catalog/topitems')
                    .success(function (data, status) {
                        if (data.items.length) {
                            vm.data.items = data.items;
                            vm.data.filtered = data.items;
                            vm.data.locationList = data.loc;
                            itemService.getStock(vm);
                            //vm.getItemsBonusBox();
                            vm.data.active = 0;
                            if (data.by) {
                                angular.forEach(data.by, function (val, key) {
                                    if (!_.has(vm.data.searchBy, val.item)) {
                                        vm.data.searchBy['' + val.item + ''] = ''
                                    }
                                    vm.data.searchBy[val.item] = (vm.data.searchBy[val.item] == undefined ? ' ' : vm.data.searchBy[val.item]) + ' ' + val.brand + ': ' + val.search + ' ';
                                });
                            }

                            
                            if (data.images) {
                                angular.forEach(data.images, function (val, key) {

                                    if (!_.has(vm.data.images, val.Item)) {
                                        vm.data.images['' + val.Item + ''] = []
                                    }
                                    vm.data.images['' + val.Item + ''].push(val);
                                });                                
                            }

                            $rootScope.tag = 'Топ-12';
                        }
                    });
            }


            function openArtActions(row) {

                var modalInstance = $modal.open({
                    templateUrl: "/app/art/art-actions-modal.html",
                    controller: "ArtActionsModalController",
                    controllerAs: "vm",
                    //size: 'lg',
                    windowClass: "no-animation-modal",
                    resolve: {
                        parent: function () {
                            return row;
                        }
                    }
                });
                modalInstance.result.then(
                    function () {
                        //vm.getData();
                    },
                    function () {
                        //vm.getData();
                    }
                );
            }

            function openReplacesModal(row) {

                var modalInstance = $modal.open({
                    templateUrl: "/app/art/art-replace-items-modal.html",
                    controller: "ArtReplaceItemsModalController",
                    controllerAs: "modal",
                    //size: 'lg',
                    windowClass: "no-animation-modal",
                    resolve: {
                        parent: function () {
                            return row;
                        }
                    }
                });
                modalInstance.result.then(
                    function () {
                        //vm.getData();
                    },
                    function () {
                        //vm.getData();
                    }
                );
            }

            function getItemsBonusBox() {
                $timeout(function() { itemService.getActionsItems(vm); }, 100);
            }

            function activeItem() {
                if (vm.$rootStateParams.artId) {
                    vm.data.active = _.findIndex(vm.data.filtered, {Item: vm.$rootStateParams.artId});
                }
            }

            $scope.$on('artMainItems:setType', function(event, type) {
                $timeout(function () {
                    if(vm.typeSearch == type){
                        vm.getData();
                    }
                },200);
            });

        }]);

