'use strict';

angular.module('app')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("orders", {
                url: "/orders",
                templateUrl: "/app/orders/orders.html",
                controller: "OrdersController"
            });
    }]);