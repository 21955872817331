'use strict';

angular.module('app')
    .controller('GoodsMainItemsController',
        ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", '$state', '$stateParams', "hotkeys", "itemService",
            function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, $state, $stateParams, hotkeys, itemService) {

                var vm = this;
                vm.$storage = $localStorage;
                vm.$stateParams = $stateParams;
                vm.$rootStateParams = $rootScope.$stateParams;
                vm.itemService = itemService;
                vm.comId = localStorage.getItem('ComId');

                vm.alerts = {};
                vm.typeSearch = 1;
                vm.inStock = false;

                vm.data = {
                    numView: 1,
                    view: 'mainview',
                    items: [],
                    filtered: [],
                    images: {},
                    filters: {brand: '', criteria: '',  tag: [], inStock: false},
                    type: [],
                    stock: [],
                    active: -1,
                    searchArt: vm.$stateParams.art,
                    listFilters:{},
                    params:{},
                    
                };
                vm.data.params.filters = [];

                vm.getData = getData;
               // vm.getDataFiltered = getDataFiltered;
                vm.addCriteriaToFilters = addCriteriaToFilters;
                vm.removeCriteriaFromFilters = removeCriteriaFromFilters;
                vm.activeItem = activeItem;
                vm.setTagFilter = setTagFilter;
                vm.inTagFilter = inTagFilter;
                vm.setInStock = setInStock;
                vm.filterByStock = filterByStock;
                vm.addParam = addParam;
                vm.resetFilters = resetFilters;

                vm.getData();

                //function test() {
                //    alert('zzzz');
                //}

                function setInStock() {
                    vm.data.filters.inStock = !vm.data.filters.inStock;
                }

                function filterByStock(filtered) {
                    var result = [];

                    angular.forEach(filtered, function (val, key) {
                        if (vm.data.stock[val.Item] != undefined) {
                            var s = 0;
                            angular.forEach(vm.data.stock[val.Item], function (val, key) {
                                if (Number.parseInt(val.replace('>', '')) > 0) s = s + Number.parseInt(val.replace('>', ''));
                            });
                            if (s > 0) {
                                result.push(val);
                            }
                            //  console.log(s);
                        }
                    });

                    return result;
                }

                function addParam(key, val) {
                    if(val == ''){
                        if(vm.data.params[key] ) delete vm.data.params[key];
                    } else {
                        vm.data.params[key] = val;
                    }
                    vm.getData();
                }

                function resetFilters() {
                    vm.data.filters = {brand: '', criteria: '',  tag: [], inStock: false};
                }

                function addCriteriaToFilters(criteria, value) {
                    vm.data.params.filters.push({criteria:criteria,values:[value]});
                    vm.getData();
                }

                function removeCriteriaFromFilters(criteria) {
                    var index = _.findIndex(vm.data.params.filters, { criteria: criteria });
                    if(index > -1) vm.data.params.filters.splice(index, 1);
                    vm.getData();
                }


                function getData() {

                    // vm. params = {grp: ''};

                    var src = '/api/catalog/goodsitemsfiltered';
                    
                    if (vm.$stateParams.grpId) {
                        vm.data.params.grp = vm.$stateParams.grpId;
                        $rootScope.title = vm.$stateParams.grpId
                        if (vm.$stateParams.grpId == 'НЕЙМОВІРНА ЧОРНА ПЯТНИЦЯ В АВТОТЕХНІКС') {
                            //src = '/api/catalog/specgoods';
                            __getData();
                            return false;
                        }
                    }
                    

                    if (vm.$stateParams.grpId) {
                        vm.data.params.grp = vm.$stateParams.grpId;
                        $rootScope.title = vm.$stateParams.grpId;
                    }

                    //vm.data.params.filters.push({criteria:'ЕМКОСТЬ, AH',values:['70','66']});
                    //vm.data.params.filters.push({criteria:'ПОЛЯРНОСТЬ',values:['ЛЕВ [+]']});
                    //alert(vm.data.params.grp);
                    $http.post(src, vm.data.params)
                        .success(function (data, status) {
                            //alert('1');
                            if (data.items.length) {

                                vm.data.items = data.items;
                                vm.data.filtered = data.items;
                                vm.data.locationList = data.loc;

                                if(data.subgrps){
                                    vm.data.listFilters.subgrp = {name:"Группы",rows:data.subgrps};
                                    //console.log(vm.data.listFilters);
                                }

                                if(data.criterias){
                                    vm.data.criterias = data.criterias;
                                    //console.log(vm.data.listFilters);
                                }

                                if (data.images) {
                                    angular.forEach(data.images, function (val, key) {

                                        if (!_.has(vm.data.images, val.Item)) {
                                            vm.data.images['' + val.Item + ''] = []
                                        }

                                        if(_.findIndex(vm.data.images['' + val.Item + ''], val) == -1) vm.data.images['' + val.Item + ''].push(val);

                                    });
                                    // console.log(vm.data.images);
                                }

                                //if (data.by) vm.data.by = data.by;

                                itemService.getStock(vm).then(
                                    function () {
                                        //vm.filterByStock();
                                        vm.data.filters.inStock = true;
                                    }
                                );



                                vm.data.active = 0;

                                $rootScope.tag = vm.$stateParams.grpId;

                                if (vm.data.filtered.length) {
                                    $state.go('goods.items.replace', {artId: vm.data.filtered[0].Item});
                                }
                                vm.resetFilters();

                            } else {
                                vm.alerts = {warning: ['Товар не найден']};

                                if (vm.typeSearch == 1) {
                                    
                                } else if (vm.typeSearch == 2) {
                                    type = 3;
                                }

                            }
                        });
                }


                function __getData() {

                    //vm. params = {grp: ''};

                    var src = '/api/catalog/goodsitems';

                    if (vm.$stateParams.grpId) {
                        vm.data.params.grp = vm.$stateParams.grpId;
                        $rootScope.title = vm.$stateParams.grpId
                        if (vm.$stateParams.grpId == 'НЕЙМОВІРНА ЧОРНА ПЯТНИЦЯ В АВТОТЕХНІКС') {
                            src = '/api/catalog/specgoods';
                        }
                    }

                    $http.get(src, {params: vm.data.params})
                        .success(function (data, status) {
                            if (data.items.length) {

                                vm.data.items = data.items;
                                vm.data.filtered = data.items;
                                vm.data.locationList = data.loc;

                                if(data.subgrps){
                                    vm.data.listFilters.subgrp = {name:"Группы",rows:data.subgrps};
                                    //console.log(vm.data.listFilters);
                                }

                                if (data.images) {
                                    angular.forEach(data.images, function (val, key) {

                                        if (!_.has(vm.data.images, val.Item)) {
                                            vm.data.images['' + val.Item + ''] = []
                                        }

                                        vm.data.images['' + val.Item + ''].push(val);

                                    });
                                    // console.log(vm.data.images);
                                }

                                //if (data.by) vm.data.by = data.by;

                                itemService.getStock(vm).then(
                                    function () {
                                        //vm.filterByStock();
                                        vm.data.filters.inStock = true;
                                    }
                                );



                                vm.data.active = 0;

                                $rootScope.tag = vm.$stateParams.grpId;

                                if (vm.data.filtered.length) {
                                    $state.go('goods.items.replace', {artId: vm.data.filtered[0].Item});
                                }
                                vm.resetFilters();

                            } else {
                                vm.alerts = {warning: ['Товар не найден']};

                                if (vm.typeSearch == 1) {

                                } else if (vm.typeSearch == 2) {
                                    type = 3;
                                }

                            }
                        });
                }

                $scope.$watchCollection("vm.data.filters", function (filters, oldFilters) {
                    var filtered = angular.copy(vm.data.items);


                    if (filters.criteria != '') {
                        var query = filters.criteria.split(',');
                        angular.forEach(query, function (val) {
                            filtered = $filter("filter")(filtered, val);
                        });
                    }

                    if (filters.brand != '') filtered = $filter("filter")(filtered, {'Бренд':filters.brand});

                    if (filters.inStock) {
                        filtered = vm.filterByStock(filtered);
                    }

                    vm.data.filtered = filtered;

                    if (vm.data.filtered.length) {
                        if (vm.$stateParams.grpId) {
                            $state.go('goods.items.replace', {artId: vm.data.filtered[0].Item});
                        } else {
                            $state.go('goods.replace', {artId: vm.data.filtered[0].Item});
                        }
                    }
                    //vm.inStock = false;
                });

                $scope.$watchCollection("vm.data.filters.tag", function (tag, oldFilters) {
                    var filtered = angular.copy(vm.data.items);
                    angular.forEach(tag, function (val) {
                        filtered = $filter("filter")(filtered, val);
                    });
                    vm.data.filtered = filtered;
                });

                $scope.$watch("vm.data.active", function (val) {
                    if (val >= 0) {
                        if (vm.data.filtered.length) {
                            if (vm.$stateParams.grpId) {
                                $state.go('goods.items.replace', {artId: vm.data.filtered[val].Item});
                            } else {
                                $state.go('goods.replace', {artId: vm.data.filtered[val].Item});
                            }
                        }
                    }
                });


                function setTagFilter(tag) {
                    var index = _.indexOf(vm.data.filters.tag, tag);
                    if (index >= 0) {
                        vm.data.filters.tag.splice(index, 1);
                    } else {
                        vm.data.filters.tag.push(tag);
                    }
                }

                function inTagFilter(tag) {
                    var index = _.indexOf(vm.data.filters.tag, tag);
                    return index >= 0;
                }


                function activeItem() {
                    if (vm.$rootStateParams.artId) {
                        vm.data.active = _.findIndex(vm.data.filtered, {Item: vm.$rootStateParams.artId});
                    }
                }

                $scope.$on('artMainItems:setType', function (event, type) {
                    $timeout(function () {
                        if (vm.typeSearch == type) {
                            vm.getData();
                        }
                    }, 200);
                });


            }]);

