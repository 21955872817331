'use strict';

angular.module('reports')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("reports.returns",
                {
                    url: "/returns",
                    views: {
                        'main@reports': {
                            templateUrl: "/app/reports/returns/returns.html",
                            controller: "ReturnsController",
                            controllerAs: "vm"
                        }
                    }
                })
            .state("reports.returns.details",
                {
                    url: "/:docNo",
                    views: {
                        'main@reports': {
                            templateUrl: "/app/reports/returns/return-details.html",
                            controller: "ReturnDetailsController",
                            controllerAs: "vm"
                        }
                    }
                });

    }]);