'use strict';


angular.module('app')
    .controller('VinController',
    ["$scope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", '$stateParams','$state', "hotkeys",
        function ($scope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, $stateParams,$state, hotkeys) {

            /* var model = $scope.mainModel = {
             activeView: 1,
             items:{
             active:-1,
             total:0
             },
             view:'mainview'
             };*/


            var vm = this;

            vm.$storage = $localStorage;
            vm.$state = $state;
            vm.car = {};
            vm.data = {
                activeView: 1,
                items: {
                    active: -1,
                    total: 0
                }
            };
            vm.items = {
                active: -1,
                total: 0
            };

            vm.isItems = $state.is('vin.grp.oem');

            //vm.getData = getData;
            //vm.setCar = setCar;
            vm.bindAll = bindAll;
            vm.addToCart = addToCart;
            vm.isItemInCart = isItemInCart;
            vm.getCartQnty = getCartQnty;
            vm.openGroupsModal = openGroupsModal;

            if (vm.$storage.vinType && vm.$storage.vinType.nameFull) vm.$storage.vinType.nameFull = '';

            //getData();
            bindAll();

            function openGroupsModal() {
                var modalInstance = $modal.open({
                    templateUrl: "/app/vin/vin-groups-modal.html",
                    controller: "VinGroupsModalController",
                    controllerAs: "modal",
                    //size: 'lg',
                    windowClass: "no-animation-modal",
                    resolve: {
                        parent: function () {
                            return '';
                        }
                    }
                });
                modalInstance.result.then(
                    function () {
                        //vm.getData();
                    },
                    function () {
                        //vm.getData();
                    }
                );
            }

            function addToCart(col, index) {


                if (index == undefined) {
                    index = model.active[col];
                }

                var row = angular.copy(model.filtered[col][index]);

                var qnty;
                if (row.qnty) {
                    qnty = row.qnty;
                } else {
                    qnty = 1;
                }

                if ($scope.$storage.car.types) {
                    if ($scope.$storage.car.types.name_full != undefined) row.tag = $scope.$storage.car.types.name_full;
                } else if (model.search.articleOrVin) {
                    row.tag = model.search.articleOrVin;
                }

                var indexCart = _.findIndex($scope.$storage.cart.items, {Item: row.Item});
                if (indexCart >= 0) {
                    $scope.$storage.cart.items.splice(indexCart, 1);
                    //$scope.$storage.cart.items[index].qnty += 1;
                } else {
                    row.qnty = qnty;
                    $scope.$storage.cart.items.push(angular.copy(row));
                }
            }

            function isItemInCart(row) {
                var index = _.findIndex($scope.$storage.cart.items, {Item: row.Item});
                return index >= 0;
            }

            function getCartQnty(row) {
                var index = _.findIndex($scope.$storage.cart.items, {Item: row.Item});
                if (index >= 0) {
                    return $scope.$storage.cart.items[index].qnty;
                } else {
                    return 0;
                }

            }

            /*function getData(code) {
             if(!code){
             code  = $stateParams.typeId;
             }
             var params = {code:code};
             $http.get("/api/catalog/groups", { params: params })
             .success(function(data, status) {
             $timeout(function() {
             //$("#srch_" + tab).focus();
             });
             });
             }




             function setCar (index) {
             if(!index){
             index = 0;
             }
             vm.car = vm.$storage.data.cars[index];
             }*/


            function bindAll() {
                hotkeys.bindTo($scope)
                    .add({
                        combo: "up",
                        description: "Движение вверх по списку",
                        allowIn: ["INPUT", "SELECT", "TEXTAREA"],
                        callback: function (event, hotkey) {
                            event.preventDefault();
                            if (vm.items.active >= 0) {
                                vm.items.active -= 1;
                            }
                            if (vm.items.active < 0) {
                                vm.items.active = 20 - 1;
                            }
                        }
                    })

                    .add({
                        combo: "down",
                        description: "Движение вниз по списку",
                        allowIn: ["INPUT", "SELECT", "TEXTAREA"],
                        callback: function (event, hotkey) {
                            event.preventDefault();

                            if (vm.items.active <= 20 - 1) {
                                vm.items.active += 1;
                            }
                            if (vm.items.active > 20 - 1) {
                                vm.items.active = 0;
                            }

                            /*if (model.active[model.col] <= (model.filtered[model.col].length - 1)) {
                             model.active[model.col] += 1;
                             }
                             if (model.active[model.col] > model.filtered[model.col].length - 1) {
                             model.active[model.col] = 0;
                             }*/
                        }
                    })
                    .add({
                        combo: "right",
                        // allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                        description: 'Переключение в правую колонку',
                        callback: function (event, hotkey) {
                            event.preventDefault();
                            if (vm.data.activeView < 2) {
                                vm.data.activeView += 1;
                                vm.items.active = 0;
                            }
                        }
                    })
                    .add({
                        combo: "left",
                        // allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                        description: 'Переключение в левую колонку',
                        callback: function (event, hotkey) {
                            event.preventDefault();
                            if (vm.data.activeView > 0) {
                                vm.data.activeView -= 1;
                                vm.items.active = 0;
                            }
                        }
                    })



                    /*.add({
                     combo: "space",
                     description: "Выбор строки",
                     //allowIn: ["INPUT", "SELECT", "TEXTAREA"],
                     callback: function (event, hotkey) {
                     event.preventDefault();
                     model.selectRow();

                     if (model.active[model.col] <= (model.filtered[model.col].length - 1)) {
                     model.active[model.col] += 1;
                     }
                     if (model.active[model.col] > model.filtered[model.col].length - 1) {
                     model.active[model.col] = 0;
                     }

                     //model.active += 1;
                     }
                     })
                     .add({
                     combo: "enter", description: "Открытие",// allowIn: ["INPUT", "SELECT", "TEXTAREA"],
                     callback: function (event, hotkey) {
                     event.preventDefault();
                     model.actionRow();
                     }
                     })*/
                ;


            }

            // $scope.bindAll();
            // console.log($scope);

        }]);

