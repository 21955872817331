"use strict";

angular.module("app")
	.controller("ItemCtrl", ["$scope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modalInstance", "$localStorage", "hotkeys","order",
		function ($scope, $http, $location, $timeout, $filter, $document, $log, $modalInstance, $localStorage, hotkeys, order) {

			$scope.type = 'order';
			$scope.$storage = $localStorage;
			$scope.table = angular.copy(order);

			$scope.search = {};
			$scope.selected = {};
			$scope.selected.item = -1;
			$scope.filteredItems = [];

			$scope.activeItem = -1;
			$scope.selectedItems = [];
			$scope.totalTable = 0;

			$scope.$watch("search.table", function (query) {
				$scope.selected.item = -1;
			});

			$scope.$watchCollection("$storage.table", function (query) {
				$scope.calcTotalTable();
			});

			$scope.$watchCollection("filteredItems", function (query) {
				$scope.calcTotalTable();
			});

			// Расчет суммы корзины
			$scope.calcTotalTable = function () {
				var total = 0;
				angular.forEach($scope.table.rows, function(row, key) {
					row.qnty = Number(row.qnty);
					if(row.qnty < 1){
						row.qnty = 1;
					}
					total = total+row.qnty*row.price;
				});
				$scope.table.total = total;
			};
			$scope.calcTotalTable();

			// Удалене строки из таблицы
			$scope.del = function (row) {
				if(!row){
					row = $scope.filteredItems[$scope.selected.item];
				}
				var index = _.findIndex($scope.table, {Item: row.Item});
				if (index >= 0) {
					$scope.table.splice(index, 1);
				}
			};


			$scope.plusQnty = function (row) {
				row.qnty = Number(row.qnty)+1;
				$scope.calcTotalTable();
			};
			// Отнимание 1 шт
			$scope.minusQnty = function (row) {
				row.qnty = Number(row.qnty)-1;
				if(row.qnty < 1){
					row.qnty = 1;
				}
				$scope.calcTotalTable();
			};

			$scope.ok = function () {
				$modalInstance.close($scope.table);
			};

			$scope.cancel = function () {
				$modalInstance.dismiss('cancel');
			};


		}]);
