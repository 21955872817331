'use strict';

angular.module('app')
    .controller('ArtActionsModalController', ['$scope', '$http', '$timeout', '$modalInstance', '$localStorage', 'hotkeys', '$log', '$filter', '$modal', 'parent',
        function ($scope, $http, $timeout, $modalInstance, $localStorage, hotkeys, $log, $filter, $modal, parent) {
            var vm = this;
            vm.$storage = $localStorage;
            vm.parent = parent;

            vm.data = {
                actions: []
            };

            vm.getData = getData;

            vm.getData();

            function getData() {
                //var data = {items: "'" + _.pluck(vm.data.items, 'Item').join("','") + "'"};
                //$timeout(function(){
                    $http.post('/api/marketing/itemactions',{'item':vm.parent.Item})
                        .success(function (data, status) {
                            vm.data.actions = data.actions;
                        });
                //},100);
            }


            //vm.getData(vm.row);


            /*vm.data = {
                container: '.modal'
            };*/

            //vm.list = {};
           // vm.list.actionRow = actionRow;
            vm.ok = ok;
            vm.cancel = cancel;




            function ok(row) {
                $modalInstance.close(row);
            }

            function cancel() {
                $modalInstance.dismiss('cancel');
            }

            /*function actionRow(index) {
                if(!index){
                    ok();
                }
                vm.data.active = index;
            }*/
        }]);
