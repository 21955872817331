"use strict";

angular.module("app")
    .controller("ReturnModalController", ["$scope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modalInstance", "$localStorage", "$modal", "hotkeys", "header",
        function ($scope, $http, $location, $timeout, $filter, $document, $log, $modalInstance, $localStorage, $modal, hotkeys, header) {
            $scope.$storage = $localStorage;
            $scope.searchText = "";
            $scope.data = [];
            var model = $scope.model = { alerts: [] };

            $scope.search = function () {
                $scope.closeAlertAll();
                $http.post("/api/Returns/Items", { itemNo: $scope.searchText })
                    .success(function (response, status) {
                        $scope.data = response.items;
                        if (response.items.length == 0)
                            $scope.addAlerts({ danger: ['Нет указанных позиций к возврату в разрешенный период. Обратитесь к менеджеру!'] });
                    });
            };
            
            $scope.searchKeyPress = function(event) {
                if (event.keyCode == 13) $scope.search();
            };
            $scope.clear = function () {
                $scope.searchText = "";
                $scope.data = [];
                $scope.closeAlertAll();
            };
            $scope.ok = function (a) {
                $modalInstance.close(a);
            };
            $scope.cancel = function (alerts) {
                if (!alerts) {
                    alerts = 'cancel';
                }
                $modalInstance.dismiss(alerts);
            };
            $scope.closeAlert = function (index) {
                delete model.alerts[index];
            };

            $scope.closeAlertAll = function () {
                    model.alerts = [];
            };

            $scope.addAlerts = function (alerts) {
                model.alerts = alerts;
                if (model.alerts.success && model.alerts.success.length > 0) {
                    $timeout(function () {
                        delete model.alerts.success;
                    }, 4000);
                }
            };
        }]);
