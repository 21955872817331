'use strict';


angular.module('app')
    .controller('NavToolbarMobController',
    ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$state", '$stateParams', "$localStorage", "hotkeys", "userCarService",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $state, $stateParams, $localStorage, hotkeys, userCarService) {

            var vm = this;

            vm.$storage = $localStorage;
            vm.$stateParams = $stateParams;
            vm.$rootStateParams = $rootScope.$stateParams;

            vm.data = {
                isCars: false,
                search: '', //vm.$stateParams.art ? vm.$stateParams.art : ''
                //numView:0,
                //view:'navview',
                //arts: [],
                items: [],
                filtered: []
            };

            vm.search = search;
            vm.getUserCars = getUserCars;
            vm.newCarOpen = newCarOpen;
            vm.newVinOpen = newVinOpen;
            //vm.saveToHistory = saveToHistory;
            vm.goToCar = goToCar;
            vm.goToArt = goToArt;
            vm.goToUserCar = goToUserCar;
            vm.goToUserVinCar = goToUserVinCar;
            vm.goToVin = goToVin;
            vm.openHistory = openHistory;
            vm.bindAll = bindAll;
            vm.deleteCar = deleteCar;
            vm.deleteVinCar = deleteVinCar;
            vm.getRandomizer = getRandomizer;

            vm.bindAll();
            vm.getUserCars();


            function getRandomizer(bottom, top) {
                return function() {
                    return Math.floor( Math.random() * ( 1 + top - bottom ) ) + bottom;
                }
            }

            /*$scope.$on('userCars:add', function() {
                $timeout(function () {
                    vm.getUserCars();
                });
            });

            $scope.$on('userCars:del', function() {
                $timeout(function () {
                    vm.getUserCars();
                });
            });*/


            function getUserCars() {
                //console.log($scope);
                userCarService.get(function(data){
                   vm.data.filtered = data.items;
                   vm.data.items =  data.items;
                });
            }


            function search() {
                if (vm.data.search.length == 17) {
                    vm.newVinOpen();
                } else {
                    var item = vm.data.search.replace(/[^a-z0-9*]/gi, '');

                    vm.$storage.artHistory.unshift(item);

                    if (vm.$storage.artHistory.length > 20) {
                        vm.$storage.artHistory.pop();
                    }

                    $state.go('art', {art: item});
                }
            }


            angular.element('#srch_art_vin').focus();


            function newVinOpen() {
                var modalInstance = $modal.open({
                    templateUrl: "/app/vin/vin-modal.html",
                    controller: "VinModalController",
                    controllerAs: "vm",
                    size: 'lg',
                    windowClass: "no-animation-modal",
                    resolve: {
                        parent: function () {
                            return vm.data;
                        }
                    }
                });
                modalInstance.result.then(function (car) {
                }, function () {

                });
            }

            function newCarOpen() {
                var modalInstance = $modal.open({
                    templateUrl: "/app/car/car-modal.html",
                    controller: "CarModalController",
                    size: 'lg',
                    windowClass: "no-animation-modal",
                    resolve: {
                        parent: function () {
                            return $scope.model;
                        },
                        car: function () {
                            return {groups: []};
                        }
                    }
                });
                modalInstance.result.then(function (car) {
                    //vm.saveToHistory(car);
                    //vm.goToCar(car);
                }, function () {

                });
            }

            /*function saveCar(car) {



             vm.$storage.data.cars.unshift(car);

             if (vm.$storage.data.cars.length > 20) {
             vm.$storage.data.cars.pop();
             }

             vm.goToCar(car);
             }*/

            /*function saveToHistory(car) {

             var row = {code:car.code,name:car.name_full};

             vm.$storage.data.car.history.unshift(row);

             if (vm.$storage.data.car.history.length > 20) {
             vm.$storage.data.car.history.pop();
             }
             }*/

            function goToArt(item) {
                vm.data.search = item;
                $state.go('art', {art: item});
            }

            function goToUserCar(event, car) {
                event.preventDefault();
                if (car.SubGrp) {
                    $state.go('car.items', {typeId: car.TypeId, grpId: car.SubGrp});
                } else {
                    $state.go('car', {typeId: car.TypeId});
                }
                //if (index) $rootScope.lastCar = {index: index, type: 'car'};
            }

            function goToUserVinCar(event, car) {
                event.preventDefault();
                if (car.Path) {
                    $state.go('vin.grp', {vin: car.Vin, typeId: car.TypeId, grpId: car.Path});
                } else {
                    $state.go('vin', {vin: car.Vin, typeId: car.TypeId});
                }
            }

            function goToCar(car, index) {
                if (car.grp) {
                    $state.go('car.items', {typeId: car.code, grpId: car.grp});
                } else {
                    $state.go('car', {typeId: car.code});
                }
                if (index) $rootScope.lastCar = {index: index, type: 'car'};
            }

            function goToVin(car, index) {
                if (car.grp) {
                    $state.go('vin.grp', {vin: car.vin, typeId: car.type, grpId: car.grp});
                } else {
                    $state.go('vin', {vin: car.vin, typeId: car.type});
                }
                if (index) $rootScope.lastCar = {index: index, type: 'vin'};
            }

            function openHistory() {
                var modalInstance = $modal.open({
                    templateUrl: "/app/car/car-history-modal.html",
                    controller: "CarHistoryModalController",
                    controllerAs: "vm",
                    size: 'lg',
                    windowClass: "no-animation-modal",
                    resolve: {
                        parent: function () {
                            return vm.data;
                        }
                    }
                });
                modalInstance.result.then(function (car) {
                    // $scope.cars.data.car = car;
                    // $scope.cars.bindAll();
                }, function () {
                    // $scope.cars.bindAll();
                });
            }

            function deleteCar(event, row) {

                event.preventDefault();

                userCarService.del(row.CarId,function(){
                    //vm.getUserCars();
                });


                //vm.$storage.carHistory.splice(index, 1);
            }

            function deleteVinCar(index) {
                vm.$storage.vinHistory.splice(index, 1);
            }


            function bindAll() {
                hotkeys
                    .bindTo($scope)
                    .add({
                        combo: "alt+a",
                        description: 'Новый подбор по автомобилю',
                        allowIn: ["INPUT", "SELECT", "TEXTAREA"],
                        callback: function (event, hotkey) {
                            event.preventDefault();
                            vm.newCarOpen();
                        }
                    })
                    .add({
                        combo: "alt+s",
                        description: 'Новый подбор по автомобилю',
                        allowIn: ["INPUT", "SELECT", "TEXTAREA"],
                        callback: function (event, hotkey) {
                            event.preventDefault();
                            angular.element('#srch_art_vin').focus();
                        }
                    })
                    .add({
                        combo: "alt+h",
                        description: "История выбора автомобиля",
                        allowIn: ["INPUT", "SELECT", "TEXTAREA"],
                        callback: function (event, hotkey) {
                            event.preventDefault();
                            vm.openHistory();
                        }
                    })
                ;
            }
        }]);

