'use strict';

angular.module('adm.adv-items')
    .controller('AdvActualController',
    ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", "hotkeys", "RestService",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, hotkeys, RestService) {

            $rootScope.title = 'Акции';

            var vm = this;

            vm.rest = RestService;
            vm.rest.set({name: 'AdvItems', url: '/api/advitems'});

            vm.alerts = {};

            vm.data = {
                search: '',
                actual: [],
                tomorrow: [],
                selected: [],
                active: -1
            };

            vm.getData = getData;

            vm.getData();

            $scope.$on('AdvItems:getData', function() {
                $timeout(function () {
                    vm.getData();
                });
            });

            function getData() {
                $http.get('/api/marketing/actual').success(function (data) {
                    vm.data.actual = JSON.parse(data.items);
                });
                $http.get('/api/marketing/tomorrow').success(function (data) {
                    vm.data.tomorrow = data.items;
                });
            }


        }]);

