"use strict";

angular.module("app")
	.controller("AutodataCtrl", ["$scope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", "hotkeys",
		function ($scope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, hotkeys) {

			$scope.$storage = $localStorage;

			var baseUrl = "/api/autodata/v1/";

			var model = $scope.model = {
				modelId:false,
				data: {},
				metadata: {},
				items: [], //  TODO:from API
				filtered: [],
				selected: [],
				active: -1,
				search: {},
				total: 0,
				bindAll: function () {

				}
			};

			/*$scope.$watchCollection("model", function (query) {
				model.calcTotal();
			});*/


			// Получение видов работ
			model.getData = function () {

				$http.get(baseUrl + "vehicles?id="+$scope.$storage.car.types.typ_id+"&method=k_type&country-code=ru&page=1&limit=100", {params: {}})
					.then(function(response){
						return $http.get(baseUrl + "vehicles/"+response.data.data.data[0]["mid"]+"/repair-times?country-code=ru&parts=yes");
					})
					.then(function(response){
						/*$http.get(baseUrl + "vehicles/"+response.data.data.data[0]["mid"]+"/repair-times?country-code=ru&parts=yes", {params: {}})
							.success(function (data, status) {
								model.data = data.data;
								model.metadata = data.metadata;
								model.filtered = data.data.parts;
								model.items = data.data.parts;
							});*/
					});
				return true;
			};
			model.getData();

			/*
			 // Расчет суммы заказов TODO:from API
			 model.calcTotal = function () {
			 var total = 0;
			 angular.forEach(model.items, function (row, key) {
			 row.total = Number(row.total);
			 total = total + row.total;
			 });
			 model.total = total;
			 };
			 model.calcTotal();


			 // Поиск индекса выделенной строки
			 var getActiveRow = function () {
			 return $scope.filteredItems[$scope.activeItem];
			 };*/
			/*


			 // Удалене заказа
			 $scope.del = function () {
			 if (confirm("Удалить строки: " + $scope.selectedItems.length)) {
			 angular.forEach($scope.selectedItems, function (row, key) {
			 var index = _.findIndex($scope.$storage.orders, {id: row.id});
			 if (index >= 0) {
			 $scope.$storage.orders.splice(index, 1);
			 }
			 });
			 }
			 };

			 // Объединение заказов
			 $scope.combineOrders = function () {

			 var order = angular.copy(model.selected[0]);

			 order.rows = [];
			 var i = -2;

			 angular.forEach(model.selected, function (ord, key) {

			 angular.forEach(ord.rows, function (row, k) {
			 i = -2;
			 i = _.findIndex(order.rows, {Item: row.Item})
			 if (i >= 0) {
			 order.rows[i].qnty = order.rows[i].qnty + row.qnty;
			 } else {
			 order.rows.push(row)
			 }
			 });

			 var index = _.findIndex(model.items, {id: ord.id});
			 model.items.splice(index, 1);
			 });

			 order.total = 0;
			 angular.forEach(order.rows, function (row, key) {
			 row.total = Number(row.total);
			 order.total = order.total + row.total;
			 });

			 var id = model.items.length + 377256 + Math.floor(Math.random() * (30000 - 10 + 1)) + 10;
			 order.id = "S0000" + id;
			 model.items.push(order);
			 model.isAllSelect = false;
			 };

			 // Сохранение заказа
			 model.saveRow = function (row) {
			 var index = _.findIndex(model.items, {id: row.id});
			 if (index >= 0) model.items[index] = row;
			 };


			 // Открыть заказ
			 model.open = function (size, row) {
			 var modalInstance = $modal.open({
			 templateUrl: "app/orders/order.html",
			 controller: "OrderCtrl",
			 size: size,
			 windowClass: "no-animation-modal",
			 resolve: {
			 row: function () {
			 return row;
			 },
			 parent: function () {
			 return model;
			 }
			 }
			 });

			 modalInstance.result.then(function (returnRow) {
			 model.saveRow(returnRow);
			 }, function () {
			 $timeout(function () {
			 model.bindAll();
			 });
			 });
			 };

			 model.openModal = function (size, row) {
			 model.open('lg', model.filtered[model.active]);
			 };*/

		}]);
