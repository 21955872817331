'use strict';


angular.module('app')
    .controller('ArtReplaceItemsController',
    ["$scope", "$rootScope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modal", "$localStorage", '$state', '$stateParams', "hotkeys", "itemService",
        function ($scope, $rootScope, $http, $location, $timeout, $filter, $document, $log, $modal, $localStorage, $state, $stateParams, hotkeys, itemService) {

            var vm = this;

            vm.$storage = $localStorage;
            vm.$stateParams = $stateParams;
            vm.$rootStateParams = $rootScope.$stateParams;
            vm.itemService = itemService;
            vm.comId = localStorage.getItem('ComId');
            $scope.number = 8;
            $scope.getNumber = function (num) {
                return new Array(Math.ceil(num));
            };
            $scope.md = function () {
                return Math.ceil(vm.data.locationList.length / $scope.number);
            };
            vm.data = {
                numView: 2,
                view: 'subview',
                items: [],
                filtered: [],
                criterias: [],
                oemItems: [],
                locationList: [],
                typeTag: ''
            };
            vm.getData = getData;
            vm.getCriterias = getCriterias;
            vm.getOemItems = getOemItems;
            vm.getCarApplication = getCarApplication;
            //vm.goOem = goOem;


            if (vm.$rootStateParams.vin) {
                vm.data.typeTag = 'vin';
            } else if (vm.$rootStateParams.car) {
                vm.data.typeTag = 'car';
            } else if (vm.$rootStateParams.art) {
                vm.data.typeTag = 'art';
            }

            vm.getData();

            function getData(artId) {
                if (!artId && vm.$stateParams.artId) {
                    artId = vm.$stateParams.artId;
                }

                if (!artId && $scope.ctrl && $scope.ctrl.parent && $scope.ctrl.parent.row) {
                    artId = $scope.ctrl.parent.row.Item;
                }

                //alert(artId);

                return $http.get('/api/catalog/replace', {params: {code: artId}})
                    .then(function (response) {
                        var data = response.data;
                        vm.data.items = data.items;
                        vm.data.filtered = data.items;
                        vm.data.locationList = data.loc;
                    }).then(function () {
                        return itemService.getStock(vm);
                    }).then(function () {
                        return vm.getCriterias(artId);
                    });
            }

            function getCriterias(artId) {
                return $http.get('/api/catalog/criteria', {params: {code: artId}, ignoreLoadingBar: true})
                    .then(function (response) {
                        vm.data.criterias = response.data;
                    }).then(function () {
                        return vm.getOemItems(artId);
                    })
                    ;
            }

            function getOemItems(artId) {
                return $http.get('/api/catalog/oemitems', {params: {code: artId}, ignoreLoadingBar: true})
                    .then(function (response) {
                        var data = response.data;
                        var oem = {};

                        angular.forEach(data, function (val) {
                            if (!oem[val.Brand1]) {
                                oem[val.Brand1] = [];
                            }
                            oem[val.Brand1].push(val);
                        });
                        vm.data.oemItems = oem;
                    }).then(function(){
                        return vm.getCarApplication(artId);
                    });
            }


            function getCarApplication(artId) {
                return $http.get('/api/catalog/carapplication', {params: {code: artId}, ignoreLoadingBar: true})
                    .then(function (response) {
                        var data = response.data;
                        var app = {};
                        angular.forEach(data, function (val) {
                            if (!app[val.MARK]) {
                                app[val.MARK] = [];
                            }
                            app[val.MARK].push(val);
                        });
                        vm.data.application = app;
                    });
            }

        }]);

