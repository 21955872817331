'use strict';

angular.module('app')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("car", {
                url: "/car/{typeId:int}",
                views:{
                    "@" : {
                        templateUrl: "/app/car/car.html",
                        controller: "CarController",
                        controllerAs:"car"
                    },
                    'groups@car':{
                        templateUrl: "/app/car/car-groups-items.html",
                        controller: "CarGroupsItemsController",
                        controllerAs:"vm"
                    }
                }
            })
            .state("car.items", {
                url: "/grp/:grpId",
                views:{
                    'items@car': {
                        templateUrl: "/app/car/car-main-items.html",
                        controller: "CarMainItemsController",
                        controllerAs: "vm"
                    }
                }
            })
            .state("car.items.replace", {
                //url: "/art/:artId",

                params: {
                    artId:''
                },

                    views:{
                    'replace@car': {
                        templateUrl: "/app/art/art-replace-items.html",
                        controller: "ArtReplaceItemsController",
                        controllerAs: "vm"
                    }
                }
            });
    }]);