'use strict';
angular.module('app')
    .controller('VinModalOperationsController', ['$scope', '$http', '$timeout', '$modalInstance', '$localStorage', 'hotkeys', '$log', '$filter', '$modal','$state', 'parent',
        function ($scope, $http, $timeout, $modalInstance, $localStorage, hotkeys, $log, $filter, $modal,$state, parent) {
            var vm = this;
            vm.$storage = $localStorage;
            vm.parent = parent;
            vm.data = {
                container: '.modal',
                items: [],
                filtered: [],
                catalog: {},
                selected: [],
                active: -1
            };

            vm.getData = getData;
            vm.actionRow = actionRow;
            vm.go = go;
            vm.ok = ok;
            vm.cancel = cancel;

            vm.getData();

            function go(row) {
                vm.$storage.vinType = row;
                $state.go('vin',{vin:0,typeId:row.vehicleId,ssd:row.ssdField,catalog:row.catalogField});
                vm.cancel();
            }

            function getData() {
                $http.post("/api/vin/typeschassis", parent)
                    .success(function (data, status) {
                        vm.data.items = data.types.rowField;
                        vm.data.filtered = data.types.rowField;
                        vm.data.catalog = data.catalog.rowField;
                    });
            }

            function ok(index) {
                $modalInstance.close(index);
            }

            function cancel() {
                $modalInstance.dismiss('cancel');
            }

            function actionRow(row) {
                if (!row) {
                    row = vm.data.filtered[vm.data.active];
                }
                $scope.ok(row);
            }
        }]);
