"use strict";

angular.module("app")
    .controller("ReturnReleaseModalController", ["$scope", "$http", "$location", "$timeout", "$filter", "$document", "$log", "$modalInstance", "$localStorage", "$modal", "hotkeys", "header",
        function ($scope, $http, $location, $timeout, $filter, $document, $log, $modalInstance, $localStorage, $modal, hotkeys, header) {

            $scope.data = [];
            $scope.selected = "";
            var model = $scope.model = { alerts: [] };

            $scope.loadData = function () {
                $http.get("/api/Returns/ShipTo")
                    .success(function (response) {
                        response.unshift({ Code: '', 'Address ADU': 'Самостоятельная Доставка Клиентом на Филиал' });
                        $scope.data = response;
                       // $scope.data = [{ Code: '', 'Address ADU': 'Самостоятельная Доставка Клиентом на Филиал' }, ...response];
                        $scope.selected = $scope.data[0];
                        if (!$scope.data.length)
                            $scope.addAlerts({ danger: ['Нет указанных позиций к возврату в разрешенный период. Обратитесь к менеджеру!'] });
                    });
            };
            $scope.loadData();

            $scope.release = function () {
                $scope.ok($scope.selected);
            };
            
            $scope.ok = function (a) {
                $modalInstance.close(a);
            };
            $scope.cancel = function (alerts) {
                if (!alerts) {
                    alerts = 'cancel';
                }
                $modalInstance.dismiss(alerts);
            };

            $scope.closeAlert = function (index) {
                delete model.alerts[index];
            };

            $scope.closeAlertAll = function () {
                model.alerts = [];
            };

            $scope.addAlerts = function (alerts) {
                model.alerts = alerts;
                if (model.alerts.success && model.alerts.success.length > 0) {
                    $timeout(function () {
                        delete model.alerts.success;
                    }, 4000);
                }
            };
        }]);
