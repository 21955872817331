'use strict';

angular.module('adm.adv-items')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("adm.adv-items", {
            url: "/adv-items",
            views:{
                'main@adm': {
                    templateUrl: "/app/adm/adv-items/adv-items.html",
                    controller: "AdvItemsController",
                    controllerAs:"vm"
                },
                'side@adm': {
                    templateUrl: "/app/adm/adv-items/adv-actual.html",
                    controller: "AdvActualController",
                    controllerAs:"vm"
                }
            }
        });
    }]);